export default {
  id: '8236e5fd07',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\n  <!-- Scrollable page content -->\n  <div data-page="mySkis" class="page" id="mySkis_div">\n\n    <div class="navbar">\n        <div class="navbar-bg"></div>\n        <div class="navbar-inner">\n          <div class="left link"><a href="/mySettings/" class="link"><i class="icon f7-icons">chevron_left</i><span>Back</span></a></div>\n            <!-- We need cool sliding animation on title element, so we have additional "sliding" class -->\n          <div class="title center sliding">My Skis</div>\n        </div>\n    </div>\n\n        <!-- Scrollable page content -->\n        <div class="page-content">\n\n                <div class="content-block-title padding_l_r_15px">\n                  <span class="vertAlignMiddle">My Skis\n                    <span class="rightIcon"><a href="/lookup/brands/">Add Ski</a></span>\n                  </span>\n                </div>\n\n                <div id="add_ski_div" class="list-block"></div> <!-- container to add skis -->\n\n                <div class="list" id="ul_mySkis_list"></div>\n\n\n          <!--  </div>  END CONTENT BLOCK -->\n        </div> <!-- END PAGE CONTENT -->\n    </div> <!-- END PAGE -->\n\n\n\n';
      return r;
    }(this);
  },
  styleScoped: false
};