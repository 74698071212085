import $$ from 'dom7';
var Utility = {

  requestUserName: function (user_email) {
    console.log("requesting username with " + user_email);
    var url=app.data.wsURL+'ws_get_username_ret_json.php';

    app.request({url:url,data:{ user_email:user_email },type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj[0].RETURN_CODE==1) { // Do nothing, since we don't want to give away if email was valid
        console.log('return code 1...success for requestUserName!');
      } else {
        console.log('return code NOT 1...no luck requestUserName');
      }
    }, timeout: 5000
      , beforeSend: function() {
        console.log('beforeSend requestUserName');
        //app.showIndicator();
        app.preloader.show();
      }, complete: function(jsonObj) {
          console.log('in complete requestUserName');
          //app.hideIndicator();
          app.preloader.hide();
            app.dialog.alert("If the email address <span class='bold'>" + user_email + "</span> is associated with your account, you will receive an email with your User Name.","Email Sent");
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
          }
      }
    }) // END ajax function for models
  },








  validEmail: function (user_email) {
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_email)) {
      return true;
    } else {
      return false;
    }
  },







  validatePwResetCode: function (userNameOrEmail) {
        app.dialog.prompt('Enter the verification code sent to your email.', 'Reset Password Code', function (value) {
          console.log('running validatePwResetCode function');
          //if (offline) return onOffline();
          if (!app.methods.onLine()) return app.methods.onOffline();

          var url=app.data.wsURL+'ws_get_forgot_pw_code_ret_json.php';
          var passed;
          app.request({url:url,data:{ userNameOrEmail:userNameOrEmail,code:value},type:'POST',dataType: 'json',success:function(jsonObj) {
            if (jsonObj[0].RETURN_CODE==1) {
              console.log('return code 1...success for pw reset code!');
              passed=true;
            } else {
              console.log('return code NOT 1...no luck activating account');
              passed=false;
            }
          }, timeout: 5000
            , beforeSend: function() {
              console.log('beforeSend validatePwResetCode');
              //app.showIndicator();
              app.preloader.show();
            }, complete: function(jsonObj) {
                console.log('in complete validatePwResetCode');
                //app.hideIndicator();
                app.preloader.hide();
                if (passed) {
                  Utility.setNewPw(userNameOrEmail);
                } else {
                  app.dialog.confirm("The Code " +value+ " is not valid.  Press OK to try again or Cancel.",
                  "Incorrect Code",
                  function () {
                        console.log('in confirm OK');
                        Utility.validatePwResetCode(userNameOrEmail);
                      },
                  function () {
                        return null;
                    }
                  );
                }

            }, error: function(jsonObj, status, err) {
                if (status == "timeout") {
                  console.log("Timeout Error. " + jsonObj + status + err);
                } else {
                  console.log("error: "  + status + err);
                }
            }
        }) // END ajax function for models

      }); // END PROMPT
  },








  generatePwResetCode: function (userNameOrEmail) {
        console.log('running generatePwResetCode function with value of ' + userNameOrEmail);
        //if (offline) return onOffline();
        if (!app.methods.onLine()) return app.methods.onOffline();

        var url=app.data.wsURL+'ws_set_forgot_pw_code_ret_json.php';
        var passed;
        app.request({url:url,data:{user_input:userNameOrEmail},type:'POST',dataType: 'json',success:function(jsonObj) {
          if (jsonObj[0].RETURN_CODE==1) {
            console.log('return code 1...success for pw reset code!');
            passed=true;
          } else {
            console.log('return code NOT 1...no luck activating account');
            passed=false;
          }
        }, timeout: 5000
          , beforeSend: function() {
            console.log('beforeSend generatePwResetCode');
            //app.showIndicator();
            app.preloader.show();
          }, complete: function(jsonObj) {
              console.log('in complete generatePwResetCode');
              //app.hideIndicator();
              app.preloader.hide();
              if (passed) {

                  app.dialog.alert(
                    'An email has been sent to ' +userNameOrEmail+'.  Please get the code from your email account and supply to the following prompt to reset your password.',
                    'Email Sent',
                    function () {
                      Utility.validatePwResetCode(userNameOrEmail);
                    }
                  );

              } else {
                app.dialog.alert(
                  'The User Name or Email address was not found in the system.',
                  'Failed',
                  function () {
                    return null;
                  }
                );
              }
          }, error: function(jsonObj, status, err) {
              if (status == "timeout") {
                console.log("Timeout Error. " + jsonObj + status + err);
              } else {
                console.log("error: "  + status + err);
              }
          }
        }) // END ajax function for models
  },








  setNewPw: function (userNameOrEmail) {
    app.dialog.prompt('Successful validation.<p>Enter a new password.</p>', 'Reset Password', function (value) {
      console.log('running validatePwResetCode function');
      //if (offline) return onOffline();
      if (!app.methods.onLine()) return app.methods.onOffline();

      if (value.length<6) {
        app.dialog.alert(
          'Password must be atleast 6 characters.',
          'Password failed',
          function () {
            Utility.setNewPw(userNameOrEmail);
          }
        );
        return;
      }

      var url=app.data.wsURL+'ws_set_reset_password_ret_json.php';
      var passed;
      app.request({url:url,data:{ userNameOrEmail:userNameOrEmail,user_pwd:value},type:'POST',dataType: 'json',success:function(jsonObj) {
        if (jsonObj[0].RETURN_CODE==1) {
          console.log('return code 1...success for pw reset!');
          passed=true;
        } else {
          console.log('return code NOT 1...failed password reset');
          passed=false;
        }
      }, timeout: 5000
        , beforeSend: function() {
          console.log('beforeSend setNewPw');
          //myApp.showIndicator();
          app.preloader.show();
        }, complete: function(jsonObj) {
            console.log('in complete setNewPw');
            //myApp.hideIndicator();
            app.preloader.hide();
            if (passed) {
              localStorage.removeItem("pwd");
              app.dialog.alert(
                'Password reset has been complete.  Please login with your new password.',
                'Success!',
                function () {
                  return null;
                }
              );
            } else {
              app.dialog.alert(
                'Password reset was not successful.  Please try again.',
                'Password Reset Failed',
                function () {
                  return null;
                }
              );
            }
        }, error: function(jsonObj, status, err) {
            if (status == "timeout") {
              console.log("Timeout Error. " + jsonObj + status + err);
            } else {
              console.log("error: "  + status + err);
            }
        }
      }); // END ajax function for models
    });

    $$(".modal-in input").prop("type","password"); // change input type to password since it defaults to text
  },



  checkImageExists: function (imageUrl, callBack) {
    var imageData = new Image();
    imageData.onload = function() {
      callBack(true);
    };
    imageData.onerror = function() {
      callBack(false);
    };
    imageData.src = imageUrl;
    console.log("++++++++++imageUrl:" + imageUrl);
  },

  isUTCFormat: function(str) {
    if (/\d{4}-\d{1,2}-\d{1,2}\s\d{1,2}:\d{1,2}:\d{1,2}/.test(str)) {
       return true;
    } else {
      return false
    }
  },




  getLocalDateTimeString: function (inputTime,outputFormat) {
    var d;

    // first build the date object with inputTime argument
    if (inputTime=='' || typeof inputTime === "undefined" || inputTime==null) {
        d=new Date();
        d.setMonth(d.getMonth()); // increment month by 1 to go from 0 index to UTC/mySql compatability.
        //console.log('In getLocalDateTimeString and determined from JS in browser with date=');
        //console.log(d);
    } else {
      let dt=inputTime.split(/[- :]/);
      d=new Date(dt[0],dt[1]-1,dt[2],dt[3],dt[4],dt[5]);
      console.log(">>>>>>>>date object when passed an inputTime value...",d)
    }

    if (outputFormat=="ISO") { // This data is to be use for working with mySQL date time object
      let y=d.getFullYear();
      let m=d.getMonth()+1;
      let da=d.getDate();
      let h=d.getHours();
      let mi=d.getMinutes();
      let s=d.getSeconds();
      let localDateTimeString=y+'-'+m+'-'+da+' '+h+':'+mi+':'+s;
      console.log(">>>>>>>>>>>>>>>>>>>localDateTimeString ",localDateTimeString);
      return localDateTimeString;
    } else { // 'pretty' format... will never send this type of formatted data to mySQL
      //console.log("in getLocalDateTimeString pretty with date obj...");
      //console.log(d);
      var monthNames = {
        0:"Jan",
        1:"Feb",
        2:"Mar",
        3:"Apr",
        4:"May",
        5:"Jun",
        6:"Jul",
        7:"Aug",
        8:"Sep",
        9:"Oct",
        10:"Nov",
        11:"Dec"
      }

      let ampm='AM';
      let y=d.getFullYear();

        console.log("d.getMonth() is ",d.getMonth());
        let m = monthNames[d.getMonth()];

      let da=d.getDate();
      let h=d.getHours();
        if (h>12) h=h-12; ampm='PM';
      let mi=d.getMinutes();
        if (mi<10) mi="0"+mi;
      let localDateTimeString=m+' '+da+', '+y+' '+h+':'+mi + ' ' + ampm;
      //console.log("in getLocalDateTimeString with localDateTimeString=" + localDateTimeString);
      return localDateTimeString;
    }
  },




  skierAbility: function(level) {
    var level = +level;  //converts string to number
    if ( isNaN(level) ) return false;
    if (level > 8) return "Professional";
    if (level >  5) return "Advanced";
    if (level > 3) return "Intermediate";
    return "Beginner";
  },




  fsSkierAbilityChart: function(level) {
    var level = +level;  //converts string to number
    if ( isNaN(level) ) return false;
    let str=`<ul class="abilityChart">
              <li id="fs_beginner">Beginner</li>
              <li id="fs_intermediate">Intermediate</li>
              <li id="fs_advanced">Advanced</li>
              <li id="fs_professional">Professional</li>
            </ul>`;

    let ability = Utility.skierAbility(level).toLowerCase();
    return {html:str, ability:ability}
  },



  speedConverter: function(obj) {
    let to = obj.toUnit;
    let speed = +obj.speed; // convert to number with plus
    let mph=[15.5,17.4,19.2,21.1,23.0,24.9,26.7,28.6,30.4,32.3,34.2,36.0];
    let kph=[25,28,31,34,37,40,43,46,49,52,55,58];

    if (to=='mph') return {speed: mph[kph.indexOf(speed)],toUnit: to}
    if (to=='kph') return {speed: kph[mph.indexOf(speed)],toUnit: to}
    return null;
  },




  inchesToFeet: function(inches) {
    if (inches==null || inches < 1) return '';
    let f = Math.floor(parseInt(inches)/12);
    let i = inches % 12;
    let result = f.toString() + '-' + i.toString()+ '"';
    return result;
  }



}

export default Utility;
