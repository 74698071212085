import $$ from 'dom7';
var SkiLookup = {

  skiLookup: function () {
  //$$('#skiLookup').click(function() {
    console.log('skiLookkup clicked');
    if (!app.methods.onLine()) return app.methods.onOffline();

    if ($$('.page #list_brand')) $$('.page #list_brand').remove();
    if ($$('.page .smart-select #brand_select_id')) $$('.page .smart-select #brand_select_id').remove();

    if ($$('.page #list_model')) $$('.page #list_model').remove();
    if ($$('.page .smart-select #model_select_id')) $$('.page .smart-select #model_select_id').remove();

    if ($$('.page #list_length')) $$('.page #list_length').remove();
    if ($$('.page .smart-select #length_select_id')) $$('.page .smart-select #length_select_id').remove();

    if ($$('.page #list_year')) $$('.page #list_year').remove();
    if ($$('.page .smart-select #year_select_id')) $$('.page .smart-select #year_select_id').remove();

    var loggedIn=false;
    var url=app.data.wsURL+'ws_ski_lookup_ret_json.php';

    app.request({url:url,data:{ ski_attr: "brands",logged_in:loggedIn},type:'POST',dataType: 'json',success:function(brandsObj) {
      console.log('in success for skiLookup for brands');
      $$(".page #skiSelected").html('').hide();

      var brandList = '<ul id="ul_stock_list"><li id="list_brand"><a href="#" class="item-link smart-select" data-back-on-select="true" data-page-title="Ski Brand"><select name="brand" id="brand_select_id" class="brand_class">';

      //brandList += '<option>Select Brand...</option>';
      $$.each(brandsObj, function( index, value ) {
        brandList += '<option name="brand_option" value="'+ value.brand + '">'+ value.brand + '</option>';
      });
      brandList += '</select><div class="item-content"><div class="item-inner"><div class="item-title">Brand</div><div class="item-after"></div></div></div></a></ul>';



      $$(".page #factory_brand").html(brandList).trigger('create');
      console.log('factory_brand selector created with ' , brandList);
      $$(".page #skiLookup").hide();


      }, timeout: 5000

      , beforeSend: function(){
        console.log('beforeSend skiLookup for brands');
        //setTimeout( app.showIndicator(),4200);
        //t = setTimeout( app.showIndicator , 200);
        app.preloader.show();
        //console.log('brandtimeout is ' + t);

      }, complete: function(){
          console.log('complete skiLookup for brands');
          $$('.page #brand_select_id').click();

          // I animate closing these when user saves settings, so need to unanimate them
          $$('.page #factory_brand').css({'opacity':1,'display':'block','height':'auto'});
          app.preloader.hide();

      }, error: function(brandsObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + brandsObj + status + err);
          } else {
            console.log("error: " + status + err);
          }
      }
    }) // END ajax function for ski brands
  //});
},



  getModels: function () {

    if (!app.methods.onLine()) return app.methods.onOffline();

    if ($$('.page #list_model')) $$('.page #list_model').remove();
    if ($$('.page .smart-select #model_select_id')) $$('.page .smart-select #model_select_id').remove();

    if ($$('.page #list_length')) $$('.page #list_length').remove();
    if ($$('.page .smart-select #length_select_id')) $$('.page .smart-select #length_select_id').remove();

    if ($$('.page #list_year')) $$('.page #list_year').remove();
    if ($$('.page .smart-select #year_select_id')) $$('.page .smart-select #year_select_id').remove();


    $$(".page #skiSelected").html('').hide();

    //theBrand = $$('.smart-select select')["0"].value;
    //if ( $$('.page .smart-select #brand_select_id')["1"] ) $$('.page .smart-select #brand_select_id')["1"].remove();

    //console.log('HOLY COW!!!!! brand is  ' +  $$(".page .smart-select .item-after").text() );
    //$$(".page .smart-select .item-after").html($$('.page .smart-select #brand_select_id')["0"].value);

    theBrand = $$('.page .smart-select #brand_select_id')["0"].value;

    var url=app.data.wsURL+'ws_ski_lookup_ret_json.php';

		app.request({url:url,data:{ ski_attr:"models",brand:theBrand },type:'POST',dataType: 'json',success:function(modelsObj) {

			var modelList = '<li id="list_model"><a href="#" class="item-link smart-select" data-back-on-select="true" data-page-title="Ski Model"><select name="model" id="model_select_id" class="model_class">';

			$$.each(modelsObj, function( index, value ) {
				modelList += '<option value="'+ value.model + '">'+ value.model + '</option>';
			});

      modelList += '</select><div class="item-content"><div class="item-inner"><div class="item-title">Model</div><div class="item-after"></div></div></div></a>';

			$$(".page #ul_stock_list").append(modelList).trigger('create');

      }, timeout: 5000
      , beforeSend: function(){
        console.log('beforeSend skiLookup for models');
        app.preloader.show();

      }, complete: function(){
          console.log('complete skiLookup for models');
          $$('#model_select_id').click(); // this would autoclick the next selector, but not the correct place to put it.
          console.log('after click triggered');

/*
          if ($$(".page #list_brand .item-content .item-inner .item-after").text() == null) {
            console.log("      IN empty brand after selected");
            $$(".page #list_brand .item-content .item-inner .item-after").text($$('.page .smart-select #brand_select_id')["0"].value) ;
          }
*/

          app.preloader.hide();

      }, error: function(brandsObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + brandsObj + status + err);
          } else {
            console.log("error: "  + status + err);
          }
      }
    }) // END ajax function for models
	},



    // ******* AJAX CALL FOR SKI LENGTH	*********//
    getLengths: function () {
          if (!app.methods.onLine()) return app.methods.onOffline();

          if ($$('.page #list_length')) $$('.page #list_length').remove();
          if ($$('.page .smart-select #length_select_id')) $$('.page .smart-select #length_select_id').remove();

          if ($$('.page #list_year')) $$('.page #list_year').remove();
          if ($$('.page .smart-select #year_select_id')) $$('.page .smart-select #year_select_id').remove();

        $$(".page #skiSelected").html('').hide();

        theModel = $$('.page .smart-select #model_select_id')["0"].value;

      var url=app.data.wsURL+'ws_ski_lookup_ret_json.php';
  		//app.request({url:url,data:{ ski_attr: "lengths", brand: theBrand, model: theModel, year: theYear},type:'POST',dataType: 'json',success:function(lengthsObj) {
      app.request({url:url,data:{ ski_attr: "lengths", brand: theBrand, model: theModel},type:'POST',dataType: 'json',success:function(lengthsObj) {
  			var lengthList = '<li id="list_length"><a href="#" class="item-link smart-select" data-back-on-select="true" data-page-title="Ski Length"><select name="length" id="length_select_id">';
  			$$.each(lengthsObj, function( index, value ) {
  				lengthList += '<option value="'+ value.length + '">'+ value.length + '</option>';
  			});
        lengthList += '</select><div class="item-content"><div class="item-inner"><div class="item-title">Length</div><div class="item-after"></div></div></div></a>';
        $$(".page #ul_stock_list").append(lengthList).trigger('create');

      }, timeout: 5000
        , beforeSend: function(){
          console.log('beforeSend skiLookup for length');
          app.preloader.show();
        }, complete: function(){
            console.log('in complete for lengthsObj');
            app.preloader.hide();
            $$('#length_select_id').click(); // this would autoclick the next selector, but not the correct place to put it.
    		}, error: function(lengthsObj, status, err) {
            if (status == "timeout") {
              console.log("Timeout Error. " + lengthsObj + status + err);
            } else {
              console.log("error: "  + status + err);
            }
        }
      }) // END ajax function
  	},


  getStockSettings: function () {
    console.log('in getStockSettings');
    if (!app.methods.onLine()) return app.methods.onOffline();

    console.log(app.data.lookup.skiYear);
        if (typeof app.data.lookup.skiYear == 'undefined') {
          var optionalYear='';
        } else {
          var optionalYear=app.data.lookup.skiYear;
        }

        var url=app.data.wsURL+'ws_get_stock_settings_ret_json.php';
    		app.request({url:url,data:{ theBrand:app.data.lookup.skiBrand, theModel:app.data.lookup.skiModel, theYear:optionalYear, theLength:app.data.lookup.skiLength},type:'POST',dataType: 'json',success:function(stock_Obj) {
          if (stock_Obj.status == 'success' && stock_Obj.RETURN_CODE == 1) { // RETURNED RESULTS
            app.data.settingArray=stock_Obj.data; // this will have the array of all ski settings.

            console.log('stock_Obj.status=' +stock_Obj.status);
            if (stock_Obj.status == 'success' && stock_Obj.RETURN_CODE == 1) {
              $$(".page #skiSelected").html('<div class="center bold">' +optionalYear+ ' ' +app.data.lookup.skiBrand+ ' ' +app.data.lookup.skiModel+ ' ' +app.data.lookup.skiLength+ '</div>');
              $$(".page #skiSelected").append('<div class="row head_setting_table"><div class="col-20">Binding</div><div class="col-20">Length</div><div class="col-20">Depth</div><div class="col-20">DFT</div><div class="col-20">Wing</div></div>');

              var ss=stock_Obj.data;

              ss.forEach( function( element ) {
                if (element.setting_name) {
                  $$(".page #skiSelected").append('<div class="marginBottomMinusHalf"><span class="lightBlueText">' +element.setting_name+ '</span></div>');
                }
                    $$(".page #skiSelected").append('<div class="row data_setting_table"><div class="col-20" id="stock_binding">'+element.stock_binding_location+'</div><div class="col-20" id="stock_length">'+element.stock_fin_length+'</div><div class="col-20" id="stock_depth">'+element.stock_fin_depth+'</div><div class="col-20" id="stock_dft">'+element.stock_fin_dft+'</div><div class="col-20"  id="stock_wing_angle">'+element.stock_wing_angle+'</div></div>');
              });


              $$(".page #skiSelected").append('<div class="center top_padding_5"><a href="#" id="howIsMeasured" class="create-sheet measure-sheet brand_'+app.data.lookup.skiBrand+'">How is this measured?</a></div>');

              if ($$(".page #saveStock").length<1) {
                $$('<div class="padding_top_5em"><a href="#" class="button button-fill" id="saveStock">Save to Stock List</a></div>').appendTo('.page #skiSelected');
              }

          		$$(".page #skiSelected").show();
            } else {
              console.log('json success, but no stock ski data found.');
            }
          }

        }, timeout: 5000
          , beforeSend: function(){
            console.log('beforeSend skiLookup for length');
            app.preloader.show();
          }, complete: function(){
              console.log('in complete for stock_Obj');

              var fbHeight=$$('#factory_brand').height();
              $$('#factory_brand').css({'height':fbHeight+'px'});
              document.getElementById('factory_brand').style.height = fbHeight+'px';

              $$('.page #saveStock').css({'opacity':1,'height':'auto'});
              $$('.page #skiSelected').css({'opacity':1,'height':'auto'});
              app.preloader.hide();

      		}, error: function(stock_Obj, status, err) {
              if (status == "timeout") {
                console.log("Timeout Error. " + stock_Obj + status + err);
              } else {
                console.log("error: "  + status + err);
              }
          }
        }) // END ajax function


    	},

  howBrandMeasures: function(obj) {
    // THIS BINDS IT IF DYNAMICALLY CREATED AFTER DOM INTIALLY LOADS
     // Check first, if we already have opened picker
     console.log(obj);
     //return;
     console.log($$(obj).attr('class'));

    // console.log("this id is " + $$(obj).attr('id') );

     var theBrand=SkiLookup.getBrandByClass(obj);
     console.log('theBrand=' + theBrand);

     if (app.data.measureObj[0][theBrand].binding!='Unknown') {

     console.log('theBrand is ' + theBrand);

     var measureSheet=app.sheet.create({
       content:'<div class="sheet-modal" id="measure_sheet">' +
         '<div class="toolbar">' +
           '<div class="toolbar-inner">' +
             '<div class="left bold">Measuring for ' +theBrand+'</div>' +
             '<div class="right"><a href="#" class="link sheet-close"><i class="f7-icons">xmark_circle_fill</i></a></div>' +
           '</div>' +
         '</div>' +
         '<div class="sheet-modal-inner">' +
           '<div class="block">' +
             //'<div class="row measure_table"><div class="col-25 bold">Binding</div><div class="col-75">'+measureObj[theBrand].binding+'</div></div>' +
             '<div class="row measure_table"><div class="col-25 bold">Binding</div><div class="col-75">'+app.data.measureObj[0][theBrand].binding+'</div></div>' +
             '<div class="row measure_table"><div class="col-25 bold">Length</div><div class="col-75">'+app.data.measureObj[0][theBrand].length+'</div></div>' +
             '<div class="row measure_table"><div class="col-25 bold">Depth</div><div class="col-75">'+app.data.measureObj[0][theBrand].depth+'</div></div>' +
             '<div class="row measure_table"><div class="col-25 bold">DFT</div><div class="col-75">'+app.data.measureObj[0][theBrand].dft+'</div></div>' +
             '<div class="row measure_table"><div class="col-25 bold">Wing</div><div class="col-75">'+app.data.measureObj[0][theBrand].wing+'</div></div>' +
           '</div>' +
         '</div>' +
       '</div>'
     });
     measureSheet.open();
   } else {
     var measurePopover=app.popover.create({
           targetEl: '#'+$$(obj).attr('id'),
           content:'<div class="popover">'+
                   '<div class="popover-inner">'+
                     '<div class="content-block">'+
                       '<p>No measurement data for this ski is documented.  However, standard practice is:</p>'+
                       '<ul><li>Binding: From front heel</li>'+
                       '<li>Length: Tips</li>'+
                       '<li>Depth: Flat</li>'+
                       '<li>DFT: Flat</li>'+
                       '<li>Wing: Screws above</li>'+
                       '</ul>'+
                     '</div>'+
                   '</div>'+
                 '</div>'
             });
     measurePopover.open();
   }
 },

  getBrandByClass: function (clickedObj) {
   for (let i=0; i<Object.keys(app.data.measureObj[0]).length; i++) {
     var brandVar='brand_'+Object.keys(app.data.measureObj[0])[i];
     if ( $$(clickedObj).hasClass(brandVar) ) return Object.keys(app.data.measureObj[0])[i];
   }

 }


}

export default SkiLookup;
