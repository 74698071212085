import HomePage from "../pages/home.f7.html";
import RegisterPage from "../pages/register.f7.html";
import LookupPage from "../pages/lookup/index.f7.html";
import BrandList from "../pages/lookup/brand_list.f7.html";
import ModelList from "../pages/lookup/model_list.f7.html";
import LengthList from "../pages/lookup/length_list.f7.html";
import YearList from "../pages/lookup/year_list.f7.html";
import MySettingsPage from "../pages/my-settings.f7.html";
import MySkisPage from "../pages/my-skis.f7.html";
import ProfilePage from "../pages/profile.f7.html";
import FinSharePage from "../pages/finShare/finShare.f7.html";
import FinShareDetailsPage from "../pages/finShare/finShareDetails.f7.html";
import FinShareAddPage from "../pages/finShare/finShareAdd.f7.html";
import FinShareFollowersPage from "../pages/finShare/finShareFollowers.f7.html";
import FinShareOverviewPage from "../pages/finShare/finShareOverview.f7.html";
import NotificationsPage from "../pages/notifications/notifications.f7.html";

import NotFoundPage from "../pages/404.f7.html";

import pushNotify from "../js_my/pushNotify.js";
import Interface from "../js_my/interface.js";

import Skis from "../js_my/ski_class.js";
import Settings from "../js_my/settings_class.js";
import Users from "../js_my/user_class.js";
import Register from "../js_my/register.js";
import MySettings from "../js_my/my_settings.js";
import MySkis from "../js_my/my_skis.js";
import Profile from "../js_my/profile.js";
import FinShare from "../js_my/fin_share.js";
import Notifications from "../js_my/notifications.js";
import Utility from "../js_my/utility.js";
import SkiLookup from "../js_my/ski_settings_smart_select.js";
import LocalSave from "../js_my/save_stock_settings_local.js";
import FirstEntry from "../js_my/first_entry.js";

var routes = [
  {
    path: "/",
    //    url: './index.html',
    //    name: 'home',
    component: HomePage,
    on: {
      pageInit: function (e, page) {
        // do something on page init
        console.log(" ------ > page init in route.js for home page");

        // INITIALIZE OBJECT TO STORE VARIABLES FOR SKI LOOKUP
        //          app.data.lookup={};
        //          app.data.settingArray={};

        $$("#user_name").val(localStorage.getItem("user_name"));
        $$("#pwd").val(localStorage.getItem("pwd"));

        if (localStorage.getItem("activation_code")) {
          // check to see if there is any pending activation of a user account
          var pendingUserName = localStorage.getItem("pending_user_name");
          $$(".page #registerBtn").text(
            "Pending Activation for " + pendingUserName
          );
        } else {
          $$(".page #registerBtn").text("Register to Begin");
        }

        $$(".page #loginBtn").click(function () {
          console.log("initiating LOGIN from onPageInit in router!");
          //loginEventStr += "\r\nlogin initiated from onPageInit for login page";
          Users.loginUser();
        });

        //$$(document).on('click',"#forgotBtn", function(e) {
        $$('.page[data-page="login"]').on("click", "#forgotBtn", function (e) {
          console.log("clicked forgotBtn");

          var forgetSomething = app.dialog
            .create({
              title: "Forget Something?",
              text: "Retrieve your user name or reset your password.",
              buttons: [
                {
                  text: "User Name",
                  bold: true,
                  onClick: function () {
                    retrieveUserName();
                  },
                },
                {
                  text: "Password",
                  bold: true,
                  onClick: function () {
                    forgotPassword();
                  },
                },
              ],
            })
            .open();
        });

        function retrieveUserName() {
          app.dialog.prompt(
            "Please enter the Email Address associated with finDB.",
            "Retrieve User Name",
            function (value) {
              if (Utility.validEmail(value)) {
                Utility.requestUserName(value);
              } else {
                console.log("invalid email format");
                app.dialog.alert(
                  "Email address <span class='bold'>" +
                    value +
                    "</span> is not properly formatted.  Please re-enter.",
                  "Invalid Email Format",
                  function () {
                    retrieveUserName();
                  }
                );
              }
            }
          );
        }

        function forgotPassword() {
          app.dialog.prompt(
            "Please enter your User Name or Email Address.",
            "Forgot Password",
            function (value) {
              Utility.generatePwResetCode(value);
            }
          );
          // wait until prompt is generated, then populate with username if available
          if (typeof localStorage.getItem("user_name") !== "undefined") {
            $$(".dialog .dialog-inner .dialog-input").val(
              localStorage.getItem("user_name")
            );
          }
        }

        //$$('.page #logoutBtn').click(function() {
        $$('.page[data-page="menu-panel"]').on(
          "click",
          "#logoutBtn",
          function (e) {
            Users.logoutUser();
            Users.thisUser.clearUser(Users.thisUser);
            Skis.thisSki.clearSki(Skis.thisSki);
            Settings.thisSetting.clearSettings(Settings.thisSetting);
          }
        );
      }, // END PAGEINIT
      pageAfterIn: function () {},
    },
  },

  {
    path: "/finShare/",
    component: FinSharePage,
    beforeEnter: function (routeTo, routeFrom, resolve, reject) {
      if (FinShare.isProfileEligible() && Interface.getUser().fin_share) {
        console.log(FinShare.isProfileEligible());
        resolve();
        console.log("resolved");
      } else {
        // don't allow to visit finShare page
        console.log("rejected");
        reject();
        if (!FinShare.isProfileEligible()) {
          FinShare.renderIncompleteProfileModal();
        }
        this.navigate("/finShare/overview/");
      }
    },
    on: {
      pageInit: function () {
        console.log("on pageInit finShare ");
        console.log(Interface.getUser().fin_share);
        if (!Interface.getUser().fin_share) {
          //app.views.main.router.navigate('/finShare/overview/');
          console.log("is NOT finShareable");
        } else {
          console.log("is finShareable");
        }
      }, // END PAGEINIT
      pageAfterIn: function () {
        console.log("on pageAfterIn finShare ");
        FinShare.init();
        FinShare.getFinShareList();
        //FinShare.getFinShareRequestCount();

        //$$('.page #finShare_div .finShareRow').click(function() {
        $$('.page[data-page="finShare"]').on(
          "click",
          ".finShareRow",
          function (e) {
            console.log("initiating finShare user lookup in router!");
            let finshare_user_name = this.dataset.finShareUser;
            console.log("un...", finshare_user_name);
            //var finshare_user_name = this.id.split('_')[1];
            app.views.main.router.navigate(
              "/finShare/finShareDetails/" + finshare_user_name
            );
          }
        );

        $$('.page[data-page="finShare"]').on(
          "click",
          "#fs_addFinshare",
          function (e, page) {
            console.log("fs_addFinshare clicked...");
          }
        );
      },
    },
  },

  {
    path: "/finShare/overview/",
    component: FinShareOverviewPage,
    on: {
      pageInit: function () {
        console.log("on pageInit finShare overview ");

        // $$('#overviewBack').on('click', function (e) {
        //   console.log("in finShare click back link... INIT");
        //
        // });
      }, // END PAGEINIT
      pageAfterIn: function () {
        console.log("on pageAfterIn finShareoverview ");
        let toggle = app.toggle.get(".toggle");
        toggle.checked = Interface.getUser().fin_share ? true : false;
        app.emit("finShareChange", Interface.getUser().fin_share);
        app.emit("finShareReqCountChange", null);

        $$("#finShareToggle").on("change", function () {
          console.log("toggle listener...");
          FinShare.toggleFinShare();
        });

        //$$('.page[data-page="finShareOverview"]').on('click', '#overviewBack', function (e) {
        $$("#overviewBack").on("click", function (e) {
          console.log(
            "in finShare click back link...PAGE AFTER IN with fin_share=" +
              Interface.getUser().fin_share
          );
          if (!Interface.getUser().fin_share) {
            console.log("in go to homepage");
            app.views.main.router.navigate("/mySettings/");
            //app.views.main.router.back('/mySettings/',{force:true});
          } else {
            console.log("in go to finshare");
            app.views.main.router.navigate("/finShare/");
          }
        });

        $$(".page .regDPP").on("click", function () {
          Interface.viewDataPrivacy();
        });
      },
    },
  },

  {
    path: "/finShare/finShareDetails/:finshare_user_name",
    name: "finShareDetailsPageName",
    component: FinShareDetailsPage,
    on: {
      pageInit: function () {
        console.log("on pageInit finShareDetails ");
        console.log(Interface.getUser().fin_share);
      }, // END PAGEINIT
      pageAfterIn: function (e, page) {
        console.log("on pageAfterIn finShare Details");
        console.log(page.route.params);
        let finshare_user_name = page.route.params.finshare_user_name;
        FinShare.getFinShareUserData(finshare_user_name);

        $$("#fs_removeFinshare").on("click", function (e) {
          console.log(
            "fs_removeFinshare clicked...user=" + this.dataset.finShareUser
          );
          console.log(e);
          FinShare.deleteFinShareUser(this);
        });

        $$("#fs_prev_settings").on("click", function (e) {
          console.log(
            "fs_prev_settings clicked...user=" +
              this.dataset.finShareUser +
              " and ski_id=" +
              this.dataset.finShareSkiId
          );
          MySettings.viewHistory(
            this.dataset.finShareUser,
            this.dataset.finShareSkiId
          );
          $$(".fs_currentUser div, #fs_settingsList .sa-msg").remove();
          $$("#fs_settingsAdjuster").css("font-size", "initial");
        });

        $$('.page[data-page="finShareDetails"]').on(
          "click",
          "#fs_compare_link",
          function (e, page) {
            //          $$('#fs_compare_link').on('click', function (e) {
            console.log("fs_compare_link clicked...", this);
            let thisID = this.id;
            $$("#" + thisID).html("My Ski");
            FinShare.compareSettings();
          }
        );

        $$('.page[data-page="finShareDetails"]').on(
          "click",
          "#fs_stock",
          function (e, page) {
            //          $$('#fs_compare_link').on('click', function (e) {
            console.log("fs_stock clicked...");
            FinShare.stockSettings();
          }
        );

        $$('.page[data-page="finShareDetails"]').on(
          "click",
          "#fs_seg_compare button",
          function (e, page) {
            //          $$('#fs_compare_link').on('click', function (e) {
            let thisID = this.id;
            let buttons = $$("#" + thisID).parent()[0].id;
            $$("#" + buttons + " button").removeClass("button-active");
            $$("#" + thisID).addClass("button-active");
            if (thisID == "fs_prev_settings") {
              setTimeout(function () {
                $$("#" + buttons + " button").removeClass("button-active");
              }, 1000);
            }
          }
        );

        $$('.page[data-page="finShareDetails"]').on(
          "click",
          ".same-ski",
          function (e, page) {
            FinShare.sameSkiPopOver();
          }
        );

        $$('.page[data-page="finShareDetails"]').on(
          "click",
          ".finSA-info",
          function (e, page) {
            FinShare.finSAPopOver();
          }
        );
      },
    },
  },

  {
    path: "/finShareAdd/",
    component: FinShareAddPage,
    on: {
      pageInit: function () {
        console.log("on pageInit finShareADD ");

        var searchbar = app.searchbar.create({
          el: ".searchbar",
          customSearch: true,
          // searchContainer: '.list',
          // searchIn: '.item-title',
          on: {
            search(sb, query, previousQuery) {
              console.log(query, previousQuery);
              FinShare.searchFinShare(query);
            },
          },
        });
      }, // END PAGEINIT
      pageAfterIn: function () {
        console.log("on pageAfterIn finShareADD");

        $$('.page[data-page="finShareAdd"]').on(
          "click",
          "#searchResults li",
          function (e) {
            console.log("in searchResults link...");
            FinShare.addFinShareUser(this);
          }
        );
      },
    },
  },

  {
    path: "/finShare/followers/",
    component: FinShareFollowersPage,
    beforeEnter: function (routeTo, routeFrom, resolve, reject) {
      if (Interface.getUser().fin_share) {
        resolve();
        console.log("resolved");
      } else {
        // don't allow to visit this page for unauthenticated users
        reject();
        console.log("rejected");
        //app.views.main.router.navigate('/finShare/overview/');
        this.navigate("/finShare/overview/");
      }
    },
    on: {
      pageInit: function () {
        console.log("on pageInit finShare ");
        console.log(Interface.getUser().fin_share);
        if (!Interface.getUser().fin_share) {
          //app.views.main.router.navigate('/finShare/overview/');
          console.log("is NOT finShareable");
        } else {
          console.log("is finShareable");
        }
      }, // END PAGEINIT
      pageAfterIn: function () {
        console.log("on pageAfterIn finShare ");
        FinShare.init();
        FinShare.getFinShareFollowers();
        //FinShare.getFinShareRequestCount();

        $$('.page[data-page="finShareFollowers"]').on(
          "click",
          "#fs_requested li",
          function (e) {
            console.log("requested with " + this.dataset.finShareUser);
            FinShare.manageRequested(this.dataset.finShareUser);
          }
        );

        $$('.page[data-page="finShareFollowers"]').on(
          "click",
          "#fs_approved li",
          function (e) {
            console.log("approved with " + this.dataset.finShareUser);
            FinShare.manageApproved(this.dataset.finShareUser);
          }
        );
      },
    },
  },

  {
    path: "/notifications/",
    component: NotificationsPage,
    on: {
      pageInit: function () {
        console.log("on pageInit NotificationsPage ");
      }, // END PAGEINIT
      pageAfterIn: function () {
        console.log("on pageAfterIn NotificationsPage");

        Notifications.getNotifications();

        $$('.page[data-page="notifications"]').on("click", "li", function (e) {
          console.log("clicked with dataset... " + this.dataset.pageId);
          //let uri='/finShare/finShareDetails/pauls';
          //let uri="/"+this.dataset.pageId+"/";
          //app.views.main.router.navigate(this.dataset.pageId);
        });
      },
    },
  },

  {
    path: "/register/",
    //url: './pages/register.html',
    //name: 'register',
    component: RegisterPage,
    on: {
      pageInit: function () {
        // do something on page init
        console.log(" ------ > page init in route.js for register page");

        //$$('.page[data-page="register"]').on('click', '#t_and_c', function (e) {
        $$(".page .regTandC").on("click", function () {
          Interface.viewTerms();
        });

        //$$('.page[data-page="register"]').on('click', '#dpp', function (e) {
        $$(".page .regDPP").on("click", function () {
          Interface.viewDataPrivacy();
        });

        $$(".page #registerBtn").click(function () {
          console.log("clicked registerBtn");
          Register.registerUser();
        });

        if (localStorage.getItem("activation_code")) {
          // check to see if there is any pending activation of a user account
          var pendingUserName = localStorage.getItem("pending_user_name");
          console.log("pendActivation initiated");
          Register.showActivationPrompt(
            localStorage.getItem("pending_user_name")
          );
        }
      },
      pageAfterIn: function (e, page) {
        console.log(
          "-------> on.pageAfterIn EVENT called from router for register"
        );

        // $$('#t_and_c').on('change', function () {
        //   console.log("t_and_c value is " + this.value );
        //   if (this.value==0) {
        //     this.value=1;
        //   } else {
        //     this.value=0
        //   }
        // });
      },
    },
  },

  {
    path: "/mySettings/",
    // url: '../pages/mysettings.html',
    // name: 'mySettings',
    component: MySettingsPage,
    on: {
      pageInit: function () {
        // do something on page init
        console.log(" ------ > page init in route.js for mySettings page");
        MySettings.init_ski();

        $$(".page #viewStockBtn").click(function () {
          //console.log(app.data.thisSetting);
          MySettings.toggleViewStock();
        });

        $$(".page #editFinBtn").click(function () {
          console.log("editFinBtn clicked");
          MySettings.init_slider();
        });

        $$(".page #editSkiBtn").click(function () {
          console.log("editSkiBtn clicked");
        });

        $$(document).on(
          "touchend",
          "#bindingRange, #lengthRange , #depthRange , #dftRange , #wingRange",
          function (e) {
            console.log("------------> touch ended with id=" + this.id);
            Interface.interface_mySettings_updateSlider(e, this.id);
          }
        );

        $$('.page[data-page="mySettings"]').on(
          "click",
          ".view_history",
          function (e) {
            console.log(
              "------------------------view_history from mySettings page here."
            );
            MySettings.viewHistory();
          }
        );

        //$$(document).on('click', '#cancelSaveBtn', function (e) {
        $$('.page[data-page="mySettings"]').on(
          "click",
          "#cancelSaveBtn",
          function (e) {
            MySettings.cancelSave();
          }
        );
      },
      pageAfterIn: function () {
        console.log(" ------ > page after in for mySettings ");
        console.log("############# USER FULLY LOGGED IN #############");
        console.log(Users.thisUser.getUser);
        console.log(Settings.thisSetting.getSettings);
        console.log(Skis.thisSki);

        MySettings.populateCurrentBindings();

        console.log("update msg is " + $$("#app .panel #update_msg"));
        if (
          typeof app.data.upd_vers_Obj != "undefined" &&
          app.data.upd_vers_Obj.vers_update < 0
        ) {
          console.log(
            "need an update " + $$(".fs-req-count").children().length
          );
          if (!$$(".fs-req-count .badge")[0]) {
            // show 'NEW' software badge if no finshare badge is shown
            $$("#versBadge").removeClass("hide");
          }

          $$("#app .panel #update_msg").html(
            "<a href='#' id='versDetails'>Software update available.  <i class='icon f7-icons color-white'>info_circle_fill</i></a>"
          );
          $$("#app .panel #update_msg").css("display", "block");
        } else {
          console.log("NO update needed");
          $$("#app .panel #update_msg").html("");
          $$("#app .panel #update_msg").hide();
        }

        $$("#versDetails").click(function () {
          console.log("versDetails via clicked from router");
          MySettings.versionDetails(app.data.upd_vers_Obj);
        });

        MySettings.displaySetupNotes();
        app.emit("finShareReqCountChange", null);
      },
      pageBeforeRemove: function () {
        console.log(" ------ > pageBeforeOut in for mySettings page ");
        if (typeof thisSetting == "undefined") {
          console.log(" No settings saved for user in session ");
        }
      },
    },
  },

  {
    path: "/mySkis/",
    // url: './pages/mySkis.html',
    // name: 'mySkis',
    component: MySkisPage,
    on: {
      pageInit: function () {
        // do something on page init
        console.log(
          " ------ > page init in route.js for mySkis page with G_LOOKUP_TYPE=" +
            app.data.G_LOOKUP_TYPE
        );

        app.data.G_LOOKUP_TYPE = "addSki";

        MySkis.getMySkis(Interface.getUser().user_name);

        $$(".page #ul_mySkis_list").change(function () {
          var ski_id = $$("input[name='my-radio']:checked").val();
          console.log("change current ski triggered with ski_id " + ski_id);
          let obj = {
            user_name: Interface.getUser().user_name,
            token: Interface.getUser().token,
            ski_id: ski_id,
          };
          Skis.setCurrentSki(obj);
        });

        // $$(document).on('click', '#addSkiBtn', function () {
        //   //addSki();
        //   console.log("addSkiBtn clicked.");
        //   skiLookup();
        // });

        //$$(document).on('click', '#saved_ski_list a.renameSki', function (e) {
        $$('.page[data-page="mySkis"]').on(
          "click",
          "#saved_ski_list a.renameSki",
          function (e) {
            var theID = $$(this).attr("id");
            theID = theID.substring(theID.lastIndexOf("_") + 1, theID.length);
            MySkis.changeSkiName(theID);
          }
        );

        $$(document).on("page:back", ".smart-select-page", function (e) {
          //app.on('pageInit', '.page[data-select-name="brand_add"]' => { // new V2 page init
          console.log("brands smart select initialized in addski ");

          $$(".smart-select #brand_select_id_add").change(getModels_add); // run getModels function
          $$('.page[data-select-name="brand_add"]')
            .find("input[type='radio']:checked")
            .prop("checked", false);
        });
      },
    },
  },

  // popup route below
  // {
  //   path: '/popupNoteC/',
  //   popup: {
  //     //componentUrl: './components/popup_setting_note.html',
  //     content: '\
  //     <div class="popup popup-notes">\
  //         <div class="page">\
  //         <div class="navbar">\
  //         <div class="navbar-inner">\
  //         <div class="title">Notes</div>\
  //         <div class="right"><a href="#" class="link popup-close">Close</a></div>\
  //         </div>\
  //         </div>\
  //         <div class="page-content">\
  //           <div class="block">\
  //           SOME TEXT \
  //             <div class="item-input">\
  //               <div class="range-slider range-slider-init">\
  //                   <input type="range" name="lengthRange" id="lengthRange" min="0" max="100" value="50" step="0.001">\
  //               </div>\
  //             </div>\
  //           </div>\
  //         </div>\
  //       </div>\
  //     </div>\
  //     '
  //   },
  // },

  {
    path: "/profile/",
    // url: './pages/profile.html',
    // name: 'profile',
    component: ProfilePage,
    on: {
      pageAfterIn: function () {
        // do something on page init
        console.log(" ------ > pageAfterIn in route.js for profile page");

        Profile.init_profile();

        $$("#editProfileBtn").click(function () {
          Profile.toggleEditSave();
        });

        $$("#profileBindingId").click(function () {
          var bindingIDPopover = app.popover.create({
            targetEl: "#" + $$(this).attr("id"),
            content:
              '<div class="popover">' +
              '<div class="popover-inner">' +
              '<div class="content-block">' +
              '<p class="bold">Why offer this data?</p>' +
              "<p>Binding type can have a significant impact when paired with fin settings. If you change your binding type, change this profile.</p>" +
              "</div>" +
              "</div>" +
              "</div>",
          });
          bindingIDPopover.open();
        });

        $$("#profileMeasureId").click(function () {
          var measureIDPopover = app.popover.create({
            targetEl: "#" + $$(this).attr("id"),
            content:
              '<div class="popover">' +
              '<div class="popover-inner">' +
              '<div class="content-block">' +
              '<p class="bold">Why offer this data?</p>' +
              "<p>How you measure your settings will be stored with each setting saved. If you change how you measure, change this profile.</p>" +
              "</div>" +
              "</div>" +
              "</div>",
          });
          measureIDPopover.open();
        });

        $$("#profileDemographicId").click(function () {
          var DemographicIdPopover = app.popover.create({
            targetEl: "#" + $$(this).attr("id"),
            content:
              '<div class="popover">' +
              '<div class="popover-inner">' +
              '<div class="content-block">' +
              '<p class="bold">Why offer this data?</p>' +
              "<p>As the systems gathers more data, you will be able to view the averages of other users settings for your demographic.</p>" +
              "</div>" +
              "</div>" +
              "</div>",
          });
          DemographicIdPopover.open();
        });
      },
    },
  },

  {
    path: "/lookup/",
    //url: './pages/lookup/index.html',
    //name: 'lookup',
    component: LookupPage,
    on: {
      pageInit: function () {
        // do something on page init
        console.log(
          " ------ > page init in route.js for lookup page with G_LOOKUP_TYPE=" +
            app.data.G_LOOKUP_TYPE
        );

        app.data.G_LOOKUP_TYPE = "findSki";

        if (LocalSave.getLocalStorage("stockSkis")) {
          LocalSave.getLocalSettings();
        } else {
          // show message on front page so user knows how to get started
          $$(
            '<div class="center">Start by selecting the link below, then choose a ski brand, model, length and year (optional).<p>Save for quick access in the future.</div>'
          ).insertAfter("#indexTitle");
        }

        console.log("triggering getHowToMeasure function");
        var measureObj = {}; // make object global
        app.methods.getHowToMeasure();
      },
      pageAfterIn: function (e, page) {
        console.log(
          "###############------> pageAfterIn fired for lookup in routes.js file.##########"
        );

        //GET STOCK SETTINGS OF THE SELECTED SKI...this only runs when all 4 values are present to retrieve the data
        if (Object.getOwnPropertyNames(app.data.lookup).length == 4) {
          SkiLookup.getStockSettings();
        }

        //$$(document).on('click', '#saveStock', function () {
        //$$('#saveStock').on('click', function () {
        $$('.page[data-page="lookup"]').on("click", "#saveStock", function (e) {
          console.log("clicked saveStock to run");
          document
            .getElementById("factory_brand")
            .setAttribute("style", "height:220px");
          console.log(
            "factory_brand height is " +
              document.getElementById("factory_brand").style.height
          );
          $$(".page #factory_brand, .page #skiSelected, .page #saveStock")
            .attr("style", "opacity:0;height:0px;margin-top:0px")
            .transition(750);

          LocalSave.storeSettingsLocally();

          $$("#saveStock").transitionEnd(function () {
            console.log("TRANSITION ENDED.......");
            $$(".page #factory_brand, .page #skiSelected").hide();
          });

          //$$('#saveStock').parent().remove();// removes the save buttons so can't duplicate saving
          $$(".page #skiLookup").show();
          LocalSave.getLocalSettings(); // this redraws screen for saved settings
          $$("#ul_saved_list>li:first-child").addClass("lightBlueBG"); //.css('background-color','#cfc');
        });

        //$$(document).on('click', '#ul_saved_list li a.swipeout-delete', function (e) {
        $$('.page[data-page="lookup"]').on(
          "click",
          "#ul_saved_list li a.swipeout-delete",
          function (e) {
            //var theID=e.target.parentNode.id;
            console.log("tagname of click was -------> " + e.target.tagName);
            console.log("parentNode id " + e.target.parentNode.id);
            console.log("className is " + e.target.className);
            console.log("style is " + e.target.style);
            console.log("this attr id " + $$(this).attr("id"));
            //var theID=e.target.id;
            var theID = $$(this).attr("id");
            console.log(
              "in deleteStockSki to run deleteStockSki function with target id = " +
                theID
            );
            LocalSave.deleteStockSki(theID); // deletes the indexed ski
            LocalSave.getLocalSettings(); // redraws screen so index's stay aligned for deletion
          }
        );

        $$(".swipeout").on("swipeout:deleted", function (e) {
          //var theID=e.target.parentNode.id;
          var theID = e.target.id;
          console.log("in swipeout:deleted with target id=" + theID);
          //deleteStockSki(theID);
          app.dialog.alert("Item removed");
          console.log("in swipeout:deleted");
        });

        $$(".swipeout").on("swipeout", function (e) {
          //e.preventDefault();
          console.log("Item opened on: " + e.detail.progress + "%");
        });

        //$$(document).on('click', '#skiSelected .create-sheet', function () {
        $$('.page[data-page="lookup"]').on(
          "click",
          ".measure-sheet",
          function (e) {
            SkiLookup.howBrandMeasures(this);
          }
        );
      },
    },
  },

  {
    path: "/lookup/brands/",
    async: function (routeTo, routeFrom, resolve, reject) {
      var logged_in = false;
      if (typeof Interface.getUser() !== "undefined") logged_in = true;
      let data = { ski_attr: "brands", logged_in: logged_in };
      app.request({
        url: app.data.wsURL + "ws_ski_lookup_ret_json.php",
        dataType: "json",
        data: data,
        method: "POST",
        //crossDomain: true,
        statusCode: {
          404: function (xhr) {
            console.log("page not found");
          },
        },
        complete: function () {
          console.log("complete lookup/brands");
        },
        success: function (response) {
          console.log("success lookup/brands");
          console.log(response);
          resolve(
            {
              component: BrandList,
            },
            {
              context: {
                brand: response,
              },
            }
          );
        },
        error: function () {
          console.log("error");
        },
      });
    },
    on: {
      pageMounted: function (e, page) {
        console.log(
          "-------> on.pageMounted EVENT called from router for BRANDS"
        );
        //console.log('-------> e is ' + e); this=e;
        //console.log('-------> page is ' + page); thispage=page;
        $$(".page-current .page-content ul li").on("click", function () {
          console.log("this value is is " + this.innerText);
          thisVal = this;
        });

        $$(".page-current").on("click", function () {
          console.log("clicked here");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageInit: function (e, page) {
        console.log("-------> on.pageInit EVENT called from router for BRANDS");

        $$(".page-current").on("click", function () {
          console.log("clicked here pageInit");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageAfterIn: function (e, page) {
        console.log(
          "-------> on.pageAfterIn EVENT called from router for BRANDS"
        );
        $$(".page-current").on("click", function () {
          console.log("clicked here afterIn");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });

        $$(document).on("click", "#brandList li a", function (e) {
          console.log("this brand is clicked");
          //let thisBrandIs = $$(this).data("brand-name");
          let thisBrandIs = $$(this).text();
          console.log("pageAfterIn this brand is " + thisBrandIs);
          //console.log("pageAfterIn this brand is " +a);
          app.data.lookup.skiBrand = thisBrandIs;
          //myApp.router.navigate('/lookup/models/')
          app.views.main.router.navigate("/lookup/models/");
        });
      },
    },
  },
  {
    path: "/lookup/brands/brand_list/",
    component: {
      template: `
      <div class="page">
        <div class="navbar">
          <div class="navbar-inner sliding">
            <div class="left">
              <a href="/lookup/brands/" class="link back">
                <i class="icon icon-back"></i>
                <span class="ios-only">Back</span>
              </a>
            </div>
            <div class="title">Brands</div>
          </div>
        </div>
        <div class="page-content">
          <div class="list links-list">
            <ul id="brandList">
              {{#each brand}}
              <li>
                <a class="item-link" data-brand-name="{{this}}">
                  {{this}}
                </a>
              </li>
              {{/each}}
            </ul>
          </div>
        </div>
      </div>
    `,
    },
  },

  {
    path: "/lookup/models/",
    async(to, from, resolve, reject) {
      console.log("app.data.lookup.skiBrand=" + app.data.lookup.skiBrand);
      let data = { ski_attr: "models", brand: app.data.lookup.skiBrand };
      app.request({
        url: app.data.wsURL + "ws_ski_lookup_ret_json.php",
        dataType: "json",
        data: data,
        method: "POST",
        crossDomain: true,
        statusCode: {
          404: function (xhr) {
            console.log("page not found");
          },
        },
        complete: function () {
          console.log("complete");
        },
        success: function (response) {
          console.log("success");
          console.log(response);
          resolve(
            {
              //componentUrl: './pages/lookup/model_list.html',
              component: ModelList,
            },
            {
              context: {
                model: response,
              },
            }
          );
        },
        error: function () {
          console.log("error");
        },
      });
    },
    on: {
      pageMounted: function (e, page) {
        console.log(
          "-------> on.pageMounted EVENT called from router for MODELS"
        );
        //console.log('-------> e is ' + e); thise=e;
        //console.log('-------> page is ' + page); thispage=page;
        $$(".page-current .page-content ul li").on("click", function () {
          console.log("this value is is " + this.innerText);
          thisVal = this;
        });

        $$(".page-current").on("click", function () {
          console.log("clicked here");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageInit: function (e, page) {
        console.log("-------> on.pageInit EVENT called from router for BRANDS");
        $$(".page-current").on("click", function () {
          console.log("clicked here pageInit");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageAfterIn: function (e, page) {
        console.log(
          "-------> on.pageAfterIn EVENT called from router for BRANDS"
        );
        $$(".page-current").on("click", function () {
          console.log("clicked here afterIn");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });

        $$("#modelList li a").on("click", function () {
          console.log("this model is clicked");
          let thisModelIs = $$(this).text();
          console.log("pageAfterIn this model is " + thisModelIs);
          app.data.lookup.skiModel = thisModelIs;
          //myApp.router.navigate('/lookup/models/')
          app.views.main.router.navigate("/lookup/length/");
        });
      },
    },
  },

  {
    path: "/lookup/length/",
    async(to, from, resolve, reject) {
      console.log("app.data.lookup.skiLength=" + app.data.lookup.skiLength);
      let data = {
        ski_attr: "lengths",
        brand: app.data.lookup.skiBrand,
        model: app.data.lookup.skiModel,
      };
      app.request({
        url: app.data.wsURL + "ws_ski_lookup_ret_json.php",
        dataType: "json",
        data: data,
        method: "POST",
        crossDomain: true,
        statusCode: {
          404: function (xhr) {
            console.log("page not found");
          },
        },
        complete: function () {
          console.log("complete");
        },
        success: function (response) {
          console.log("success");
          console.log(response);
          resolve(
            {
              //componentUrl: './pages/lookup/length_list.html',
              component: LengthList,
            },
            {
              context: {
                skiLength: response,
              },
            }
          );
        },
        error: function () {
          console.log("error");
        },
      });
    },
    on: {
      pageMounted: function (e, page) {
        console.log(
          "-------> on.pageMounted EVENT called from router for LENGTH"
        );
        //console.log('-------> e is ' + e); thise=e;
        //console.log('-------> page is ' + page); thispage=page;
        $$(".page-current .page-content ul li").on("click", function () {
          console.log("this value is " + this.innerText);
          thisVal = this;
        });

        $$(".page-current").on("click", function () {
          console.log("clicked here");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageInit: function (e, page) {
        console.log("-------> on.pageInit EVENT called from router for LENGTH");
        $$(".page-current").on("click", function () {
          console.log("clicked here pageInit");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageAfterIn: function (e, page) {
        console.log(
          "-------> on.pageAfterIn EVENT called from router for LENGTH"
        );
        $$(".page-current").on("click", function () {
          console.log("clicked here afterIn");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });

        $$("#lengthList li a").on("click", function () {
          console.log("this length is clicked");
          let thisLengthIs = $$(this).text();
          console.log("pageAfterIn this length is " + thisLengthIs);
          app.data.lookup.skiLength = thisLengthIs;
          app.views.main.router.navigate("/lookup/year/");
        });
      },
    },
  },

  {
    path: "/lookup/year/",
    async(to, from, resolve, reject) {
      console.log("app.data.lookup.skiYear=" + app.data.lookup.skiYear);
      let data = {
        ski_attr: "years",
        brand: app.data.lookup.skiBrand,
        model: app.data.lookup.skiModel,
      };
      var hasYear; // to determine if the ski has a year value to show or not show year page
      app.request({
        url: app.data.wsURL + "ws_ski_lookup_ret_json.php",
        dataType: "json",
        data: data,
        method: "POST",
        crossDomain: true,
        statusCode: {
          404: function (xhr) {
            console.log("page not found");
          },
        },
        complete: function (xhr, status) {
          console.log(
            "================> >   > complete lookup for year request with xhr=" +
              xhr +
              " and status=" +
              status
          );
          if (!hasYear) {
            app.methods.routeSkiLookup();
          }
          //if (app.data.lookup.skiYear=='') {
          //  routeSkiLookup();
          //}
        },
        success: function (response) {
          console.log("success with response=" + response);
          console.log("success with response[0]=" + response[0]);
          console.log("response.length=" + response.length);
          console.log("response[0].length=" + response[0].length);

          if (response != "") {
            console.log("resolved this to year with data=" + response);
            hasYear = true;

            resolve(
              {
                //componentUrl: './pages/lookup/year_list.html',
                component: YearList,
              },
              {
                context: {
                  skiYear: response,
                },
              }
            );
          } else {
            hasYear = false;
            app.data.lookup.skiYear = "";
            reject();
          }
        },
        error: function () {
          console.log("error");
        },
      });
    },
    on: {
      pageMounted: function (e, page) {
        console.log(
          "-------> on.pageMounted EVENT called from router for YEAR"
        );
        //console.log('-------> e is ' + e); thise=e;
        //console.log('-------> page is ' + page); thispage=page;
        $$(".page-current .page-content ul li").on("click", function () {
          console.log("this value is " + this.innerText);
          thisVal = this;
        });

        $$(".page-current").on("click", function () {
          console.log("clicked here");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageInit: function (e, page) {
        console.log("-------> on.pageInit EVENT called from router for YEAR");
        $$(".page-current").on("click", function () {
          console.log("clicked here pageInit");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });
      },
      pageAfterIn: function (e, page) {
        console.log(
          "-------> on.pageAfterIn EVENT called from router for YEAR"
        );
        $$(".page-current").on("click", function () {
          console.log("clicked here afterIn");
        });

        $$(".page-current .page-content .list").on("click", function () {
          console.log("clicked here");
        });

        $$("#yearList li a").on("click", function () {
          console.log("this year is clicked");
          let thisYearIs = $$(this).text();
          console.log("pageAfterIn this year is " + thisYearIs);
          app.data.lookup.skiYear = thisYearIs;
          app.methods.routeSkiLookup();

          //verifySki();
        });
      },
      pageBeforeIn: function (e, page) {
        console.log(
          "*****************-------> on.pageBeforeIn EVENT called from router for YEAR"
        );
        if (app.data.lookup.skiYear == null) {
          console.log("its null ***************");
          //routeSkiLookup();
        } else {
          console.log("its not null ***************");
        }
      },
    },
  },

  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
