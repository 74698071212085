class User {
  constructor(id, user_name, user_email, last_login, user_vers, eula, data_priv, token, fcm_reg_id, fin_share,first_name,last_name,dob_year,capability_level,weight,height,ski_speed,measure_binding,measure_length,measure_depth,measure_dft,front_binding_type,rear_binding_type,foot_forward,measure_water_temp,profileActivated) {
    this.id = id;
    this.user_name = user_name;
    this.user_email = user_email;
    this.last_login = last_login;
    this.user_vers = user_vers;
    this.eula = parseInt(eula) || 0;
    this.data_priv = parseInt(data_priv) || 0;
    this.token = token;
    this.fcm_reg_id = fcm_reg_id;
    this.fin_share = parseInt(fin_share) || 0;

    this.first_name = first_name;
    this.last_name = last_name;
    this.dob_year = dob_year;
    this.capability_level = capability_level;
    this.weight = weight;
    this.height = height;
    this.ski_speed = ski_speed;
    this.measure_binding = measure_binding;
    this.measure_length = measure_length;
    this.measure_depth = measure_depth;
    this.measure_dft = measure_dft;
    this.front_binding_type = front_binding_type;
    this.rear_binding_type = rear_binding_type;
    this.foot_forward = foot_forward;
    this.measure_water_temp = measure_water_temp;
    this.profileActivated = profileActivated;
  }



  set setUser (obj) {
    console.log("in setUser");
    console.log(obj);
    if(obj.hasOwnProperty('id')) this.id = obj.id;
    if(obj.hasOwnProperty('user_name')) this.user_name = obj.user_name;
    if(obj.hasOwnProperty('user_email')) this.user_email = obj.user_email
    if(obj.hasOwnProperty('last_login')) this.last_login = obj.last_login;
    if(obj.hasOwnProperty('user_vers')) this.user_vers = obj.user_vers;
    if(obj.hasOwnProperty('eula')) this.eula = parseInt(obj.eula) || 0;
    if(obj.hasOwnProperty('data_priv')) this.data_priv = parseInt(obj.data_priv) || 0;
    if(obj.hasOwnProperty('fcm_reg_id')) this.fcm_reg_id = obj.fcm_reg_id;
    if(obj.hasOwnProperty('fin_share')) this.fin_share = parseInt(obj.fin_share) || 0;
    if(obj.hasOwnProperty('first_name')) this.first_name = obj.first_name;

    if(obj.hasOwnProperty('last_name')) this.last_name = obj.last_name;
    if(obj.hasOwnProperty('dob_year')) this.dob_year = obj.dob_year;
    if(obj.hasOwnProperty('capability_level')) this.capability_level = obj.capability_level;
    if(obj.hasOwnProperty('weight')) this.weight = obj.weight;
    if(obj.hasOwnProperty('height')) this.height = obj.height;
    if(obj.hasOwnProperty('ski_speed')) this.ski_speed = obj.ski_speed;
    if(obj.hasOwnProperty('measure_binding')) this.measure_binding = obj.measure_binding;
    if(obj.hasOwnProperty('measure_length')) this.measure_length = obj.measure_length;
    if(obj.hasOwnProperty('measure_depth')) this.measure_depth = obj.measure_depth;
    if(obj.hasOwnProperty('measure_dft')) this.measure_dft = obj.measure_dft;
    if(obj.hasOwnProperty('front_binding_type')) this.front_binding_type = obj.front_binding_type;
    if(obj.hasOwnProperty('rear_binding_type')) this.rear_binding_type = obj.rear_binding_type;
    if(obj.hasOwnProperty('foot_forward')) this.foot_forward = obj.foot_forward;
    if(obj.hasOwnProperty('measure_water_temp')) this.measure_water_temp = obj.measure_water_temp;
    if(obj.hasOwnProperty('profileActivated')) this.profileActivated = obj.profileActivated;
  }


  get getUser() {
    return this;
  }



  clearUser(obj) {
    console.log("START in clearUser.");
    for (let prop in obj) {
      delete obj[prop];
    }
  }

  /*
    *********
      Function validProfile checks to make sure the user has set up the required fields for saving fin settings.
      RETURNS: Boolean
    *********
  */
  validProfile(userObj) {
    var reqArray = ["measure_binding", "measure_length", "measure_depth", "measure_dft", "measure_water_temp"];
    var missing =[];
    reqArray.forEach(function(property) {
      if (!userObj[property]) {
        missing.push(property);
       }
    });
    if (missing.length>0) {
      return false;
    } else {
      return true;
    }
  }

} // END class User







import $$ from 'dom7';
import Interface from '../js_my/interface.js';
import Utility from '../js_my/utility.js';

var Users = {
  thisUser:{},

  // VALIDATE THE USER
  loginUser: function () {
  		console.log('submit login button clicked');
      if (!app.methods.onLine()) return app.methods.onOffline();

          var user_name=$$('.page #user_name').val();
          var pwd=$$('.page #pwd').val();
          var loginTime=Utility.getLocalDateTimeString(null,"ISO"); //SAVES TO SERVER

          $$(".page #loginError").html("");
          if (user_name=='' || pwd =='') {
            $$(".page #loginError").html("Please enter a User Name and Password.");
            return;
          }

          var url=app.data.wsURL+'ws_login_ret_json.php';
          console.log("url is " + url);
          var returnCode;

        	//$$.ajax({url:url,data:{
          app.request({
            url:url,
            data:{
              user_name:user_name,
              pwd:pwd,
              app_vers:app.data.app_vers,
              api_vers:app.data.api_vers,
              device_manufacturer:app.data.device.manufacturer,
              device_platform:app.data.device.platform,
              device_model:app.data.device.model,
              device_version:app.data.device.version,
              last_login:loginTime },
            type:'POST',dataType: 'json'
  				,success:function(json_Obj) {
  						console.log('ajax success function for loginUser.');
  						if (json_Obj.length>0) { // RETURNED RESULTS
                console.log('return code is ' + json_Obj[0].RETURN_CODE);

            		if (json_Obj[0].RETURN_CODE == 1) {
              		returnCode=1;

                  Users.thisUser = new User(json_Obj[0].id,json_Obj[0].user_name,json_Obj[0].user_email,json_Obj[0].last_login,json_Obj[0].user_vers,json_Obj[0].eula,json_Obj[0].data_priv,json_Obj[0].token,json_Obj[0].fcm_reg_id);
                  //console.log(Users.thisUser);

  							}
                else if (json_Obj[0].RETURN_CODE == -1 || json_Obj[0].RETURN_CODE == -2) {
                  returnCode=-1;
                  console.log('sql success, but no user found');
                } else {
                  returnCode=json_Obj[0].RETURN_CODE;
                }
  						} else { // NO JSON OBJECT RETURNED
                console.log('NO return code ');
              }
        		}, complete: function() {
                console.log('ajax complete.');

                if (returnCode==1) {
                  localStorage.setItem("user_name", user_name);
                  localStorage.setItem("pwd", pwd);
                  $$("#userNameInPanel").html(Users.thisUser.user_name);
                  $$("#userLastLogin").html( Utility.getLocalDateTimeString(Users.thisUser.last_login,"pretty")); // PRINTS TO SCREEN
                  Users.retrieveProfile(Users.thisUser.user_name,Users.thisUser.token);
                } else if (returnCode==-1 || returnCode==-2) {
                  $$(".page #loginError").html("Invalid Login.  Please try again.");
                  app.preloader.hide();
                } else if (returnCode==401) {
                  $$(".page #loginError").html("Token Authentication failed.  Please try again or contact paul@paulsantangelo.com.");
                  app.preloader.hide();
                } else {
                  $$(".page #loginError").html("Database error.  Please try again or contact paul@paulsantangelo.com.");
                  console.log('invalid credentials...thisUser deleted');
                  app.preloader.hide();
                }

        	  }, // end COMPLETE
  					timeout: 5000,
  					error: function(json_Obj, status, err) {
  						if (status == "timeout") {
  								console.log("Timeout Error. " + json_Obj + status + err);
  						} else {
  							console.log("non-timeout error: " + json_Obj + status + err);
  						}
            }, // end error
              beforeSend: function() {
                console.log('user_name='+user_name+ ' pwd='+pwd);
                $$(".page #loginError").html('');

                if (typeof app.notification.get() !== 'undefined') { app.notification.close(); }
                app.preloader.show('Validating User...');
  						} // END before Send
          }); // END AJAX REQUEST
  },







  // Perform logout activities
  logoutUser: function () {
    console.log('Logout button clicked');
    if (!app.methods.onLine()) return app.methods.onOffline();


        var url=app.data.wsURL+'ws_logout_ret_json.php';
        console.log("url is " + url);
        var returnCode;

        //$$.ajax({url:url,data:{
        app.request({
          url:url,
          data:{
            user_name:Interface.getUser().user_name,
            token:Interface.getUser().token
             },
          type:'POST',dataType: 'json'
        ,success:function(json_Obj) {
            console.log('ajax success function for logoutUser.');
            if (json_Obj.length>0) { // RETURNED RESULTS
              console.log('return code is ' + json_Obj[0].RETURN_CODE);

              if (json_Obj[0].RETURN_CODE == 1) {
                returnCode=1;


              } else {
                returnCode=json_Obj[0].RETURN_CODE;
              }
            }
          }, complete: function() {
              console.log('ajax complete.');


          }, // end COMPLETE
          timeout: 5000,
          error: function(json_Obj, status, err) {
            if (status == "timeout") {
                console.log("Timeout Error. " + json_Obj + status + err);
            } else {
              console.log("non-timeout error: " + json_Obj + status + err);
            }
          }, // end error
            beforeSend: function() {

            } // END before Send
        }); // END AJAX REQUEST


    // Users.thisUser.clearUser(Users.thisUser);
    // Skis.thisSki.clearSki(Skis.thisSki);
    // Settings.thisSetting.clearSettings(Settings.thisSetting);

    app.views.main.router.navigate('/');
  },


  // LOAD USERS PROFILE INTO USER object
  retrieveProfile: function (user_name,token) {
  				console.log('retrieveProfile triggered');
          if (!app.methods.onLine()) return app.methods.onOffline();

          var url=app.data.wsURL+'ws_get_user_profile_ret_json.php';
          var returnCode;

  				app.request({url:url,data:{ user_name:user_name, token:token },type:'POST',dataType: 'json'
          ,success:function(json_Obj) {
  						console.log('ajax success.');
  						if (json_Obj.length>0) { // RETURNED RESULTS
            		if (json_Obj[0].RETURN_CODE==1) {
              		returnCode=1;
                  Users.thisUser.setUser=json_Obj[0]; // set thisUser with profile properties
  							} else {
                  returnCode=json_Obj[0].RETURN_CODE;
                  Users.thisUser.profileActivated=0;
                }
  						}
        		}, complete: function() {
              if (returnCode == 1 ||  returnCode == -1) {
                Interface.route_retrieveProfile(returnCode);
              } else {
                app.methods.genericAlert("get_user_profile",returnCode);
              }
              app.preloader.hide();
        	  }, // end COMPLETE
  					timeout: 5000,
  					error: function(json_Obj, status, err) {
  						if (status == "timeout") {
  								console.log("Timeout Error. " + json_Obj + status + err);
  						} else {
  								console.log("error: " + json_Obj + status + err);
  						}
            }, // end error
              beforeSend: function(){
  							console.log('ajax beforeSend.')
  							} // END before Send
          });

  },








  /*
    setProfile function recieves data from the profile page
    This data updates the DB and the User object
    Function updates the user interface with corrected data
    Performs validation and error handling
  */
  setProfile: function (obj) { // NOTE: this obj is actually a FormData object...special.
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {// passed obj is empty, so set User to empty/logout
      Users.thisUser={};
      return null;
    }

    return new Promise((resolve, reject) => {
      var url=app.data.wsURL+'ws_set_user_profile_ret_json.php';
      var returnCode;
      var firstLogin=false;
      var passed=false;
      app.request({
        url:url,
        data:obj,
        type:'POST',
        dataType:'json',
        success:function(profilesObj) {
          if (profilesObj.length>0) { // RETURNED RESULTS
            returnCode=profilesObj[0].RETURN_CODE;
            if (profilesObj[0].RETURN_CODE==1) {
              console.log('successful profile update');
              passed=true;
                if (Users.thisUser.getUser.profileActivated == 0) {//first enry and successful profile update/min fields submitted
                firstLogin=true;
                Users.thisUser.setUser.profileActivated=1;
              }
            } else {
                if (profilesObj[0].RETURN_CODE==-2) { // required fields not met
                  app.alert(
                    'Required fields have not been populated.  Please complete the form.',
                    'Required Fields',
                    function () {
                      return null;
                    }
                  );
              }
              if (profilesObj[0].RETURN_CODE==1062) { // Duplicate email in system
                app.alert(
                  'You have entered an email that is already in the system.  Please enter another email address, or login with the account associated with this email Address.',
                  'Duplicate Email',
                  function () {
                    return null;
                  }
                );
            }
              else {
                console.log("profile set failed, not return 1 or -2");
                app.methods.genericAlert("interface_insertSki",returnCode);
              }
            }
            resolve(profilesObj)
          }
      }, timeout: 5000

        , beforeSend: function(){
          console.log('beforeSend profile update');
          app.preloader.show();

        }, complete: function(){
            console.log('complete profile update.  firstLogin is ' + firstLogin + ' and being updated with obj=');
            console.log(obj);
            if (passed) {
              Users.thisUser.setUser=obj;
            }
            if (firstLogin) {
              console.log('automatically sending user to mySettings page...');
              app.preloader.hide();
              return true;
            } else {
              app.preloader.hide();
              return false;
            }

        }, error: function(profilesObj, status, err) {
            if (status == "timeout") {
              console.log("Timeout Error. " + profilesObj + status + err);
            } else {
              console.log("error: "  + status + err);
            }
            reject(err);
        }
      }); // END ajax function
    }); // end promise
  },






  setUserItems: function(userObject) {
    if (Object.keys(userObject).length === 0 && userObject.constructor === Object) {// passed obj is empty, so set User to empty/logout
      return null;
    }

    Users.thisUser.setUser=userObject;


  },


}

export default Users;
