import $$ from 'dom7';
import Utility from '../js_my/utility.js';
import Users from '../js_my/user_class.js';
import Skis from '../js_my/ski_class.js';
import pushNotify from '../js_my/pushNotify.js';

var FirstEntry = {

  ftBinding:null,
  ftLength:null,
  ftDepth:null,
  ftDFT:null,
  ftFrontBinding:null,
  ftRearBinding:null,
  ftFootForward:null,
  measure_water_temp:null,
  accept_notifications:null,

  firstTimeEntry: function () {
    console.log("in firstTimeEntry");

    var modal = app.dialog.create({
      title: 'Successful Login!',
      text: 'Before you get started, let us know about your setup and measurement methods.'+
            '<div class="swiper-container" style="width: auto; margin:5px -15px -15px">'+
            '<div class="swiper-pagination"></div>'+
            '<div class="swiper-wrapper">'+
              '<div class="swiper-slide center"><img src="./static/img/firstTimeUser.jpg" style="display:block;margin: 0px auto;width:100%"></div>' +
            '</div>'+
            '</div>',
      buttons: [
        {
          text: 'Let\'s Go!',
          bold: true,
          onClick: function () {
            FirstEntry.firstTimeFrontBindingType();
          }
        },
      ]
    }).open();
  },






    firstTimeFrontBindingType: function () {
      if (typeof Users.thisUser !== 'undefined' && Users.thisUser.front_binding_type) {// BY PASS IF THIS IS ALREADY SET
        return FirstEntry.firstTimeRearBindingType();
      }


      var modalLength = app.dialog.create({
        title:  'Front Binding Type',
        text: 'What type of front binding do you currently use?',
        verticalButtons: true,
        buttons: [
          {
            text: 'Hard Shell',
            onClick: function() {
              FirstEntry.ftFrontBinding='Hard Shell';
              FirstEntry.firstTimeRearBindingType();
            }
          },
          {
            text: 'Firm Shell',
            onClick: function() {
              FirstEntry.ftFrontBinding='Firm Shell';
              FirstEntry.firstTimeRearBindingType();
            }
          },
          {
            text: 'Rubber',
            onClick: function() {
              FirstEntry.ftFrontBinding='Rubber';
              FirstEntry.firstTimeRearBindingType();
            }
          },
        ]
      }).open();
    },





    firstTimeRearBindingType: function () {
      if (typeof Users.thisUser !== 'undefined' && Users.thisUser.rear_binding_type) {// BY PASS IF THIS IS ALREADY SET
        return FirstEntry.firstTimeFootForward();
      }


      var modalLength = app.dialog.create({
        title:  'Rear Binding Type',
        text: 'What type of rear binding do you currently use?',
        verticalButtons: true,
        buttons: [
          {
            text: 'Kick In',
            onClick: function() {
              FirstEntry.ftRearBinding='Kick In';
              FirstEntry.firstTimeFootForward();
            }
          },
          {
            text: 'Half Shell',
            onClick: function() {
              FirstEntry.ftRearBinding='Half Shell';
              FirstEntry.firstTimeFootForward();
            }
          },
          {
            text: 'Firm Shell',
            onClick: function() {
              FirstEntry.ftRearBinding='Firm Shell';
              FirstEntry.firstTimeFootForward();
            }
          },
          {
            text: 'Hard Shell',
            onClick: function() {
              FirstEntry.ftRearBinding='Hard Shell';
              FirstEntry.firstTimeFootForward();
            }
          },
          {
            text: 'Rubber',
            onClick: function() {
              FirstEntry.ftRearBinding='Rubber';
              FirstEntry.firstTimeFootForward();
            }
          },
        ]
      }).open();
    },






    firstTimeFootForward: function () {
      if (typeof Users.thisUser !== 'undefined' && Users.thisUser.foot_forward) {// BY PASS IF THIS IS ALREADY SET
        return FirstEntry.firstTimeBinding();
      }


      var modalLength = app.dialog.create({
        title:  'Foot Forward',
        text: 'What foot forward do you ski?',
        verticalButtons: true,
        buttons: [
          {
            text: 'Right',
            onClick: function() {
              FirstEntry.ftFootForward='Right';
              FirstEntry.firstTimeBinding();
            }
          },
          {
            text: 'Left',
            onClick: function() {
              FirstEntry.ftFootForward='Left';
              FirstEntry.firstTimeBinding();
            }
          },
        ]
      }).open();
    },




  firstTimeBinding: function () {
    //var thisUser=Users.returnProfile();
    console.log("thisUser is ");
    console.log(Users.thisUser);

    if (typeof Users.thisUser !== 'undefined' && Users.thisUser.measure_binding) {// BY PASS IF THIS IS ALREADY SET
      return FirstEntry.firstTimeLength();
    }

    var modalBinding = app.dialog.create({
      title:  'Front Binding',
      text: 'How do you measure your front binding?',
      verticalButtons: true,
      buttons: [
        {
          text: 'Heel',
          onClick: function() {
            FirstEntry.ftBinding='Heel';
            FirstEntry.firstTimeLength();
          }
        },
        {
          text: 'Ankle',
          onClick: function() {
            FirstEntry.ftBinding='Ankle';
            FirstEntry.firstTimeLength();
          }
        },
      ]
    }).open();
  },





  firstTimeLength: function () {
    if (typeof Users.thisUser !== 'undefined' && Users.thisUser.measure_length) {// BY PASS IF THIS IS ALREADY SET
      return FirstEntry.firstTimeDepth();
    }


    var modalLength = app.dialog.create({
      title:  'Fin Length',
      text: 'How do you measure the LENGTH of your fin?',
      verticalButtons: true,
      buttons: [
        {
          text: 'Tips',
          onClick: function() {
            FirstEntry.ftLength='Tips';
            FirstEntry.firstTimeDepth();
          }
        },
        {
          text: 'Jaws',
          onClick: function() {
            FirstEntry.ftLength='Jaws';
            FirstEntry.firstTimeDepth();
          }
        },
        {
          text: 'Axiom Tool',
          onClick: function() {
            FirstEntry.ftLength='Axiom';
            FirstEntry.firstTimeDepth();
          }
        },
      ]
    }).open();
  },





  firstTimeDepth: function () {
    if (typeof Users.thisUser !== 'undefined' && Users.thisUser.measure_depth) { // BY PASS IF THIS IS ALREADY SET
      return FirstEntry.firstTimeDFT();
    }
    var modalDepth = app.dialog.create({
      title:  'Fin Depth',
      text: 'How do you measure the DEPTH of your fin?',
      verticalButtons: true,
      buttons: [
        {
          text: 'Flat',
          onClick: function() {
            FirstEntry.ftDepth='Flat';
            FirstEntry.firstTimeDFT();
          }
        },
        {
          text: 'Axiom Tool',
          onClick: function() {
            FirstEntry.ftDepth='Axiom';
            FirstEntry.firstTimeDFT();
          }
        },
      ]
    }).open();
  },






  firstTimeDFT: function () {
    if (typeof Users.thisUser !== 'undefined' && Users.thisUser.measure_dft) { // BY PASS IF THIS IS ALREADY SET
      return FirstEntry.firstTimeWaterTemp();
    }

    var modalDFT = app.dialog.create({
      title:  'Fin DFT',
      text: 'How do you measure the Depth From Tail (DFT) of your fin?',
      verticalButtons: true,
      buttons: [
        {
          text: 'Flat',
          onClick: function() {
            FirstEntry.ftDFT='Flat';
            FirstEntry.firstTimeWaterTemp();
          }
        },
        {
          text: 'Needle',
          onClick: function() {
            FirstEntry.ftDFT='Needle';
            FirstEntry.firstTimeWaterTemp();
          }
        },
        {
          text: 'Slot',
          onClick: function() {
            FirstEntry.ftDFT='Slot';
            FirstEntry.firstTimeWaterTemp();
          }
        },
        {
          text: 'Axiom Tool',
          onClick: function() {
            FirstEntry.ftDFT='Axiom';
            FirstEntry.firstTimeWaterTemp();
          }
        },
      ]
    }).open();
  },






  firstTimeWaterTemp: function () {
    if (typeof Users.thisUser !== 'undefined' && Users.thisUser.measure_water_temp) {
      return FirstEntry.firstTimeSummary(); // BY PASS IF THIS IS ALREADY SET
    }

    var modalWaterTemp = app.dialog.create({
      title:  'Water Temperature',
      text: 'What scale do you use for water temperature?',
      verticalButtons: true,
      buttons: [
        {
          text: 'Celcius',
          onClick: function() {
            FirstEntry.measure_water_temp='C';
            if (app.device.ios) {
              FirstEntry.iosAcceptNotifications();
            } else {
              FirstEntry.accept_notifications=1;
              FirstEntry.firstTimeSummary();
            }
          }
        },
        {
          text: 'Farenheit',
          onClick: function() {
            FirstEntry.measure_water_temp='F';
            if (app.device.ios) {
              FirstEntry.iosAcceptNotifications();
            } else {
              FirstEntry.accept_notifications=1;
              FirstEntry.firstTimeSummary();
              pushNotify.init();
            }

          }
        }
      ]
    }).open();
  },







  iosAcceptNotifications: function () {
    var modalNotifications = app.dialog.create({
      title:  'Push Notifications',
      text: 'Do you want to recieve app notifications/alerts?<p>This is useful when new skis are added to the database.  If you use the finShare feature, you will recieve notifications when someone wants to share fin settings with you.</p>',
      verticalButtons: false,
      buttons: [
        {
          text: 'No',
          onClick: function() {
            FirstEntry.accept_notifications=0;
            FirstEntry.firstTimeSummary();
          }
        },
        {
          text: 'Yes',
          onClick: function() {
            FirstEntry.accept_notifications=1;
            pushNotify.init();
            FirstEntry.firstTimeSummary();
          }
        }
      ]
    }).open();
  },




  firstTimeSummary: function () {
    var summaryStr='<div class="ftSummaryList">';
    if (typeof FirstEntry.ftFrontBinding!="undefined") {summaryStr+='<div>Front Binding Type: <span class="floatRight bold">'+ FirstEntry.ftFrontBinding +'</span></div>'};
    if (typeof FirstEntry.ftRearBinding!="undefined") {summaryStr+='<div>Rear Binding Type: <span class="floatRight bold">'+ FirstEntry.ftRearBinding +'</span></div>'};
    if (typeof FirstEntry.ftFootForward!="undefined") {summaryStr+='<div>Foot Forward: <span class="floatRight bold">'+ FirstEntry.ftFootForward +'</span></div>'};
    if (typeof FirstEntry.ftBinding!="undefined") {summaryStr+='<div>Front Binding: <span class="floatRight bold">'+ FirstEntry.ftBinding +'</span></div>'};
    if (typeof FirstEntry.ftLength!="undefined") {summaryStr+='<div>Fin Length: <span class="floatRight bold">'+ FirstEntry.ftLength +'</span></div>'};
    if (typeof FirstEntry.ftDepth!="undefined") {summaryStr+='<div>Fin Depth: <span class="floatRight bold">'+ FirstEntry.ftDepth +'</span></div>'};
    if (typeof FirstEntry.ftDFT!="undefined") {summaryStr+='<div>Fin DFT: <span class="floatRight bold">'+ FirstEntry.ftDFT +'</span></div>'};
    if (typeof FirstEntry.measure_water_temp!="undefined") {summaryStr+='<div>Water Temp: <span class="floatRight bold">'+ FirstEntry.measure_water_temp +'</span></div>'};
    summaryStr+='</div>';

    var modalSummary = app.dialog.create({
      title: 'Complete!',
      text: '<div>Below are the settings you\'ve chosen.  These can be changed at anytime in your profile.</div>' + summaryStr,

      buttons: [
        {
          text: 'Oops!',
          bold: true,
          onClick: function () {
            FirstEntry.firstTimeBinding();
          }
        },
        {
          text: 'Perfect!',
          bold: true,
          onClick: function () {
            FirstEntry.saveMeasuring();
          }
        },
      ]
    }).open();
  },






  saveMeasuring: function () {
    console.log('in saveMeasuring');

    if (!app.methods.onLine()) return app.methods.onOffline();

    console.log('saveMeasuring function fired with user_name ' + Users.thisUser.user_name);


    var url=app.data.wsURL+'ws_set_how_i_measure_ret_json.php';
    var passed;
    var returnCode;

    app.request({url:url,data:{ user_name:Users.thisUser.user_name,token:Users.thisUser.token,measure_binding:FirstEntry.ftBinding,measure_length:FirstEntry.ftLength,measure_depth:FirstEntry.ftDepth,measure_dft:FirstEntry.ftDFT,front_binding_type:FirstEntry.ftFrontBinding,rear_binding_type:FirstEntry.ftRearBinding,foot_forward:FirstEntry.ftFootForward,measure_water_temp:FirstEntry.measure_water_temp },type:'POST',dataType: 'json',success:function(json_Obj) {

        if (json_Obj.length>0) { // RETURNED RESULTS
          returnCode=json_Obj[0].RETURN_CODE;
          if (json_Obj[0].RETURN_CODE==1) {
            console.log('success with obj ' + json_Obj)
            Users.thisUser.profileActivated=true;
            passed=true;
          } else {
            console.log('json success, but not return code 1.');
            passed=false;
          }
        } else {
          console.log('json success, but no data/return code sent');
          passed=false;
        }
      }, timeout: 5000
      , beforeSend: function(){
        console.log('beforeSend saveMeasuring');
        app.preloader.show();
      }, complete: function(){
          console.log('complete saveMeasuring');
          if (passed) { // CHANGE THESE OBJECT PROPERTIES IF CHANGED DURING LOGIN SELF SELECTION
            if (FirstEntry.ftFrontBinding) Users.thisUser.front_binding_type=FirstEntry.ftFrontBinding;
            if (FirstEntry.ftRearBinding) Users.thisUser.rear_binding_type=FirstEntry.ftRearBinding;
            if (FirstEntry.ftFootForward) Users.thisUser.foot_forward=FirstEntry.ftFootForward;
            if (FirstEntry.ftBinding) Users.thisUser.measure_binding=FirstEntry.ftBinding;
            if (FirstEntry.ftLength) Users.thisUser.measure_length=FirstEntry.ftLength;
            if (FirstEntry.ftDepth) Users.thisUser.measure_depth=FirstEntry.ftDepth;
            if (FirstEntry.ftDFT) Users.thisUser.measure_dft=FirstEntry.ftDFT;
            if (FirstEntry.measure_water_temp) Users.thisUser.measure_water_temp=FirstEntry.measure_water_temp;
            app.preloader.hide();
            Skis.getCurrentSki({user_name:Users.thisUser.user_name, token:Users.thisUser.token, ski_id:null});
          } else {
            app.dialog.alert(
              'Data could not be saved due to an unknown error.  Please try again.',
              'Save Failed',
              function () {
                FirstEntry.firstTimeBinding();
              }
            );
          }

        if (returnCode!=1) {
          app.methods.genericAlert("set_how_i_measure",returnCode);
        }
      }, error: function(json_Obj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + json_Obj + status + err);
          } else {
            console.log("error: "  + status + err);
          }
      }
    }); // END ajax function for saveMeasuring
  },


}

export default FirstEntry;
