import $$ from 'dom7';
import Interface from '../js_my/interface.js';

var Profile = {


  init_profile:function () {
    console.log("init_profile executing");

    //get a fresh copy of the user profile
    //Interface.i_UserProfile();

    Profile.populateWeight();
    Profile.populateDOByears();
    Profile.populateProfile();

    $$("#editProfileBtn").text('Edit');
    //$$('#profilePage_div .item-input').children().prop("disabled", true);

    // FOR Framework7 V3, it has to be done this way
    //$$('#profilePage_div .item-input .item-inner .item-input-wrap .input-with-value').prop("disabled",true);
    $$('#profilePage_div .item-input .item-inner .item-input-wrap').children().prop("disabled",true);

    console.log('disabled=' + $$('#profilePage_div .item-input .item-inner .item-input-wrap').children().prop("disabled",true));

    if (! Interface.getUser().profileActivated) Profile.toggleEditSave(); // make fields editable upon first entry
  },


  populateProfile: function () {
    console.log("populateProfile with ");
    console.log(Interface.getUser());
    if (typeof Interface.getUser() !== "undefined") {
      // populate the fields with values
      $$(".page #first_name").val(Interface.getUser().first_name);
      $$(".page #last_name").val(Interface.getUser().last_name);
      $$(".page #user_email").val(Interface.getUser().user_email);
      $$(".page #weight").val(Interface.getUser().weight);
      $$(".page #height").val(Interface.getUser().height);
      $$(".page #dob_year").val(Interface.getUser().dob_year);
      $$(".page #capability_level").val(Interface.getUser().capability_level);
      $$(".page #ski_speed").val(Interface.getUser().ski_speed);
      $$(".page #measure_binding").val(Interface.getUser().measure_binding);
      $$(".page #measure_length").val(Interface.getUser().measure_length);
      $$(".page #measure_depth").val(Interface.getUser().measure_depth);
      $$(".page #measure_dft").val(Interface.getUser().measure_dft);
      $$(".page #front_binding_type").val(Interface.getUser().front_binding_type);
      $$(".page #rear_binding_type").val(Interface.getUser().rear_binding_type);
      $$(".page #foot_forward").val(Interface.getUser().foot_forward);
      $$(".page #measure_water_temp").val(Interface.getUser().measure_water_temp);
    }
  },








  populateWeight: function () {
    var weight='';
    weight += '<select name="weight" id="weight">';
    weight+="<option value=''>Select...</option>";
    for (let i=100;i<255; i+=5) {
      weight+="<option value='"+i+"'>"+i+" lbs</option>";
    }
    weight+='</select>';
    $$(".page #weightSelect").html(weight).trigger('create'); // APPLY TO PAGE
  },







  populateDOByears: function () {
    var currentYear = (new Date).getFullYear();
    var years='';
    years += '<select name="dob_year" id="dob_year">';
    years+="<option value=''>Select...</option>";
    for (let i=currentYear-99;i<currentYear-6; i++) {
      years+="<option value='"+i+"'>"+i+"</option>";
    }
    years+='</select>';
    $$(".page #dobYearSelect").html(years).trigger('create'); // APPLY TO PAGE
  },






  toggleEditSave: function () {
    console.log("toggleEditSave clicked");
    if ($$("#editProfileBtn").text() == "Edit") {
      $$("#editProfileBtn").text("Save");
  //    $$('#profilePage_div .content-block-title').hide();
      //$$('#profilePage_div .list-block ul').css('background-color','rgba(245,255,245,.5)');

      //$$('#profilePage_div .list ul').addClass('lightOrangeBG');
      $$('#profilePage_div .item-input .item-inner .item-input-wrap').addClass('lightOrangeBG');
      //$$('#profilePage_div .item-input').children().prop("disabled", false);
      $$('#profilePage_div .item-input .item-inner .item-input-wrap').children().prop("disabled",false);
    } else {
      console.log("in Save block");
      if (!app.methods.onLine()) return app.methods.onOffline();

          var errorCondition=false;
          $$("#profilePage_div .redText").html(''); // clear all error fields
          if ($$("#measure_binding, #measure_length, #measure_depth, measure_dft").val() == "") {
            console.log('Empty required values for measure');
            $$("#errorMeasure").html(" Required fields");
            errorCondition=true;
          }

          /* // REQUIRING FIRST NAME AND LAST NAME
          if ($$("#first_name, #last_name, #user_email").val() == "") {
            console.log('Empty required values for user');
            $$("#errorUser").html(" Required fields");
            errorCondition=true;
          }
          */

          if ( !Profile.validateEmail( $$("#user_email").val() ) ) {
            console.log('invalid email for user');
            $$("#errorUser").html(" Invalid email");
            errorCondition=true;
          }

          if (errorCondition) {
            console.log("do not save data");
            return null; // Don't save data
          }



      $$("#editProfileBtn").text("Edit");
      $$('#profilePage_div .content-block-title').show();
      //$$('#profilePage_div .list-block ul').css('background-color','rgba(255,255,255,1)');
      //$$('#profilePage_div .list ul').removeClass('lightOrangeBG');
      $$('#profilePage_div .item-input .item-inner .item-input-wrap').removeClass('lightOrangeBG');
      //$$('#profilePage_div .item-input').children().prop("disabled", true);
      $$('#profilePage_div .item-input .item-inner .item-input-wrap').children().prop("disabled",true);


      // ASSIGN SUCCESSFUL VALUES SENT TO SERVER TO OBJECTS
      var $$inputs = $$('#profilePage_div input, #profilePage_div select ,#profilePage_div textarea');
      var tempUser={user_name:Interface.getUser().user_name, token:Interface.getUser().token};

      console.log("token is ");
      console.log(Interface.getUser().token);

      $$inputs.each(function() { // ASSIGN data object all the values from the form
        tempUser[this.name]=$$(this).val();
      });

      Profile.saveProfileData(tempUser);

    }
  },

  saveProfileData: function(formData) {
    Interface.updateProfile(formData)
    .then((data) => {
      console.log("in the then for saveProfileData...")
      console.log(data)
    })
    .catch((error) => {
      console.log("in the error for saveProfileData")
      console.log(error)
    })
  },







  validateEmail: function (user_email) {
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_email)) {
      return true;
    } else {
      //alert("You have entered an invalid email address!")
      return false;
    }
  },





}

export default Profile;
