class Ski {
  constructor(ski_id, user_name, my_ski_name, stock_ski_id, brand, model, ski_length, year, stockArray, /*stock_binding_location, stock_fin_length, stock_fin_depth, stock_fin_dft, stock_wing_angle,*/ measure_binding, measure_length, measure_depth, measure_dft, ski_count) {
    this.id = ski_id; // this is the ski_id for thisSetting.ski_id
    this.user_name = user_name;
    this.my_ski_name = my_ski_name;
    this.stock_ski_id = stock_ski_id;
    this.brand = brand;
    this.model = model;
    this.length = ski_length;
    this.year = year;
    this.stockArray = stockArray;
    /*this.stock_binding_location = stock_binding_location;
    this.stock_fin_length = stock_fin_length;
    this.stock_fin_depth = stock_fin_depth;
    this.stock_fin_dft = stock_fin_dft;
    this.stock_wing_angle = stock_wing_angle;*/
    this.measure_binding = measure_binding;
    this.measure_length = measure_length;
    this.measure_depth = measure_depth;
    this.measure_dft = measure_dft;
    this.ski_count = ski_count;
  }

  get getSki() {
    return this;
  }


  clearSki(obj) {
    for (let prop in obj) {
      delete obj[prop];
    }
  }

} // END class Ski



import $$ from 'dom7';
import Settings from '../js_my/settings_class.js';
import Interface from '../js_my/interface.js';
//import Utility from '../js_my/utility.js';

var Skis = {

    thisSki:{},

  /*
    *********   Gets users currently selected ski and settings if available
    *********   Stores these settings in two objects, thisSki and thisSetting
    *********   Forwards user to the MySettings page component
  */
  getCurrentSki: function (obj) {
		console.log('getCurrentSki function');
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {// passed obj is empty, so set User to empty/logout
      Skis.thisSki={};
      return null;
    }

    if (!app.methods.onLine()) return app.methods.onOffline();

    var user_name=obj.user_name;
    var token=obj.token;
    var ski_id=obj.ski_id;

    var url=app.data.wsURL+'ws_get_stock_and_cur_settings_ret_json.php';
    var returnCode;

  	app.request({url:url,data:{ user_name:user_name, token:token, ski_id:ski_id },type:'POST',dataType: 'json'
		,success:function(json_Obj) {
				console.log('ajax success.');
        if (json_Obj.status=='success') { // RETURNED RESULTS
      		if (json_Obj.RETURN_CODE==1) {
        		returnCode=1;
						console.log('my_ski_name is ' + json_Obj.data[0].my_ski_name);
						console.log('ski_id is ' + json_Obj.data[0].ski_id);

            // CREATE THE SKI OBJECT
            Skis.thisSki = new Ski(json_Obj.data[0].ski_id, json_Obj.data[0].user_name, json_Obj.data[0].my_ski_name,json_Obj.data[0].stock_ski_id,json_Obj.data[0].brand, json_Obj.data[0].model, json_Obj.data[0].ski_length, json_Obj.data[0].year, json_Obj.stock_info, json_Obj.data[0].how_to_measure_binding, json_Obj.data[0].how_to_measure_length, json_Obj.data[0].how_to_measure_depth, json_Obj.data[0].how_to_measure_dft, json_Obj.data[0].ski_count);

            //window.thisSki=Skis.thisSki;
            // CREATE THE SETTINGS OBJECT IF ANY SETTINGS ARE CURRENTLY SAVED
            if (json_Obj.data[0].setting_id) {
              Settings.initSettings(json_Obj.data[0]);
              //console.log(">>>>>>>>>settings just created initially.....")
              //console.log(Settings.thisSetting.getSettings);
            } else {
              Settings.initSettings({});
            }
					} else {
            returnCode=json_Obj.RETURN_CODE;
            console.log("RETURN CODE IS " + returnCode);
            const thisSki = null;
          }
				} else if (json_Obj.status!='success') {
          returnCode=json_Obj.RETURN_CODE;
          console.log("RETURN CODE for status of not success is  " + returnCode);
        } else {  // NOTE, this is a different JSON ENVELOP...specifically for OAUTH errors
          console.log("RETURN CODE IS NOW " + returnCode);
          returnCode=json_Obj[0].RETURN_CODE;
        }
  		}, complete: function(){
          console.log('ajax complete for getCurrentSki.')
          if (typeof thisSki != "undefined") {
            app.emit('skiUsageCount', thisSki);
          }

          if (returnCode == 1) {
            app.views.main.router.navigate('/mySettings/');
            app.preloader.hide();
          } else if (returnCode == -1) { // NO SKIS IN USERS ACCOUNT
            app.views.main.router.navigate('/mySettings/?ski=0');
            app.preloader.hide();
          } else {
            app.methods.genericAlert("stock_and_current_settings",returnCode);
          }
          // if (returnCode!=1) {
          //   app.methods.genericAlert("stock_and_current_settings",returnCode);
          // }
  	  }, // end COMPLETE
			timeout: 5000,
			error: function(json_Obj, status, err) {
				if (status == "timeout") {
						console.log("Timeout Error. " + json_Obj + status + err);
				} else {
						console.log("error: " + json_Obj + status + err);
				}
      }, // end error
        beforeSend: function(){
					console.log('ajax beforeSend.')
					} // END before Send
    });
  },



  /*
    *******   Facade for getCurrentSki
  */

  setCurrentSki: function (obj) {
    Skis.getCurrentSki(obj);
  },



}

export default Skis;
