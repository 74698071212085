import $$ from 'dom7';
import Utility from '../js_my/utility.js';

var Register = {

  validateFields: function (user_name,user_email,user_pwd,user_pwd_confirm,t_and_c,data_priv) {
    var errMsg="";
    if (user_name.length<4) {
      console.log('invalid user_name');
      errMsg+="<li>User Name must be atleast 4 characters.</li>";
    }
    if (! Utility.validEmail(user_email)) {
      console.log('invalid user_email');
      errMsg+="<li>Email address is not valid.</li>";
    }
    if (user_pwd.length<6) {
      console.log('invalid pwd='+user_pwd);
      errMsg+="<li>Password must be atleast 6 characters.</li>";
    } else if (user_pwd != user_pwd_confirm) {
      errMsg+="<li>Password does not match Confirm Password.</li>";
    }
    if (t_and_c != 1) {
      console.log('invalid t_and_c');
      errMsg+="<li>Terms and Conditions must be accepted.</li>";
    }
    if (data_priv != 1) {
      console.log('invalid data privacy');
      errMsg+="<li>Data Privacy Policy must be accepted.</li>";
    }
    return errMsg;
  },


  // MOVED THIS TO A UTILITY.JS file
  // function validEmail(user_email) {
  //  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_email)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  registerUser: function () {
      console.log('running registerUser function');
      if (!app.methods.onLine()) return app.methods.onOffline();

      var user_name=$$(".page #user_name_reg").val().trim();
      var user_email=$$(".page #user_email").val().trim();
      var user_pwd=$$(".page #user_pwd").val().trim();
      var user_pwd_confirm=$$(".page #user_pwd_confirm").val().trim();
      //var t_and_c=$$('.page #t_and_c').val();
      //var dpp=$$('.page #dpp').val();
      var t_and_c = app.toggle.get('#tog_tc').checked ? 1 : 0;
      var data_priv = app.toggle.get('#tog_dpp').checked ? 1 : 0;

      var validationMsg=Register.validateFields(user_name,user_email,user_pwd,user_pwd_confirm,t_and_c,data_priv);
      if ( validationMsg!="") {
        validationMsg="<ul class='alignLeft'>"+validationMsg+"</ul>";
        console.log('Fields not validated');
        app.dialog.alert(
          validationMsg,
          'Field Validation Failed',
          function () {
            return null;
          }
        );
        return false;
      } else {

        app.dialog.confirm(
          user_email,
          'Confirm Email Address',
          function () {
            Register.submitNewUser(user_name,user_email,user_pwd,user_pwd_confirm,t_and_c,data_priv);
            return null;
          },
          function () {
            return false;
          }
        );
      }
  },








  submitNewUser: function (user_name,user_email,user_pwd,user_pwd_confirm,t_and_c,data_priv) {
        let dataObj={ user_name:user_name,
                      user_email:user_email,
                      user_pwd:user_pwd,
                      user_pwd_confirm:user_pwd_confirm,
                      t_and_c:t_and_c,
                      data_priv:data_priv,
                      app_vers:app.data.app_vers,
                      api_vers:app.data.api_vers
                    }
        var url=app.data.wsURL+'ws_add_register_user_ret_json.php';
        var returnCode;
        app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {

          returnCode=jsonObj[0].RETURN_CODE;
          //if (jsonObj[0].RETURN_CODE==1) {
          switch (returnCode) {

            case 1:
            console.log('return code 1...success')
            localStorage.setItem('activation_code', true);
            localStorage.setItem('pending_user_name', user_name);
            Register.showActivationPrompt(user_name);
            break;

            case -1: //not well formed email address
              app.dialog.alert(
                'The email address is not a valid format.',
                'Email Address',
                function () {
                  return null;
                }
              );
            break;

            case -2: //username not long enough
              app.dialog.alert(
                'The user name must be at least 4 characters in length.',
                'User Name',
                function () {
                  return null;
                }
              );
            break;

            case -3: // password mismatch
              app.dialog.alert(
                'The passwords you provided do not match and/or they must be at least 6 characters in length.',
                'Password Mismatch',
                function () {
                  return null;
                }
              );
            break;
          //} else if (jsonObj[0].RETURN_CODE==1062) { // user already in system

          case -4: // T&C not selected
            app.dialog.alert(
              'You much check the box to approve the terms and conditions.',
              'T&Cs Not Checked',
              function () {
                return null;
              }
            );
          break;

          case -5: // Data Privacy not selected
            app.dialog.alert(
              'You much check the box to approve the Data Privacy Policy.',
              'Data Privacy Policy Not Checked',
              function () {
                return null;
              }
            );
          break;

          case 1062:
            app.dialog.alert(
              'This user name or email is already registered.  Please try another user name and/or email address OR login with this user name.',
              'Already Registered',
              function () {
                return null;
              }
            );
            break;
          //  } else {
          default:
              console.log('return code NOT 1 or 1062...no luck')
              app.dialog.alert(
                'Please be sure all fields above are populated.  If so, and you are not successful registering, please contact the administrator at paul@paulsantangelo.com',
                'Unsuccessful Registration',
                function () {
                  return null;
                }
              );
              break;
            }
        }, timeout: 5000
          , beforeSend: function() {
            console.log('beforeSend registerUser');

            app.preloader.show();

          }, complete: function() {

              console.log('in complete registerUser');
              app.preloader.hide();
              // NOW REFRESH INTERFACE, but mySki list and mySettings page.


          }, error: function(jsonObj, status, err) {
              if (status == "timeout") {
                console.log("Timeout Error. " + jsonObj + status + err);
              } else {
                console.log("error: "  + status + err);
              }
          }
        }) // END ajax function for models
  },






  showActivationPrompt: function (user_name) {
    var pendingActivation=localStorage.getItem('activation_code');
    var pendingUserName=localStorage.getItem('pending_user_name');

    if (pendingActivation) {
      app.dialog.prompt('An email has been sent with an activation code. Please enter the activation code below.', 'Activation Code', function (value) {
          Register.validateActivationCode(pendingUserName,value);
      });
    }
  },






  validateActivationCode: function (pendingUserName,activation_code) {
      console.log('running validateActivationCode function');
      if (!app.methods.onLine()) return app.methods.onOffline();

      var url=app.data.wsURL+'ws_get_activation_code_ret_json.php';
      var passed;
      app.request({url:url,data:{ user_name:pendingUserName,activation_code:activation_code},type:'POST',dataType: 'json',success:function(jsonObj) {
        if (jsonObj[0].RETURN_CODE==1) {
          console.log('return code 1...success for activation!')
          localStorage.removeItem('activation_code');
          localStorage.removeItem('pending_user_name');
          passed=true;
        } else {
          console.log('return code NOT 1...no luck activating account')
          passed=false;
        }
      }, timeout: 5000
        , beforeSend: function() {
          console.log('beforeSend validateActivationCode');

          app.preloader.show();

        }, complete: function(jsonObj) {

            console.log('in complete validateActivationCode');
            app.preloader.hide();
            if (passed) {
              Register.passedValidation();
            } else {
              Register.activationFailedConfirmation(pendingUserName,activation_code);
            }
            // NOW REFRESH INTERFACE, but mySki list and mySettings page.


        }, error: function(jsonObj, status, err) {
            if (status == "timeout") {
              console.log("Timeout Error. " + jsonObj + status + err);
            } else {
              console.log("error: "  + status + err);
            }
        }
      }) // END ajax function for models
  },



  activationFailedConfirmation: function (pendingUserName,activation_code) {
    console.log('in activationFailedConfirmation function with user_name:'+ pendingUserName + ' and activation_code:' + activation_code);
    app.dialog.confirm("The activation code " + activation_code + " is not valid.  Press OK to try again or Cancel.",
    "Incorrect Activation Code",
    function () {
          console.log('in confirm OK');
          Register.showActivationPrompt(pendingUserName);
        },
    function () {
          app.dialog.alert(
            'You can try to activate the account at a later time from the start page.',
            'Cancel Activation',
            function () {
              //mainView.router.load( { url:'index.html' });
              app.views.main.router.navigate('/'); //V2
            }
          );
      }
    );
  },

  passedValidation: function () {
    app.dialog.alert(
      'Your account is activated.  Please login with your user name and password.',
      'Congratulations!',
      function () {
        //mainView.router.load( { url:'index.html' });
        app.views.main.router.navigate('/'); //V2
      }
    );
  },












}

export default Register;
