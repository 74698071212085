import $$ from 'dom7';

var pushNotify = {
	pushText : "this is pushnotify.js. ",
	registrationId: null,

	init: function () {
		if(typeof(cordova) == "object"){
      setTimeout(function(){ pushNotify.initPush(); }, 10);
		}
		// LISTENER FOR addRegistration button
		// $$("#addRegistration").click(function(){
		// 	console.log("add registration clicked");
		// 	pushNotify.addRegistration();
		// });
	},

	addRegistration: function(data) {
		if (data.registrationId) pushNotify.registrationId=data.registrationId;

		let data_obj = {registration_id:data.registrationId};
		// add app data
		data_obj = Object.assign(data_obj, pushNotify.getAppData());

		console.log("data_obj sent is >>>>>>>>>>> ");
		console.log(data_obj);

    var url=app.data.wsURL+'notifications/addRegistrationID.php';
		app.request.post(url, data_obj , function (data) {
			console.log('Load was performed from pushNotify with returned data = ');
			console.log(data);
		});
	},


	getAppData: function() {
		var obj = {}
		if (app.data.device.platform) Object.assign(obj, {platform:app.data.device.platform});
		if (app.data.device.manufacturer) Object.assign(obj, {manufacturer:app.data.device.manufacturer});
		if (app.data.device.model) Object.assign(obj, {model:app.data.device.model});
		if (app.data.device.version) Object.assign(obj, {version:app.data.device.version});
		if (app.data.app_vers) Object.assign(obj, {app_vers:app.data.app_vers});
		if (localStorage.getItem("user_name"))  Object.assign(obj, {user_name:localStorage.getItem("user_name")});

		return obj;
	},




	isPushEnabled: function() {
		return new Promise((resolve, reject) => {
			PushNotification.hasPermission(data => {
			 console.log("in push.on hasPermission");
			 console.log(data);
			 if (data.isEnabled) {
				 console.log("in pushNotify is enabled.");
				 resolve(data.isEnabled);
				 //return true;
			 } else {
				 console.log("in pushNotify is NOT enabled.");
				 reject(data.isEnabled);
			 }
			});
		});
	},






	initPush : function() {
		var push = PushNotification.init({
			android: {
				forceShow: "true",
				vibrate: "true",
				sound: "true"
			},
		    browser: {
						pushServiceURL: 'https://fcm.googleapis.com/fcm/send'
		    },
			ios: {
				alert: "true",
				badge: "true",
				sound: "true"
			},
			windows: {}
		});

			push.on('registration', (data) => { // This executes FCM FirebasePlugin to get registrationId AND registrationType from Google FCM if available and returns an object called data.  Prompts user to accept notifications if NO DATA is returned.
			  console.log("push.on registration");
				console.log(data);
				pushNotify.addRegistration(data);
				// BELOW is recommended approach to clear badge after user successfully registers FCM ID
				// clear on registration
				push.setApplicationIconBadgeNumber(function() {}, function() {}, 1);
				push.setApplicationIconBadgeNumber(function() {}, function() {}, 0);
			});





			push.on('notification', data => {
			  // do something with the push data
			  // then call finish to let the OS know we are done
				console.log("push.on notification with data...");
				console.log(data);
				// BELOW will trigger finShare request badge to update the count upon notification.
				app.emit('finShareReqCountChange', null);


			  push.finish(
			    () => {
			      console.log('processing of push data is finished');
						var notificationWithButton = app.notification.create({
								icon: '<img src="./static/img/icon-40.png">',
								title: data.title,
								//subtitle: 'message: '+data.count,
								text: data.message,
								closeOnClick: true,
								on: {
		    					close: function () {
										app.methods.receiveNotfication(data);
		    					},
		  					}
							});
							notificationWithButton.open();
			    },
			    () => {
			      console.log('something went wrong with push.finish for $pageID ='+data.additionalData.$pageID);
			    },
					data.additionalData.$pageID
				);

				// BELOW is recommended approach to clear badge
				// clear on notification
				push.setApplicationIconBadgeNumber(function() {}, function() {}, 1);
				push.setApplicationIconBadgeNumber(function() {}, function() {}, 0);

				// per research, this method/function does NOT work...see workaround in onRegister event
				// push.clearAllNotifications(
				//   () => {
				//     console.log('clearAllNotifications success');
				//   },
				//   () => {
				//     console.log('clearAllNotifications error');
				//   }
				// );
			});


			push.on('error', (e) => {
				//document.getElementById("pushNotifyDiv").append(' | pushNotify Error  |  ');
				// e.message
			  console.log("push.on error");
				console.log(e.message)

			});



// PS, I THINK THIS NEEDS TO GO INSIDE THE ON EVENTS...IE. push.on(...)
			push.getApplicationIconBadgeNumber(
				n => {
					console.log('getApplicationIconBadgeNumber success ', n);
				},
				() => {
					console.log('error');
				}
			);

	} // END init_push

}

export default pushNotify;
