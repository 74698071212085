import $$ from 'dom7';
import Skis from '../js_my/ski_class.js';
//import Settings from '../js_my/settings_class.js';
import Users from '../js_my/user_class.js';
import Interface from '../js_my/interface.js';
import Utility from '../js_my/utility.js';

var MySkis = {


  saveSki: function(skiDataObj) {
    return new Promise((resolve, reject) => {
        var url=app.data.wsURL+'ws_add_ski_ret_json.php';
        var returnCode;
 
        app.request({url:url,data:skiDataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
          if (jsonObj[0].RETURN_CODE==1) {
            console.log('return code 1...success');
            returnCode=1;
          } else {
            console.log('return code NOT 1...no luck');
            returnCode = jsonObj[0].RETURN_CODE ? jsonObj[0].RETURN_CODE : 0;
          }
        }, timeout: 5000
          , beforeSend: function() {
            console.log('beforeSend saveSki');
            app.preloader.show();
          }, complete: function() {
            console.log('in complete saveSki');
            app.preloader.hide();
            resolve(returnCode);
          }, error: function(jsonObj, status, err) {
              if (status == "timeout") {
                console.log("Timeout Error. " + jsonObj + status + err);
              } else {
                console.log("error: "  + status + err);
                console.log(jsonObj);
              }
            reject(err);
          }
        }) // END ajax function for models
      }); // end promise
  },





  /*
    *********   Gets all of users skis they have saved
    *********   Renders them on the MySkis component page
  */
  getMySkis: function (user_name) {
    if (!app.methods.onLine()) return app.methods.onOffline();

    console.log('getMySkis function fired with user_name ' + user_name);

    var url=app.data.wsURL+'ws_get_my_skis_ret_json.php';
    var returnCode;

    app.request({url:url,data:{ user_name:user_name, token:Interface.getUser().token },type:'POST',dataType: 'json',success:function(mySkisObj) {
      if (mySkisObj.length>0) { // RETURNED RESULTS
        returnCode = mySkisObj[0].RETURN_CODE
        console.log('returnCode is ' + returnCode);
        if (mySkisObj[0].RETURN_CODE==1) {
          //returnCode=1;
          console.log('success with return code 1');
          //var str = '<div class="list-block">';
            var str='<ul id="saved_ski_list">';
              // iterate here.
              //mySkisObj.forEach( function( index, value ) {
              for (let a=0;a<mySkisObj.length;a++) {
                //console.log('in each loop with current=' + mySkisObj[a].current);
                if (mySkisObj[a].current==1) {
                  str+='<li class="lightOrangeBG swipeout transitioning">';
                } else {
                  str+='<li class="swipeout transitioning">';
                }

                str+='<label class="item-radio"> '; // V2: <label class="item-radio item-content"> // V1: <label class="label-radio">
                str+='<div class="item-content swipeout-content">';

                str+='<input type="radio" name="my-radio" value="'+mySkisObj[a].id+'"';
                if (mySkisObj[a].current==1) {
                  str+=' checked="checked">';
                } else {
                  str+='>';
                }

                str+='<i class="icon icon-radio"></i>';

                str+='<div class="item-inner">';
                //str+='<div class="item-title-row">';

                var assigned_by,ski_name;

                if (mySkisObj[a].assigned==1) {
                  assigned_by=mySkisObj[a].assigned_by;
                  ski_name="<span class='bold lightBlueText'>" + mySkisObj[a].my_ski_name + " | " + assigned_by.toUpperCase() + " DEMO</span>";
                } else {
                  ski_name=mySkisObj[a].my_ski_name;
                }


                if (mySkisObj[a].current==1) {
                  str+='<div class="item-title checkedRadio" id="ski_name_'+mySkisObj[a].id+'"> '+ski_name+'</div>';
                } else {
                  str+='<div class="item-title" id="ski_name_'+mySkisObj[a].id+'"> '+ski_name+'</div>';
                }

                str+='<div class="item-after"><i class="icon f7-icons ">chevron_left</i><i class="chevronB icon f7-icons ">chevron_left</i><i class="chevronC icon f7-icons">chevron_left</i></div>';


                //str+='</div>'; // title row
                str+='</div>'; // inner

                str+='</div>'; //item-content
                str+='</label>';
                str+='<div class="swipeout-actions-right"><a href="#" id="ski_id_'+mySkisObj[a].id+'" class="renameSki swipeout-close">Rename</a></div>';


                str+='</li>';
        			//});
            } // end for loop
              str+='</ul>';
              //str+='</div>';
              //console.log ("str = " + str);

              $$(".page #ul_mySkis_list").append(str).trigger('create');
        }// else {
          //console.log('json success, but no stock ski data found.');
          //returnCode = mySkisObj[0].RETURN_CODE ? mySkisObj[0].RETURN_CODE : 0;
        //}
        console.log('json success, mySkisObj > 1.');
      }
      }, timeout: 5000
      , beforeSend: function(){
        console.log('beforeSend getMySkis');
        //app.showIndicator();
        app.preloader.show();

      }, complete: function(){
          console.log('complete getMySkis');
          if (returnCode != 1 && returnCode != -1) {
            app.methods.genericAlert("get_my_skis",returnCode);
          }
          app.preloader.hide();

      }, error: function(mySkisObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + mySkisObj + status + err);
          } else {
            console.log("ERROR!  status:"  + status + " err:" + err);
          }
      }
    }) // END ajax function for mySkis
  },










  /*
    *********   Allows user to change any ski name in their list of skis associated with their account
    *********   Renders the change on the MySki's component page
    *********   Saves the change to the DB
  */
  changeSkiName: function (ski_id) {
    console.log(Users.thisUser);
    var curName=$$("#ski_name_"+ski_id).text();
    //app.prompt('Current name is<div class="bold">'+curName+'</div>', 'Rename Your Ski',
    app.dialog.prompt('Current name is<div class="bold">'+curName+'</div>', 'Rename Your Ski',
      function (value) { // OK BUTTON FOR CHANGE NAME ON SWIPEOUT PROMPT

        var url=app.data.wsURL+'ws_set_rename_ski_ret_json.php';
        var returnCode;
        //user_name:Interface.getUser().user_name
        app.request({url:url,data:{ user_name:Users.thisUser.user_name, token:Users.thisUser.token, ski_id:ski_id,my_ski_name:value },type:'POST',dataType: 'json'
        ,success:function(json_Obj) {
            console.log('ajax success.');
            if (json_Obj.length>0) { // RETURNED RESULTS
              returnCode=json_Obj[0].RETURN_CODE;
              // if (json_Obj[0].RETURN_CODE==1) {
              //   console.log('my_ski_name is ' + value);
              //   console.log('ski_id is ' + ski_id);
              // } else {
              //   returnCode=json_Obj[0].RETURN_CODE;
              //   console.log('return code is NOT 1');
              // }
            }
          }, complete: function() {
              console.log('ajax complete for rename Ski.')
              if (returnCode==1) {
                // close the swiper
                console.log('in complete with EL of #ski_name_' +ski_id+ ' with value of ' + value);
                $$("#ski_name_"+ski_id).text(value);
                //if (app.data.thisSki.id==ski_id) app.data.thisSki.my_ski_name=value;
                if (Skis.thisSki.id==ski_id) Skis.thisSki.my_ski_name=value;
              } else {
                app.methods.genericAlert("interface_insertSki",returnCode);
              }
              $$("#ski_id_"+ski_id).addClass('swipeout-close');
          }, // end COMPLETE
          timeout: 5000,
          error: function(json_Obj, status, err) {
            if (status == "timeout") {
                console.log("Timeout Error. " + json_Obj + status + err);
            } else {
                console.log("error: " + json_Obj + status + err);
            }
          }, // end error
            beforeSend: function(){
              console.log('ajax beforeSend.')
              } // END before Send
        });



      },
      function (value) { // CANCEL BUTTON ON SWIPEOUT PROMPT
        return null;
      }
    );

  }

}


export default MySkis;
