import $$ from 'dom7';
//import Skis from '../js_my/ski_class.js';
//import Settings from '../js_my/settings_class.js';
import Users from '../js_my/user_class.js';
import Interface from '../js_my/interface.js';
import Utility from '../js_my/utility.js';
import pushNotify from '../js_my/pushNotify.js';


var FinShare = {
  notEligibleMessage:null,
  finShareStatusMessage:["finShare is <span class='bold red'>DISABLED.</span>","finShare is <span class='bold green'>ENABLED.</span>"],
  sameBrandModelLength:false,
  fsUserObj:{}, // populated with each finshare users data for ski, settings, user profile, etc.

  init: function() {
    app.emit('finShareReqCountChange', null);
    FinShare.notEligibleMessage=null;
      let eligible=FinShare.isProfileEligible();
      if (!eligible) {
        return false;
      } else { // IF ELIGIBLE, check for notification status.
        if (app.data.isCordova) {
          console.log("user is finShare eligible...now check for push permissions");
          pushNotify.isPushEnabled()
              .then(data => { // returns true
                  console.log("in then...fin_share.js push is enabled " + data);
                  // do nothing here since user already has push enabled
              })
              .catch(error => { // returns false.  Notification are not enabled.  We now have to check to see if they have ever accepted notifications...the only way to do that is look at the User object for their registration id...
                console.log ("in catch...fin_share.js push is NOT enabled " + error);
                if (!Interface.getUser().fcm_reg_id) {
                  FinShare.renderIOSNotificationModal();
                }
              });
        }
      }
  },







  renderIOSNotificationModal: function() {
    if (app.device.ios && app.data.iosPromptCount<1) {
      app.data.iosPromptCount++;
      var modalNotifications = app.dialog.create({
        title:  'Push Notifications',
        text: 'Do you want to recieve app notifications/alerts?<p>This is useful when new skis are added to the database.  If you use the finShare feature, you will recieve notifications when someone wants to share fin settings with you.</p>',
        verticalButtons: false,
        buttons: [
          {
            text: 'No',
            onClick: function() {
              return null;
            }
          },
          {
            text: 'Yes',
            onClick: function() {
              pushNotify.init();
            }
          }
        ]
      }).open();
    }
  },






 isProfileEligible: function() {
   var thisUser=Interface.getUser();
   var returnString='';

   if (!thisUser.first_name || thisUser.first_name.length<1) returnString+="First Name<br />";
   if (!thisUser.last_name || thisUser.last_name.length<1) returnString+="Last Name<br />";
   if (!thisUser.front_binding_type || thisUser.front_binding_type.length<1) returnString+="Front Binding Type<br />";
   if (!thisUser.rear_binding_type || thisUser.rear_binding_type.length<1) returnString+="Rear Binding Type<br />";
   if (!thisUser.foot_forward || thisUser.foot_forward.length<1) returnString+="Foot Forward<br />";

   if (!thisUser.measure_binding || thisUser.measure_binding.length<1) returnString+="Binding Measurement<br />";
   if (!thisUser.measure_length || thisUser.measure_length.length<1) returnString+="Fin Length Measurement<br />";
   if (!thisUser.measure_depth || thisUser.measure_depth.length<1) returnString+="Fin Depth Measurement<br />";
   if (!thisUser.measure_dft || thisUser.measure_dft.length<1) returnString+="Fin DFT Measurement<br />";
   if (!thisUser.measure_water_temp || thisUser.measure_water_temp.length<1) returnString+="Water Temperature Units<br />";

   if (!thisUser.capability_level || thisUser.capability_level.length<1) returnString+="Capability Level<br />";
   if (!thisUser.dob_year || thisUser.dob_year.length<1) returnString+="Birth Year<br />";
   if (!thisUser.height || thisUser.height.length<1) returnString+="Height<br />";
   if (!thisUser.weight || thisUser.weight.length<1) returnString+="Weight<br />";
   if (!thisUser.ski_speed || thisUser.ski_speed.length<1) returnString+="Ski Speed<br />";

   if (typeof returnString != 'undefined' && returnString.length > 0) {
     console.log("returnString is " + returnString);
     FinShare.notEligibleMessage=returnString;
     return false;
   } else {
     return true;
   }
 },




  renderIncompleteProfileModal: function() {
    console.log("IN renderIncompleteProfileModal...not eligible");
    var modal = app.dialog.create({
      title: 'Profile Not Complete',
      text: 'To be eligible to participate in finShare, you must have a completed profile.<p class="bold">Data needed to complete:<p>' + FinShare.notEligibleMessage,
      buttons: [
        {
          text: 'Cancel',
          bold: false,
          onClick: function () {
            app.views.main.router.navigate('/mySettings/');
          }
        },
        {
          text: 'Go To Profile',
          bold: true,
          onClick: function () {
            app.views.main.router.navigate('/profile/');
          }
        },
      ]
    }).open();
  },



 toggleFinShare: function() {
   console.log("in toggleFinShare function");

   var toggle = app.toggle.get('#finShareToggle');
   var fin_share = toggle.checked ? 1 : 0;

  var dataObj = { user_name:Interface.getUser().user_name, fin_share:fin_share }
  var url=app.data.wsURL+'ws_set_finshare_flag_ret_json.php';
  var returnCode;

  app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
    if (jsonObj[0].RETURN_CODE==1) {
      console.log('return code 1...success again!');
      returnCode=1;
    } else {
      returnCode = jsonObj[0].RETURN_CODE ? jsonObj[0].RETURN_CODE : 0;
    }
  }, timeout: 5000
    , beforeSend: function() {
      //console.log('beforeSend toggleFinShare');
      app.preloader.show();
    }, complete: function() {
      //console.log('in complete toggleFinShare');
      app.emit('finShareChange', fin_share);
      app.preloader.hide();

      console.log("in complete with ... ");
      console.log(Interface.getUser().fcm_reg_id);
      console.log(returnCode);
      console.log(fin_share);
      // PROMPT FOR NOTIFICATION IF ...
      if (returnCode == 1 && fin_share == 1) {
        if (!Interface.getUser().fcm_reg_id) {
          FinShare.renderIOSNotificationModal();
        }
      }
    }, error: function(jsonObj, status, err) {
        if (status == "timeout") {
          console.log("Timeout Error. " + jsonObj + status + err);
        } else {
          console.log("error: "  + status + err);
          console.log(jsonObj);
        }
    }
  }) // END ajax function for models
},






  getFinShareList: function() {
    //ws_get_finshare_list_ret_json.php
    var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token }
    var url=app.data.wsURL+'ws_get_finshare_list_ret_json.php';
    var returnCode;

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE==1) {
        console.log('return code 1...success');
        FinShare.renderFinShareList(jsonObj);
        returnCode=1;
      } else {
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
        //let msg="<div class='margin-left_1em margin-right_1em'>You don't have a finShare group established yet.  Start by requesting other finShare users permission to view their setup information.<p>If you've already requested a finShare, the user has not yet approved the request.</div>";
        let msg=`<div class='block'>
                <div class='center'>
                  <div class="font_2em bold">Start a finShare!</div>
                  <div><a href="/finShareAdd/"><i class="icon f7-icons icon_addFinshare font_5em">person_crop_circle_badge_plus</i></a></div>
                  <hr />
                </div>

                  <div class='bold'>What is a finShare Group?</div>
                  <div>finShare allows you to view other skiers setup information.</div>
                  <ul>
                    <li><a href="/finShareAdd/">Search</a> for a finShare user</li>
                    <li>A request is sent to them for approval</li>
                    <li>After approval, their name will show up on this screen</li>
                    <li>Click their name to view their setup</li>
                  </ul>
                  <div>If you've already requested a finShare, and the users name is not on this screen, they have not yet approved the request.</div>
                </div>
                  `;
        $$('#myFinShareList').html(msg);
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');
        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function for models

  },





  renderFinShareList: function(obj) {
    var data=obj.data;
    var str=`<div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th class="label-cell">Name</th>
                    <th class="label-cell">Ski</th>
                    <th class="label-cell">Last Update</th>
                  </tr>
                </thead>
                <tbody>`;
    console.log("in renderFinsShareList");
    console.log(data);

    data.forEach(function(item){
      let LenToInches = +item.length.toString();
      str += `<tr class='finShareRow' data-fin-share-user=${item.finshare_user_name}>
                <td>${item.first_name} ${item.last_name}</td>
                <td>${item.brand} ${item.model} ${LenToInches}" ${item.year} </td>
                <td class='nowrap'>${item.date_time_created}<i class="icon f7-icons color-blue">chevron_right</i></td>
               </tr>`;

    });

    str +=`</tbody>
        </table>
      </div>`;
    $$('#myFinShareList').html(str);
  },





  getFinShareUserData: function(finshare_user_name) {
    console.log('in getFinShareUser data with user ' + finshare_user_name);
    // var finshare_user_name=id.split('_')[1];
    //console.log(finshare_user_name);

    var dataObj = { user_name:Interface.getUser().user_name, finshare_user_name:finshare_user_name, token:Interface.getUser().token }
    var url=app.data.wsURL+'ws_get_finshare_details_ret_json.php';
    var returnCode;

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE==1) {
        console.log('return code 1...success');
        console.log(jsonObj);
        FinShare.fsUserObj=jsonObj;
        FinShare.renderFinShareUserData(jsonObj);
        returnCode=1;
      } else {
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');

        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function for models


  },




  renderFinShareUserData: function(obj) {
    var userData = obj.user_profile[0];
    var setupData = obj.finAndSki.data[0];
    var stockData = obj.finAndSki.stock_info.data[0];
    var notes = obj.notes;

    if ( (setupData.model == Interface.getSki().model) && (setupData.brand == Interface.getSki().brand) && (setupData.ski_length == Interface.getSki().length)) {
      FinShare.sameBrandModelLength=true;
    } else {
      FinShare.sameBrandModelLength=false;
    }

    let height = userData.height ? Utility.inchesToFeet(userData.height) : '';
    let weight = userData.weight ? userData.weight+' lbs' : '';

    let skiLength = +setupData.ski_length.toString() + '"';
    let skiString=setupData.year+' '+setupData.brand+' '+setupData.model+' '+ skiLength;
    // SEE IF finShare user is on same ski as user
    // if ( FinShare.sameBrandModelLength ) {
    //   skiString += " <a href='#' id='fs_compare_link'>Compare to my ski.</a>";
    // }
    if ( FinShare.sameBrandModelLength ) {
      //skiString += " <span class='badge color-red same-ski super-script'>!</span>";
      skiString += " <a href='#'><i class='icon f7-icons font_125em same-ski super-script'>info_circle_fill</i></a>";
    }

    // Add attribute to navbar right link to allow removal of finShare by user_name
    $$("#fs_removeFinshare, #fs_prev_settings").attr('data-fin-share-user',setupData.user_name);
    $$("#fs_removeFinshare").attr('data-fin-share-first-name',userData.first_name);
    $$("#fs_removeFinshare").attr('data-fin-share-last-name',userData.last_name);
    $$("#fs_prev_settings").attr('data-fin-share-ski-id',setupData.ski_id);

    let dateLastModified = (setupData.setting_id) ? 'Last modified: '+setupData.date_time_created : '';

    let fsUL=`<div>
                <div class="fs_name bold">${userData.first_name} ${userData.last_name}</div>
                <div class="text_8em">( ${height} / ${weight} )</div>
                <div class="bold">${skiString}</div>
                <span class="bold">Bindings:</span>
                <ul class="fs_binding">
                  <li>Front ${userData.front_binding_type} </li>
                  <li>Rear ${userData.rear_binding_type} </li>
                  <li>${userData.foot_forward} Foot Forward </li>
                </ul>
                <div>${dateLastModified}</div>
              </div>`;
    $$("#fsUpperLeft").html(fsUL);

    //let ability=Utility.fsSkierAbilityChart(userData.capability_level);
    let abilityObj=Utility.fsSkierAbilityChart(userData.capability_level);
    let speedObj=Utility.speedConverter({toUnit:'kph', speed:userData.ski_speed});
    let fsUR=`<div>
                <div class="boxHeader">Level</div>
                <div class="boxContent">${abilityObj.html}</div>
                <div class="boxHeader">Ski Speed:</div>
                <div class="bold font_125em boxContent">${userData.ski_speed} / ${speedObj.speed}</div>

              </div>`;
    $$("#fsUpperRight").html(fsUR);
    $$("#fs_"+abilityObj.ability).addClass("bold font_125em italics blackText");

    if (setupData.setting_id) {
      $$("#fs_currentBinding").html(setupData.front_binding);
      $$("#fs_measureBinding").html(setupData.measure_binding);
      $$("#fs_currentLength").html(setupData.length);
      $$("#fs_measureLength").html(setupData.measure_length);
      $$("#fs_currentDepth").html(setupData.depth);
      $$("#fs_measureDepth").html(setupData.measure_depth);
      $$("#fs_currentDFT").html(setupData.dft);
      $$("#fs_measureDFT").html(setupData.measure_dft);
      $$("#fs_currentWingAngle").html(setupData.wing_angle);

      $$("#fs_LE").html("LE: " + setupData.leading_edge);
      $$("#fs_BLE").html("B-LE: " + setupData.binding_to_le);

      $$("#fs_settingsList, #fs_leadingEdgeDiv, #fs_settings_hdr").show();
    } else {
      $$("#fs_settingsList").html("<span class='block'>This user does not have any settings saved.</span>");
      $$("#fs_settingsList").show();
    }

    if (notes.RETURN_CODE == 1) {
      var noteStr='';
      for (let a=0;a<notes.data.length;a++) {
        noteStr+="<div class='historyNotesHdr'>"+Utility.getLocalDateTimeString(notes.data[a].date_time,'pretty'); //PRINTS TO SCREEN
        if (notes.data[a].water_temp>0){
          noteStr+="<span class='floatRight'>Water: "+notes.data[a].water_temp+"&deg; "+notes.data[a].water_temp_scale+"</span>";
        }
        noteStr+="</div>";
        noteStr+="<div class='historyNotes'>"+notes.data[a].notes+"</div>";
      }
      $$("#fs_SettingNotes").html(noteStr);
    }

    // SEE IF finShare user is on same ski as user
    if ( FinShare.sameBrandModelLength ) {
      $$("#fs_settings_hdr .segmented").append('<button id="fs_compare_link" class="button">Compare</button>');
    }
  },






  stockSettings: function() {
    console.log("in Finshare.stockSettings");
    //let setupData = FinShare.fsUserObj.finAndSki.data[0];
    //let stockInfo = FinShare.fsUserObj.finAndSki.stock_info.data[0];
    let stockInfo = FinShare.fsUserObj.finAndSki.stock_info.data;
    //console.log(stockInfo);

    if (stockInfo.length>1) {
      let buttons=[];
      stockInfo.forEach(function(item){
        console.log(item.setting_name);
        buttons.push({text:item.setting_name});
      });
      console.log(buttons);
      let btn=[{text:"one"},{text:"two"}];
      app.dialog.create({
        title: 'Please Select...',
        text: 'Multiple stock settings are available for this ski.',
        buttons: buttons,
        verticalButtons: true,
        onClick: function(dialog, index) {
          console.log("item clicked with index=",index);
          //console.log(dialog);
          FinShare.renderStockSettings(index);
        }
      }).open();

    } else {
      FinShare.renderStockSettings();
    }
  },







  renderStockSettings: function(index=0) {
    console.log("in Finshare.renderStockSettings");
    let setupData = FinShare.fsUserObj.finAndSki.data[0];
    let stockInfo = FinShare.fsUserObj.finAndSki.stock_info.data[index];
    console.log(stockInfo);


    // ERASE ANY EXISTING DATA IN EACH ROW
    $$(".fs_currentUser div").remove();

    let stockBinding=stockInfo.stock_binding_location;
    let stockBindingDiff = parseFloat((stockBinding-setupData.front_binding).toFixed(3));
    let stockBindingM=setupData.how_to_measure_binding;
    let stockLength=stockInfo.stock_fin_length;
    let stockLengthDiff = parseFloat((stockLength-setupData.length).toFixed(3));
    let stockLengthM=setupData.how_to_measure_length;
    let stockDepth=stockInfo.stock_fin_depth;
    let stockDepthDiff = parseFloat((stockDepth-setupData.depth).toFixed(3));
    let stockDepthM=setupData.how_to_measure_depth;
    let stockDFT=stockInfo.stock_fin_dft;
    let stockDftDiff = parseFloat((stockDFT-setupData.dft).toFixed(3));
    let stockDFTM=setupData.how_to_measure_dft;
    let stockWingAngle=stockInfo.stock_wing_angle;
    let stockWaDiff = parseFloat((stockWingAngle-setupData.wing_angle).toFixed(3));

    $$("#fs_currentBinding").append("<div class='fs_mysettings'>"+stockBinding+" ( "+stockBindingDiff+" )</div>");
    $$("#fs_measureBinding").append("<div class='fs_mysettings'>"+stockBindingM+"</div>");
    $$("#fs_currentLength").append("<div class='fs_mysettings'>"+stockLength+" ( "+stockLengthDiff+" )</div>");
    $$("#fs_measureLength").append("<div class='fs_mysettings'>"+stockLengthM+"</div>");
    $$("#fs_currentDepth").append("<div class='fs_mysettings'>"+stockDepth+" ( "+stockDepthDiff+" )</div>");
    $$("#fs_measureDepth").append("<div class='fs_mysettings'>"+stockDepthM+"</div>");
    $$("#fs_currentDFT").append("<div class='fs_mysettings'>"+stockDFT+" ( "+stockDftDiff+" )</div>");
    $$("#fs_measureDFT").append("<div class='fs_mysettings'>"+stockDFTM+"</div>");
    $$("#fs_currentWingAngle").append("<div class='fs_mysettings'>"+stockWingAngle+" ( "+stockWaDiff+" )</div>");

    let stockLE=parseFloat((parseFloat(stockInfo.stock_fin_dft) + parseFloat(stockInfo.stock_fin_length)).toFixed(3));
    let stockLeDiff=(((stockLE - parseFloat(setupData.leading_edge).toFixed(4)).toFixed(4))).replace(/^(-?)0+|0+$/g, "$1");
    let stockBLE=parseFloat((parseFloat(stockInfo.stock_binding_location)-stockLE).toFixed(3));
    let stockBleDiff=(((stockBLE-parseFloat(setupData.binding_to_le).toFixed(4)).toFixed(4))).replace(/^(-?)0+|0+$/g, "$1");
    let stockLeStr=stockLE+" ("+stockLeDiff+")";
    let stockBleStr=stockBLE+" ("+stockBleDiff+")";
    let stockLeInfo=`
              <div class="chip">
                <div class="chip-label font_125em fs_mysettings" id="fs_stockLE">${stockLeStr}</div>
              </div>
              <div class="chip">
                <div class="chip-label font_125em fs_mysettings" id="fs_stockBLE">${stockBleStr}</div>
              </div>
              `;

    $$("#fs_leadingEdgeDiv .fs_currentUser").append(stockLeInfo);


    FinShare.renderSurfaceAreaText(false,setupData.length, setupData.depth, stockLength, stockDepth);


    $$("#fs_settingsAdjuster").css("font-size", ".6em");
  },







  compareSettings: function() {
    console.log("compareSetting obj");
    var setupData = FinShare.fsUserObj.finAndSki.data[0];

    let myBinding=Interface.getSettings().front_binding;
    let bindingDiff = parseFloat((myBinding-setupData.front_binding).toFixed(3));
    let myBindingM=Interface.getSettings().measure_binding;
    let myLength=Interface.getSettings().length;
    let lengthDiff = parseFloat((myLength-setupData.length).toFixed(3));
    let myLengthM=Interface.getSettings().measure_length;
    let myDepth=Interface.getSettings().depth;
    let depthDiff = parseFloat((myDepth-setupData.depth).toFixed(3));
    let myDepthM=Interface.getSettings().measure_depth;
    let myDFT=Interface.getSettings().dft;
    let dftDiff = parseFloat((myDFT-setupData.dft).toFixed(3));
    let myDFTM=Interface.getSettings().measure_dft;
    let myWingAngle=Interface.getSettings().wing_angle;
    let waDiff = parseFloat((myWingAngle-setupData.wing_angle).toFixed(3));

    $$(".fs_currentUser div").remove();

    $$("#fs_currentBinding").append("<div class='fs_mysettings'>"+myBinding+" ( "+bindingDiff+" )</div>");
    $$("#fs_measureBinding").append("<div class='fs_mysettings'>"+myBindingM+"</div>");
    $$("#fs_currentLength").append("<div class='fs_mysettings'>"+myLength+" ( "+lengthDiff+" )</div>");
    $$("#fs_measureLength").append("<div class='fs_mysettings'>"+myLengthM+"</div>");
    $$("#fs_currentDepth").append("<div class='fs_mysettings'>"+myDepth+" ( "+depthDiff+" )</div>");
    $$("#fs_measureDepth").append("<div class='fs_mysettings'>"+myDepthM+"</div>");
    $$("#fs_currentDFT").append("<div class='fs_mysettings'>"+myDFT+" ( "+dftDiff+" )</div>");
    $$("#fs_measureDFT").append("<div class='fs_mysettings'>"+myDFTM+"</div>");
    $$("#fs_currentWingAngle").append("<div class='fs_mysettings'>"+myWingAngle+" ( "+waDiff+" )</div>");


    let myLE=Interface.getSettings().leading_edge.replace(/^(-?)0+|0+$/g, "$1");
    let leDiff=(((myLE - setupData.leading_edge).toFixed(4))).replace(/^(-?)0+|0+$/g, "$1");
    let myBLE=Interface.getSettings().binding_to_le.replace(/^(-?)0+|0+$/g, "$1");
    let bleDiff=(((myBLE-setupData.binding_to_le).toFixed(4))).replace(/^(-?)0+|0+$/g, "$1");
    let leStr=myLE+" ("+leDiff+")";
    let bleStr=myBLE+" ("+bleDiff+")";
    let leInfo=`
              <div class="chip">
                <div class="chip-label font_125em fs_mysettings">${leStr}</div>
              </div>
              <div class="chip">
                <div class="chip-label font_125em fs_mysettings">${bleStr}</div>
              </div>
              `;

    $$("#fs_leadingEdgeDiv .fs_currentUser").append(leInfo);



    FinShare.renderSurfaceAreaText(true,setupData.length, setupData.depth, myLength, myDepth);


    $$("#fs_settingsAdjuster").css("font-size", ".6em");
  },






  renderSurfaceAreaText:function (compare,fs_length, fs_depth, other_length, other_depth) {
    let mySA=Number((( (2.697 * Number(other_length)) + (4.841 * Number(other_depth))) - 18.924).toFixed(3));
    let fsSA=Number((( (2.697 * Number(fs_length)) + (4.841 * Number(fs_depth))) - 18.924).toFixed(3));
    let percentDiff=Number((((mySA-fsSA)/mySA)*100).toFixed(2));
    let SaMsg;
    if (percentDiff>0) { //Increased in size
      if (compare) {
        SaMsg="You have "+Math.abs(percentDiff)+ "% more fin surface area.";
      } else {
        SaMsg="Stock setting is "+Math.abs(percentDiff)+ "% more fin surface area.";
      }

    } else if (percentDiff<0) { // Decreased in size
      if (compare) {
        SaMsg="You have "+Math.abs(percentDiff)+ "% less fin surface area.";
      } else {
        SaMsg="Stock setting is "+Math.abs(percentDiff)+ "% less fin surface area.";
      }
    } else { // No change in size.
      if (compare) {
        SaMsg="You have the same fin surface area.";
      } else {
        SaMsg="Stock setting has the same fin surface area.";
      }
    }

    SaMsg+=" <a href='#'><i class='icon f7-icons font_125em finSA-info super-script'>info_circle_fill</i></a>";

    $$("#fs_settingsList .sa-msg").remove();
    $$("#fs_settingsList").append("<div class='sa-msg'>"+SaMsg+"</div>");
  },






  finSAPopOver: function() {
    console.log("this is finSAPopOver...");

    var finSAPopOverVar = app.popover.create({
      targetEl: '.finSA-info',
      content: `<div class="popover">
                  <div class="popover-inner">
                    <div class="content-block">
                      <p>
                        <div class='bold'>Surface Area Calculation</div>
                        <p>This data is based on fin shapes being identical between two skis.  The standard "Tear Drop" shape was assumed for calculations.<p>This data is meant to be directional in nature only.  There are many factors that go into comparing ski setups.</p> </p>
                      </p>
                    </div>
                  </div>
                </div>`,
      // Events
      on: {
        open: function (popover) {
          console.log('Popover open');
        },
        opened: function (popover) {
          console.log('Popover opened');
        },
      }
    }).open();
  },






  sameSkiPopOver: function() {
    console.log("this is sameSkiPopOver...");

    var dynamicPopover = app.popover.create({
      targetEl: '.same-ski',
      content: `<div class="popover">
                  <div class="popover-inner">
                    <div class="content-block">
                      <p>
                        <div class='bold'>Compare your setup.</div>
                        <p>Because the <span class='bold'>brand, model, and length</span> matches your ski, you can compare your settings to this one by selecting the "COMPARE" button below.</p>
                      </p>
                    </div>
                  </div>
                </div>`,
      // Events
      on: {
        open: function (popover) {
          console.log('Popover open');
        },
        opened: function (popover) {
          console.log('Popover opened');
        },
      }
    }).open();
  },


  getFinShareFollowers: function() {
    //ws_get_finshare_list_ret_json.php
    var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token }
    var url=app.data.wsURL+'ws_get_finshare_followers_ret_json.php';
    var returnCode;

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE ==1 || jsonObj.RETURN_CODE == -1) {
        console.log('return code 1...success');
        FinShare.renderFinShareFollowers(jsonObj);
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
      } else {
        console.log('return code NOT 1 or -1 fo get finshare followers');
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');

        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function for models

  },




  renderFinShareFollowers: function(obj) {
    //console.log("in renderFinShareFollowers");
    //console.log(obj);
    var data=obj.data;
    var countApproved=0;
    var countRequested=0;

    $$("#fs_requested, #fs_approved").html(''); // empty container before rendering results

    if (obj.RETURN_CODE==1) { // records available for requesting or approved
      var list1="<ul>"; // already approved
            var list0="<ul>"; //not approved yet
      data.forEach(function(item){
        //console.log('in forEach...');
        //console.log(item.user_name);
        //console.log(item.approved);
        if (item.approved != 1) { // put in a list with approve/deny
          countRequested++;
          list0 += `<li id='${item.user_name}' data-fin-share-user=${item.user_name}>
                    <div class="item-content">
                      <div class="item-media"><i class="icon f7-icons color-gray">person_circle</i></div>
                      <div class="item-inner">
                        <div class="item-title">${item.first_name} ${item.last_name}</div>
                        <div class="item-after"> <i class="icon f7-icons color-blue">plusminus_circle</i></div>
                      </div>
                    </div>
                  </li>`;
        } else { // put in regular list with ability to remove
          countApproved++;
          //list1 += ("<li>" + item.first_name + " " + item.last_name + "</li>");
          //

          list1 += `<li id='${item.user_name}' data-fin-share-user=${item.user_name}>
                    <div class="item-content">
                      <div class="item-media"><i class="icon f7-icons color-gray">person_crop_circle_fill_badge_checkmark</i></div>
                      <div class="item-inner">
                        <div class="item-title">${item.first_name} ${item.last_name}</div>
                        <div class="item-after"> <i class="icon f7-icons color-blue">plusminus_circle_fill</i></div>
                      </div>
                    </div>
                  </li>`;

        }

      });
      list0 += "</ul>";
      list1 += "</ul>";
    }


    if (countRequested>0) {
      //console.log(" in requested with " + countRequested);
      $$("#fs_requested").html(list0);
    } else {
      $$("#fs_requested").html("<div class='margin_1em'>You have no pending requests.</div>");
    }

    if (countApproved>0) {
      //console.log(" in approved with " + countApproved);
      $$("#fs_approved").html(list1);
    } else {
      $$("#fs_approved").html("<div class='margin_1em'>You currently have no followers.</div>");
    }
  },








  searchFinShare: function(query) {
    console.log("query is " + query);
    var search_type=$$("input[name='search_type']:checked").val();
    var dataObj = { user_name:Interface.getUser().user_name, search_type:search_type, search_field:query, token:Interface.getUser().token }
    var url=app.data.wsURL+'ws_search_finshare_users_ret_json.php';
    var returnCode;

    if (query.length==0) return FinShare.renderSearchResults(query,{});

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE == 1 || jsonObj.RETURN_CODE == -1) {
        console.log('return code 1 or -1 ...success');
        FinShare.renderSearchResults(query,jsonObj);
        returnCode=jsonObj.RETURN_CODE;
      } else {
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');

        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function for models
  },







  renderSearchResults: function(query,obj) {
    console.log("in renderSearchResults");
    if (Object.keys(obj).length === 0) return $$("#searchResults").html(''); // handles empty query string
    if (obj.RETURN_CODE == -1) return $$("#searchResults").html('<span class="bold margin_1em">No Results Found.<span>'); // handles no records found

    var data=obj.data;
    var str='<div class="list"><ul>';

    data.forEach(function(item){
      let ability=Utility.skierAbility(item.capability_level);
      let skiLength = +item.length.toString();
      str += `<li id='${item.user_name}' data-fin-share-user=${item.user_name}  data-fin-share-first-name=${item.first_name}  data-fin-share-last-name=${item.last_name}>
                <a href="#" class="item-link item-content">
                <div class="item-media"><img src="./static/img/skiLogos/${item.brand}_logo.png" class="logo_36height" /></div>
                  <div class="item-inner">
                    <div class="item-title">
                      <div class="item-header">${item.first_name} ${item.last_name} ( ${ability} )</div>
                      ${item.brand} ${item.model} ${skiLength}" ${item.year}
                      <div class="item-footer">Ski Added: ${item.date_time_created}</div>
                    </div>
                  <div class="item-after">Add</div>
               </div>
             </a>
            </li>`;
    });
    str+='</ul></div>';

    $$("#searchResults").html(str);
  },







  addFinShareUser: function(listObject) {
    var finShareUser=listObject.dataset.finShareUser

      app.dialog.confirm(
        'Select OK to request view access to setup information for ' + listObject.dataset.finShareFirstName + ' ' + listObject.dataset.finShareLastName,
        'Confirm finShare',
        function () {

            var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token, fin_share:finShareUser }
            var url=app.data.wsURL+'ws_add_finshare_user_ret_json.php';
            var returnCode;

            app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
              if (jsonObj.RETURN_CODE == 1) {
                console.log('addFinShareUser return code 1 ...success');
                //FinShare.renderSearchResults(query,jsonObj);
                $$(listObject).remove();
                returnCode=jsonObj.RETURN_CODE;
                Interface.sendNotification(jsonObj.msg_id);
                // send out notifications
                // console.log('msg_id=',jsonObj.msg_id);
                // if (jsonObj.msg_id) {
                //   let notifyUrl=app.data.wsURL+'notifications/sendNotifications.php';
                //   var dataObj = {
                //     user_name:Interface.getUser().user_name,
                //     token:Interface.getUser().token,
                //     msg_id:jsonObj.msg_id
                //   }
                //   app.request.post(
                //     notifyUrl,
                //     dataObj,
                //     function (data) {
                //       console.log("returned data..." ,data);
                //     },
                //     function (data) {
                //       console.log("ERRROR..." ,data);
                //     },
                //     'json'
                //   );
                // }



              } else {
                app.methods.genericAlert("add_fin_share_user",jsonObj.RETURN_CODE);
              }
            }, timeout: 5000
              , beforeSend: function() {
                //console.log('beforeSend toggleFinShare');
                app.preloader.show();
              }, complete: function() {
                console.log('in complete addFinShareUser');

                app.preloader.hide();
              }, error: function(jsonObj, status, err) {
                  if (status == "timeout") {
                    console.log("Timeout Error. " + jsonObj + status + err);
                  } else {
                    console.log("error: "  + status + err);
                    console.log(jsonObj);
                  }
              }
            }) // END ajax function


    });
  },







  deleteFinShareUser: function(listObject) {
    var finShareUser=listObject.dataset.finShareUser

      app.dialog.confirm(
        'Select OK to remove ' + listObject.dataset.finShareFirstName + ' ' + listObject.dataset.finShareLastName + ' from my list.',
        'Remove finShare',
        function () {

            var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token, fin_share:finShareUser }
            var url=app.data.wsURL+'ws_delete_finshare_user_ret_json.php';
            var returnCode;

            app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
              if (jsonObj.RETURN_CODE == 1) {
                console.log('return code 1 ...success');
                //FinShare.renderSearchResults(query,jsonObj);
                //$$(listObject).remove();
                returnCode=jsonObj.RETURN_CODE;
              } else {
                app.methods.genericAlert("delete_fin_share_user",jsonObj.RETURN_CODE);
              }
            }, timeout: 5000
              , beforeSend: function() {
                //console.log('beforeSend toggleFinShare');
                app.preloader.show();
              }, complete: function() {
                console.log('in complete deleteFinShareUser');
                app.views.main.router.navigate('/finShare/');
                app.preloader.hide();
              }, error: function(jsonObj, status, err) {
                  if (status == "timeout") {
                    console.log("Timeout Error. " + jsonObj + status + err);
                  } else {
                    console.log("error: "  + status + err);
                    console.log(jsonObj);
                  }
              }
            }) // END ajax function


    });
  },







  manageRequested: function(finShareUser) {

    var modalRequested = app.dialog.create({
      title:  'Requesting Approval',
      text: 'Please approve or deny this user to view your finShare data.',
      verticalButtons: false,
      buttons: [
        {
          text: 'Deny',
          onClick: function() {
            FinShare.setFinShareApproval(finShareUser,0);
          }
        },
        {
          text: 'Cancel',
          onClick: function() {
            return null;
          }
        },
        {
          text: 'Approve',
          onClick: function() {
            FinShare.setFinShareApproval(finShareUser,1);
          }
        },
      ]
    }).open();
  },






  manageApproved: function(finShareUser) {

    var modalRequested = app.dialog.create({
      title:  'Deny Access',
      text: 'Please select Deny Access to remove this person from accessing your finShare data.',
      verticalButtons: false,
      buttons: [
        {
          text: 'Cancel',
          onClick: function() {
            return null;
          }
        },
        {
          text: 'Deny Access',
          onClick: function() {
            FinShare.setFinShareApproval(finShareUser,0);
          }
        },
      ]
    }).open();
  },






  setFinShareApproval: function(finShareUser,approved) {

    var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token, fin_share:finShareUser, approved:approved }
    var url=app.data.wsURL+'ws_set_finshare_approval_ret_json.php';
    var returnCode;

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE == 1) {
        console.log('return code 1 ...success');
        //FinShare.renderSearchResults(query,jsonObj);
        returnCode=jsonObj.RETURN_CODE;
        Interface.sendNotification(jsonObj.msg_id);
        // send out notifications
        // console.log('msg_id=',jsonObj.msg_id);
        // if (jsonObj.msg_id) {
        //   let notifyUrl=app.data.wsURL+'notifications/sendNotifications.php';
        //   var dataObj = {
        //     user_name:Interface.getUser().user_name,
        //     token:Interface.getUser().token,
        //     msg_id:jsonObj.msg_id
        //   }
        //   app.request.post(
        //     notifyUrl,
        //     dataObj,
        //     function (data) {
        //       console.log("returned data..." ,data);
        //     },
        //     function (data) {
        //       console.log("ERRROR..." ,data);
        //     },
        //     'json'
        //   );
        // }

      } else {
        app.methods.genericAlert("set_fin_share_approval",jsonObj.RETURN_CODE);
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');
        if (returnCode==1) {
          FinShare.getFinShareFollowers();
          app.emit('finShareReqCountChange', null);
        }
        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function
  },






}

export default FinShare;
