export default {
  id: 'f5bcb124d8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div data-page="lookup" class="page">\n    <div class="navbar">\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="/" class="link icon-only"\n            ><i class="icon f7-icons">chevron_left</i></a\n          >\n        </div>\n        <div class="title sliding">Stock Ski Lookup</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block">\n        <div class="black1_1_heading margin_1em_bottom center" id="indexTitle">\n          Water Ski Stock Settings Lookup System\n        </div>\n        <div id="skiSelector_div">\n          <a href="/lookup/brands/" class="button whiteTransBG"\n            >Find Stock Ski Settings</a\n          >\n        </div>\n      </div>\n      <!-- END CONTENT BLOCK -->\n      <div id="factory_brand" class="list"></div>\n\n      <div id="skiSelected" style="display: none"></div>\n\n      <div id="stockSkiListing_div"></div>\n    </div>\n    <!-- END PAGE CONTENT -->\n  </div>\n  <!-- END PAGE -->\n';
      return r;
    }(this);
  },
  styleScoped: false
};