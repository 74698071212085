export default {
  id: 'c4226daf60',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\n  <!-- Scrollable page content -->\n  <div data-page="notifications" class="page" id="notifications_div">\n\n    <div class="navbar">\n        <div class="navbar-bg"></div>\n        <div class="navbar-inner">\n          <div class="left link"><a href="/mySettings/" class="link"><i class="icon f7-icons">chevron_left</i><span>Back</span></a></div>\n            <!-- We need cool sliding animation on title element, so we have additional "sliding" class -->\n          <div class="title center sliding">Notifications</div>\n          <!-- <div class="right link"><a href="#" class="link"><i class="icon f7-icons">gear_alt</i></a></div> -->\n        </div>\n    </div>\n\n      <!-- Scrollable page content -->\n      <div class="page-content">\n        <div id="notifications"></div>\n      </div> <!-- END PAGE CONTENT -->\n\n  </div> <!-- END PAGE -->\n\n\n\n';
      return r;
    }(this);
  },
  styleScoped: false
};