import $$ from 'dom7';
window.$$=$$;
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
//import '../css/icons.css';
//import '../css/app.css';

import '../css_my/styles.css';
import '../css_my/framework7-icons.css';
import '../css_my/animate.min.css';


// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

// Import JS files (PS)
//import routes from '../js_my/routes.js';


// Import main app component
import App from '../app.f7.html';


// //import my_app from '../js_my/my-app.js' // DELETE THIS...ALL MOVED TO app.js;
import Interface from '../js_my/interface.js';
import Utility from '../js_my/utility.js';
import Users from '../js_my/user_class.js';
import ski_class from '../js_my/ski_class.js';
//import settings_class from '../js_my/settings_class.js';
import notifications from '../js_my/notifications.js';
import register from '../js_my/register.js';
import MySettings from '../js_my/my_settings.js';
import FinShare from '../js_my/fin_share.js';
import my_skis from '../js_my/my_skis.js';
import SkiLookup from '../js_my/ski_settings_smart_select.js';
import LocalSave from '../js_my/save_stock_settings_local.js';
import first_entry from '../js_my/first_entry.js';




var api_vers="2_0_5";
//var app_vers="2_0_10";
//var user_vers=app_vers.replace(/_/g,".");
var wsURL="https://finDB.paulsantangelo.com/ws/"+api_vers+"/";
//var wsURL="http://localhost:8083/ws/"+api_vers+"/";

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  id: 'com.paulsantangelo.findb', // App bundle ID
  name: 'finDB', // App name
  theme: 'auto', // Automatic theme detection
  popup: {
      closeByBackdropClick: false,
    },


  // App root data
  data: function () {
    return {
      api_vers: "2_0_5",
      app_vers: "3_0_9", //default...to be replaced by corodov upon cordova init using Build plugin
      upd_vers_Obj: {},
      wsURL: "https://finDB.paulsantangelo.com/ws/"+api_vers+"/",
      device: { manufacturer: null,
                platform: null,
                model: null,
                version: null },
      G_LOOKUP_TYPE: null,
      lookup: {},
      settingArray: {},
      measureObj:{},
      isCordova:false,
      isIOS:false,
      iosPromptCount:0,
      isAndroid:false,
      appLinkiOS:"https://itunes.apple.com/us/app/fin-db/id1234631397?mt=8",
      appLinkAndroid:"https://play.google.com/store/apps/details?id=com.paulsantangelo.finDB",
    };
  },


  // App root methods
  methods: {

    onLine: function() {
      return navigator.onLine;
    },

    onOffline: function () {
      console.log(' in onOffline');

      app.dialog.alert(
        '<div>No network connection established.  This application requires network access.</div>',
        'Network Status',
        function () {
          return null;
      });

         return false;
    },

    onOnline: function () {
      console.log(' in onOnline');
      if ($$('.close-notification')) {
        $$('.close-notification').click();
      }
    },

    genericAlert: function (caller,returnCode) {
    //  var calledBy=arguments.callee.caller.name
      var title;
      if (returnCode==401) {
        title="Unauthorized Access";
      } else {
        title="System Error";
      }
      var msg="Error: "+caller+ " / " +returnCode;
      app.dialog.alert(
        '<div>The system incurred an error.  Please log out and re-try.</div>' + msg,
        title,
        function () {
          return null;
      });
    },

    receiveNotfication: function (data) {
        console.log('---------- here in app.receiveNotification');
    },


    addRegistrationID: function (registration_id) {
      console.log('---------- here in app.addRegistrationID');
    },

    getHowToMeasure: function () {
      console.log('inside getHowToMeasure');
      if (!app.methods.onLine()) return app.methods.onOffline();

       var url=app.data.wsURL+'ws_get_how_to_measure_ret_json.php';
       app.request.post(url, {source:'mobileApp'}, function(measure_Obj) {
         var measureObj=measure_Obj;
         console.log(Object.keys(measureObj[0]).length);
         if (measureObj.length>0) { // RETURNED RESULTS
           if (Object.keys(measureObj[0]).length>0) { // results have values
             console.log('success in getting measured object');
             console.log(measureObj);
             app.data.measureObj=measureObj;
           } else {
             console.log('No data found');
           }
         } else {
           console.log('No object found');
         }
       }, 'json');
      //});
    },

    // RE-USE of the ski lookup functions IN and OUT of session have to route to different locations.
    routeSkiLookup: function () {
      console.log("In routeSkiLookup Function");

      if (app.data.G_LOOKUP_TYPE=='findSki') { // THIS IS FOR OUT OF SESSION
        app.views.main.router.navigate('/lookup/');
      } else { // ALL IN SESSIONS ROUTE BACK TO SPECIFIC PAGE
        app.views.main.router.navigate('/mySkis/');
        Interface.interface_promptSkiName(app.data.lookup.skiYear);
      }
    }

  },


  // App routes
  routes: routes,


  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      console.log("in app.init method....Device is ready!");

      $$(document).on('click',".pwd-view", function(e) {
        if ($$("#pwd")[0].type == 'text') {
          $$("#pwd")[0].type = 'password';
          this.innerText="eye_slash";
        } else {
          $$("#pwd")[0].type = 'text';
          this.innerText="eye";
        }

      });

      $$(document).on('click',"#addNoteBtn", function(e) {
        console.log("addNote clicked from routes /");
            MySettings.addNote();
        });

        $$('.page[data-page="menu-panel"]').on('click', '.view_history', function (e) {
          console.log('------------------------view_history here from datapage menu panel.');
          MySettings.viewHistory();
        });

        $$('#app>.view-main').on('click', '#newSettingNote', function (e) {
          console.log("newSettingNote clicked root js file");
          MySettings.AddNotePopUp();
        });


        // THIS IS CODE TO COMPARE LATEST VERSION WITH USERS Version
        var url=wsURL+'get_user_version_status_ret_json.php';
        let data="user_vers="+app.data.app_vers.replace(/_/g,".");
        CallMethod(url, data , onSuccess);

      if (f7.device.cordova) { // put all cordova device hooks here
        console.log("in f7.device.cordova");
        app.data.isCordova=1;
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        console.log("cordovaApp.init with app.data=");
        console.log(app.data);
        console.log(device);
        //inAppBrowser.init();
        // var isAndroid = app.device.android === true;
        // var isIos = app.device.ios === true;
        app.data.isAndroid = app.device.android === true;
        app.data.isIOS = app.device.ios === true;
        app.data.app_vers = BuildInfo.version ? BuildInfo.version.replace(/_/g,".") : app.data.app_vers;
        //app.data.device.os = device.platform ? device.platform : null;
        app.data.device.manufacturer = device.manufacturer ? device.manufacturer : null;
        app.data.device.platform = device.platform ? device.platform : null;
        app.data.device.model = device.model ? device.model : null;
        app.data.device.version = device.version ? device.version : null;

        console.log("^^^^^^^^^^^^^app_vers assigned = " + app.data.app_vers);

        if (app.device.android) {
          document.addEventListener('backbutton', function(event){
               onBackKeyDown()
            }, false);
          function onBackKeyDown() {
            console.log("closing app...");
            navigator.app.exitApp();
          }
        }

      } // end if cordova

    }, // end app.init
  },


}); // END APP object


// RE-USE of the ski lookup functions IN and OUT of session have to route to different locations.
function routeSkiLookup() {
  console.log("In routeSkiLookup Function");

  if (G_LOOKUP_TYPE=='findSki') { // THIS IS FOR OUT OF SESSION
    app.view.main.router.navigate('/lookup/');
  } else { // ALL IN SESSIONS ROUTE BACK TO SPECIFIC PAGE
    app.view.main.router.navigate('/mySkis/');
    Interface.interface_promptSkiName(app.data.lookup.skiYear);
  }

}


// LISTENER / SUBSCRIBER for when user selects a ski and ski count is displayed in nav
app.on('skiUsageCount', function (thisSki) {
  console.log("emitted function skiUsageCount");
  if (thisSki) {
    if (thisSki.ski_count==0) {
      $$("#skiCount").html("You are the only member currently riding the " + thisSki.brand + " " + thisSki.model);
    } else {
      if (thisSki.ski_count==1) {
        $$("#skiCount").html(thisSki.ski_count + " other member is currently riding the " + thisSki.brand + " " + thisSki.model);
      } else {
        $$("#skiCount").html(thisSki.ski_count + " other members are currently riding the " + thisSki.brand + " " + thisSki.model);
      }
    }
  }
});


// emitted listener for finShare change event when User toggles to enable/disable the finShare service
app.on('finShareChange', function (fin_share_status) {
  console.log("emitted function finShareChange with fin_share_status=" + fin_share_status);
  $$("#finShareStatus").html(FinShare.finShareStatusMessage[fin_share_status]);
  Users.setUserItems({fin_share:fin_share_status});
  console.log("data_priv is ....");
  console.log(Interface.getUser().data_priv);
  if (!Interface.getUser().fin_share && !Interface.getUser().data_priv) { // if finshare is disabled and user has not agreed to Data Privacy
    if ($$('#fs_pp_agree')) $$('#fs_pp_agree').remove();
    $$('#finShareOverview_div #finShareStatus').parent().prepend("<div id='fs_pp_agree'>By enabling, you agree to the <a href='#' class='regDPP'>Privacy Policy</a></div>");
  }
  app.emit('finShareReqCountChange', null);
});



app.on('finShareReqCountChange', function () {
  console.log("--------------emitted function finShareReqCountChange");
  var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token }
  var url=app.data.wsURL+'ws_get_finshare_request_count_ret_json.php';
  var returnCode;

  app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
    if (jsonObj.RETURN_CODE == 1) {
      $$(".fs_total_users").html('<span class="bold">' + jsonObj.total_count + '</span> users have enabled finShare.');
      if (Interface.getUser().fin_share) {
        $$(".fs_total_users").append('<div><a class="button button-small" href="/finShareAdd/">Start Searching</a></div>');
      }
      if ( jsonObj.count>0 ) {
        $$(".fs-req-count").html('<span class="badge color-red">'+jsonObj.count+'</span>');
      } else {
        $$(".fs-req-count").html('');
      }
      returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
    } else {
      console.log('return code NOT 1 or -1 fo get finshare followers');
      $$(".fs-req-count").html('');
      returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
    }
  }, timeout: 5000
    , beforeSend: function() {
      app.preloader.show();
    }, complete: function() {
      app.preloader.hide();
    }, error: function(jsonObj, status, err) {
        if (status == "timeout") {
          console.log("Timeout Error. " + jsonObj + status + err);
        } else {
          console.log("error: "  + status + err);
          console.log(jsonObj);
        }
    }
  }) // END ajax function for models
});




function CallMethod(url, parameters, successCallback) {
    console.log("in CallMethod function");
    if (!app.methods.onLine()) return app.methods.onOffline();

    app.request({
        type: 'POST',
        url: url,
        data: parameters,
        //contentType: 'application/json;',
        dataType: 'json',
        success: successCallback,
        error: function(xhr, textStatus, errorThrown) {
            console.log('---------- ------- -------- error from CallMethod with textStatus='+ textStatus);
           console.log(errorThrown);
           console.log(xhr);
        }
    });
}


function onSuccess(upd_vers_Obj) {
    console.log("++++++++++ +++++++++ +++++++++ this is onSuccess function with param="+upd_vers_Obj);

    var upd_vers_Obj = {user_vers:upd_vers_Obj[0].user_vers,latest_vers:upd_vers_Obj[0].latest_vers,vers_features:upd_vers_Obj[0].vers_features,vers_update:upd_vers_Obj[0].vers_update};
    app.data.upd_vers_Obj=upd_vers_Obj;
    let update_link;
    let notify_text="Your version: "+ upd_vers_Obj.user_vers+ ". Latest version: "+upd_vers_Obj.latest_vers;
    if (app.device.ios) {
      update_link="<a class='external' href='"+app.data.appLinkiOS+"' target='_system'>Learn More <i class='icon f7-icons color-blue'>info_circle_fill</i></a>";
    }
    if (app.device.android) {
      update_link="<a class='external' href='"+app.data.appLinkAndroid+"' target='_system'>Learn More <i class='icon f7-icons color-blue'>info_circle_fill</i></a>";
    }

    if (typeof upd_vers_Obj != "undefined" && upd_vers_Obj.vers_update<0) {
      console.log('need an update available');
      var notificationClickToClose = app.notification.create({
        icon: '<img src="/static/img/icon-40.png" width="24" height="24" />',
        title: 'finDB version ' + upd_vers_Obj.user_vers,
        titleRightText: 'now',
        subtitle: 'Update available. Version: ' +upd_vers_Obj.latest_vers,
        text: update_link,
        closeButton: true,
        closeOnClick: true,
      });
      notificationClickToClose.open();
    }
}


// THIS MAKES APP global
window.app=app;
