import $$ from 'dom7';
//import Users from '../js_my/user_class.js';
import Skis from '../js_my/ski_class.js';
import Settings from '../js_my/settings_class.js';
import Utility from '../js_my/utility.js';
import Interface from '../js_my/interface.js';


var MySettings = {
  viewCounter:0,
  newAnim:{},
  tempStockChosen:{},

  init_ski: function () {
    console.log('in init_ski');


    if (! Interface.getUser().profileActivated) { // see if user profile is populated
      app.dialog.alert(
        'your user profile must be complete.  Select OK below to continue.',
        'Before you can begin...',
        function () {
          //mainView.router.load( { url:'profile.html' });
          app.views.main.router.navigate('/profile/');
        }
      );
    } else {
      MySettings.populateCurrentSki();
      MySettings.populateCurrentSettings();
      MySettings.finshare_prompt();
      //MySettings.populateCurrentBindings();
    }



  }, // end init_ski functions













  populateCurrentSki: function () {
    console.log('in populateCurrentSki with viewCounter=' + MySettings.viewCounter);
    // console.log("Skis.thisSki is ");
    // console.log(Skis.thisSki);

    if (typeof Skis.thisSki !== "undefined" && Object.keys(Skis.thisSki).length !== 0) {
      console.log("skis are in thisSkis object....");
      var thisSki=Skis.thisSki;
      if (thisSki.my_ski_name) {
        $$(".page #myCurrentSki").text(thisSki.my_ski_name);
        //console.log('Users');
        //console.log(Users);
        //$$(".page #myCurrentSki").text(Users.thisSki.user_name);
      } else {
        var skiName=thisSki.length.toString() + '" ' + thisSki.brand + ' ' + thisSki.model + ' ' + thisSki.year;
        //var skiName=Users.thisSki.length + " " + Users.thisSki.brand + " " + Users.thisSki.model + " " + Users.thisSki.year;
        $$(".page #myCurrentSki").text(skiName);
      }

      // INSERT BRAND LOGO IF LOGO IS AVAILABLE
      if (thisSki.brand) {
        Utility.checkImageExists("./static/img/skiLogos/"+thisSki.brand+"_logo.png", function(existsImage) {
          if(existsImage == true) {
            console.log("++++++++++true.  Logo available.");
            $$(".page #ul_mySki .item-content .item-media").append('<a href="/mySkis/" id="editSkiBtn"><img src="./static/img/skiLogos/'+thisSki.brand+'_logo.png" class="logo_36height" /></a>');
          }
          else {
            console.log("++++++++++false.  Logo not available.");
          }
        });
      }



    } else { // IF USER HAS NO SKI's SELECTED, Like first time entry, direct to ski selection page
      console.log("populateCurrentSki with empty  or null thisSki object...");
      $$(".page #myCurrentSki").text("Add Ski");
      if (MySettings.viewCounter<1) {
        MySettings.viewCounter++;
        console.log("first select a ski with viewCounter=" + MySettings.viewCounter);
        var modalFirstSki = app.dialog.create({
          title: 'Welcome!',
          text: 'Before you save any settings, you must select a ski to apply these to.',
          afterText:  '',
          buttons: [
            {
              text: 'Select My Ski',
              bold: true,
              onClick: function () {
                //mainView.router.load( { url:'mySkis.html' });
                app.views.main.router.navigate('/mySkis/');
              }
            },
          ]
        }).open();
        /*
        app.alert(
          'Before you save any settings, you must select a ski to apply these to.',
          'Welcome!',
          function () {
            mainView.router.load( { url:'mySkis.html' });
          }
        );*/
      }
    }
  },





  /*
    ********* Writes out users current bindings to the interface.
    ********* These values can be changed in Profile, so need to be updated independently of fin settings so they can be re-freshed on screen
  */
 populateCurrentBindings: function() {
   // populate current binding types down in SVG graphic area
   $$(".page #myFrontBindingType").html(Interface.getUser().front_binding_type);
   $$(".page #myRearBindingType").html(Interface.getUser().rear_binding_type);
 },


/*
  ********* Writes out users current settings to the interface.
  ********* If user does not have settings saved, will write out stock settings.
*/

  populateCurrentSettings: function () {
    console.log("in populateCurrentSettings");
    // var thisSetting=Settings.getSettings ? Settings.getSettings : {};
     console.log("Settings.thisSetting.getSettings is");
     console.log(Settings.thisSetting.getSettings);

    if (Object.keys(Settings.thisSetting).length !== 0) {
      //var thisSetting=Settings.thisSetting;//Settings.getSettings;
      var thisSetting=Settings.thisSetting.getSettings;
      console.log("Object thisSetting IS DEFINED");
      console.log(thisSetting);
      if (thisSetting.setting_id) {
        if (thisSetting.assigned==1) {
          $$(".page #dateCreated").addClass('redText');
          $$(".page #dateCreated").html("<span class='redText bold text_8em'>"+ thisSetting.assigned_by  +" Custom Setting</span>");
        } else {
          let dateStr=thisSetting.date_time_created;
          if (Utility.isUTCFormat(dateStr)) {
            $$(".page #dateCreated").html(Utility.getLocalDateTimeString(dateStr,'pretty'));// PRINTS TO SCREEN
          } else {
            $$(".page #dateCreated").html(dateStr);
          }

        }

        $$(".page #myCurrentBinding").html(thisSetting.front_binding);
        $$(".page #myCurrentLength").html(thisSetting.length);
        $$(".page #myCurrentDepth").html(thisSetting.depth);
        $$(".page #myCurrentDFT").html(thisSetting.dft);
        $$(".page #myCurrentWingAngle").html(thisSetting.wing_angle);

        if (thisSetting.leading_edge > 0) { // myCurrentLE - case values are in the database for a current setting
          $$(".page #myCurrentLE").html(thisSetting.leading_edge);
        } else { // - case values are NOT in the database for a current setting
          $$(".page #myCurrentLE").html( (Number(thisSetting.dft) + Number(thisSetting.length)).toFixed(3) );
        }

        if (thisSetting.binding_to_le > 0) { //myCurrentBindToLE - case values are in the database for a current setting
          $$(".page #myCurrentBindToLE").html(thisSetting.binding_to_le);
        } else { // - case values are NOT in the database for a current setting
          $$(".page #myCurrentBindToLE").html( (Number(thisSetting.front_binding) - (Number(thisSetting.dft) + Number(thisSetting.length))).toFixed(4) );
        }


        //$$(".page #mySettingsBinding").html(thisSetting.measure_binding);
        $$(".page #stockBinding").html(thisSetting.measure_binding);
        $$(".page #stockLength").html(thisSetting.measure_length);
        $$(".page #stockDepth").html(thisSetting.measure_depth);
        $$(".page #stockDFT").html(thisSetting.measure_dft);
        $$(".page #stockWingAngle").html("");

        if ( $$(".page #dateCreated , .page .measureOverlay").hasClass('redText') ) { // redText class is for stock settings only when initially loaded
          $$(".page #dateCreated , .page .measureOverlay").removeClass('redText');
        }
      }
    } else {
      if (typeof Skis.thisSki !== "undefined" && Object.keys(Skis.thisSki).length !== 0) {
        var thisSki=Skis.thisSki;
        if (thisSki.stockArray.data.length>1) {
          console.log('if writeOut');
          MySettings.chooseFromMultiSettings(thisSki);
        } else {
          console.log('else writeOut');
          MySettings.writeOutStockSettings(0);
        }
      }
    }
  }, // END populateCurrentSettings










  // Function to prompt user to select from multiple stock settings
  // REturns index of array item
  chooseFromMultiSettings: function (thisSki) {
    console.log("func chooseFromMultiSettings");
    var thisSki=Skis.thisSki;

    var textStr="The <span class='bold'>"+thisSki.year+" "+thisSki.brand+ " "+thisSki.model+" "+thisSki.length+ "</span> has multiple stock settings.  Please choose from the list below as your starting point.";
    var btns=[];
    thisSki.stockArray.data.forEach( function(element){
      //btns['text']=element.setting_name;
      btns.push({
          text: element.setting_name
      });

    });
    console.log(btns);
    //var btns= [ {text:'Radar 1'} , {text:'Radar 2'} ];

    app.dialog.create({
      title: 'Multiple Settings',
      text: textStr,
      buttons: btns ,
      verticalButtons: true,
      onClick: function(dialog, index) {
        console.log('button index clicked= '+index);
        MySettings.writeOutStockSettings(index);
        //return index;
        //app.router.navigate('/mySkis/');
      }
    }).open();
  },









  // Writes out stock settings to mySettings page for case of a new ski (NOT custom settings)
  writeOutStockSettings: function (index) {
    console.log('--------------- embedded function writeOut');
    var thisSki=Skis.thisSki;
    $$(".page #dateCreated").html('Stock Settings');
    $$(".page #myCurrentBinding").html(thisSki.stockArray.data[index].stock_binding_location);
    $$(".page #myCurrentLength").html(thisSki.stockArray.data[index].stock_fin_length);
    $$(".page #myCurrentDepth").html(thisSki.stockArray.data[index].stock_fin_depth);
    $$(".page #myCurrentDFT").html(thisSki.stockArray.data[index].stock_fin_dft);
    $$(".page #myCurrentWingAngle").html(thisSki.stockArray.data[index].stock_wing_angle);
    $$(".page #myCurrentLE").html( (Number(thisSki.stockArray.data[index].stock_fin_dft) + Number(thisSki.stockArray.data[index].stock_fin_length)).toFixed(3));
    $$(".page #myCurrentBindToLE").html( (Number(thisSki.stockArray.data[index].stock_binding_location) - (Number(thisSki.stockArray.data[index].stock_fin_dft) + Number(thisSki.stockArray.data[index].stock_fin_length))).toFixed(4));

    //BELOW SHOW HOW STOCK IS MEASURED IF THERE IS A VALUE FOR EACH OF THE SETTINGS FROM THE FACTORY
    thisSki.stockArray.data[index].stock_binding_location>0 ? $$(".page #stockBinding").html("<span class='redText'>"+thisSki.measure_binding+"</span>") : $$(".page #stockBinding").html("");
    thisSki.stockArray.data[index].stock_fin_length>0 ? $$(".page #stockLength").html("<span class='redText'>"+thisSki.measure_length+"</span>") : $$(".page #stockLength").html("");
    thisSki.stockArray.data[index].stock_fin_depth>0 ? $$(".page #stockDepth").html("<span class='redText'>"+thisSki.measure_depth+"</span>") : $$(".page #stockDepth").html("");
    thisSki.stockArray.data[index].stock_fin_dft>0 ? $$(".page #stockDFT").html("<span class='redText'>"+thisSki.measure_dft+"</span>") : $$(".page #stockDFT").html("");
    $$(".page #stockWingAngle").html("");

    $$(".page #dateCreated").addClass('redText');
    MySettings.tempStockSettingsChosen (index);
  },









  // NEED A FUNCTION TO TEMPORAIRLIY HOLD STOCK SETTINGS UNTIL USER SAVES THEIR SETTINGS
  tempStockSettingsChosen: function (index) {
    var thisSki=Skis.thisSki;
    var tempStockChosen={'stock_binding_location': thisSki.stockArray.data[index].stock_binding_location,'stock_fin_length':thisSki.stockArray.data[index].stock_fin_length,'stock_fin_depth':thisSki.stockArray.data[index].stock_fin_depth,'stock_fin_dft':thisSki.stockArray.data[index].stock_fin_dft,'stock_wing_angle':thisSki.stockArray.data[index].stock_wing_angle};
    MySettings.tempStockChosen = tempStockChosen;
  },













  toggleViewStock:function () {
    console.log("in toggleViewStock");
    var thisSki=Skis.thisSki;
      //if ($$(".page #viewStockBtn i").text() == "more_vertical_round") {
      if ($$(".page #viewStockBtn").text().includes("STOCK")) {

        if ( thisSki.stockArray.status!='success' && thisSki.stockArray.RETURN_CODE!=1) {

          console.log("No stock settings available");
          var stockHTML ='<div id="stockSettings">';
          stockHTML+='<div class="stockName center">No stock data is available.</div>';
          stockHTML+='</div>';
      } else {
        var stockSettingsArray=thisSki.stockArray.data;
        console.log("Stock settings are available");
        var stockHTML ='<div id="stockSettings">';
        stockHTML+='<div class="data-table">';
        stockHTML+='<div class="stockName center">'+thisSki.length + ' ' + thisSki.brand + ' ' + thisSki.model + ' ' + thisSki.year + '</div>';
        stockHTML+='<table>';
        stockHTML+='<thead>';
        stockHTML+='<tr>';
        stockHTML+='<th class="numeric-cell">Binding</th>';
        stockHTML+='<th class="numeric-cell">Length</th>';
        stockHTML+='<th class="numeric-cell">Depth</th>';
        stockHTML+='<th class="numeric-cell">DFT</th>';
        stockHTML+='<th class="numeric-cell">Wing</th>';
        stockHTML+='</tr>';
        stockHTML+='</thead>';
        stockHTML+='<tbody>';


        stockSettingsArray.forEach(function(element) {
          let settingName = element.setting_name ? element.setting_name : "";
          stockHTML+='<tr>';
          stockHTML+='<td colspan="5"><span class="lightBlueText bold">' + settingName + '</span></td>';
          stockHTML+='</tr>';

          stockHTML+='<tr>';
          stockHTML+='<td class="numeric-cell">' +element.stock_binding_location+ '</td>';
          stockHTML+='<td class="numeric-cell">' +element.stock_fin_length+ '</td>';
          stockHTML+='<td class="numeric-cell">' +element.stock_fin_depth+ '</td>';
          stockHTML+='<td class="numeric-cell">' +element.stock_fin_dft+ '</td>';
          stockHTML+='<td class="numeric-cell">' +element.stock_wing_angle+ '</td>';
          stockHTML+='</tr>';
        });

        stockHTML+='<tr>';
        stockHTML+='<td class="numeric-cell">' +thisSki.measure_binding+ '</td>';
        stockHTML+='<td class="numeric-cell">' +thisSki.measure_length+ '</td>';
        stockHTML+='<td class="numeric-cell">' +thisSki.measure_depth+ '</td>';
        stockHTML+='<td class="numeric-cell">' +thisSki.measure_dft+ '</td>';
        stockHTML+='<td class="numeric-cell"></td>';
        stockHTML+='</tr>';
        stockHTML+='</tbody>';
        stockHTML+='</table>';
        stockHTML+='</div>';
        stockHTML+='</div>';
      }

        //$$("#stockSettingsDiv").css("height","100%");

        $$('#stockSettingsDiv').html(stockHTML);
        var stockHeight=$$("#stockSettings").height();
        console.log("stockSettingsDiv height = " + stockHeight);

        $$('#stockSettingsDiv').animate(
          /* CSS properties to animate, e.g.: */
          {
              'height': stockHeight,
              'opacity': 1
          },
          {
              // Animation duraion in ms, optional (default to 300)
              duration: 500,
              // Animation easing, optional (default to 'swing'), can be also 'linear'
              easing: 'swing',
              /* Callbacks */
              // Animation begins, optional
              begin: function (elements) {
                  console.log('animation began');

              },
              // Animation completed, optional
              complete: function (elements) {
                  console.log('animation completed');
              },
              // Animation in progress, optional
              progress: function (elements, complete, remaining, start) {
                  /* Where
                  complete - The call's completion percentage (as a decimal value)
                  remaining - How much time remains until the call completes (in ms)
                  start - The absolute time at which the call began (in ms)
                  */
                  console.log('animation in progress');
              }
          }
        );

        //$$(".page #viewStockBtn i").text("more_vertical_round_fill");
        $$(".page #viewStockBtn").html('HIDE<i class="icon f7-icons font_125em">chevron_up</i>');

      } else { // NOW HIDE THE SETTINGS
        //$$(".page #stockBinding, .page #stockLength, .page #stockDepth, .page #stockDFT, .page #stockWingAngle").text("");
        //$$(".page #viewStockBtn i").text("more_vertical_round");
        $$(".page #viewStockBtn").html('STOCK<i class="icon f7-icons font_125em">chevron_down</i>');

        $$('#stockSettingsDiv').animate(
          /* CSS properties to animate, e.g.: */
          {
            'height': '0px',
            'opacity': 0
          },
          /* Animation parameters */
          {
              // Animation duraion in ms, optional (default to 300)
              duration: 200,
              // Animation easing, optional (default to 'swing'), can be also 'linear'
              easing: 'swing',
              /* Callbacks */
              // Animation begins, optional
              begin: function (elements) {
                  console.log('animation began');
              },
              // Animation completed, optional
              complete: function (elements) {
                  console.log('animation completed');
                  $$("#stockSettingsDiv").html('');
              },
              // Animation in progress, optional
              progress: function (elements, complete, remaining, start) {
                  /* Where
                  complete - The call's completion percentage (as a decimal value)
                  remaining - How much time remains until the call completes (in ms)
                  start - The absolute time at which the call began (in ms)
                  */
                  console.log('animation in progress');
              }
          }
        );



      }
    //}
  },












  viewHistory: function (user_name, ski_id) {

    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;
    var thisSki=Skis.thisSki;

    var this_user_name = user_name ? user_name : Interface.getUser().user_name;
    var this_ski_id = ski_id ? ski_id : thisSetting.ski_id;
    var popup_title = (arguments.length) ? "Settings History" : thisSki.my_ski_name;

    var metaNotes=false;
    var notesIdArray=[];
  	console.log('viewHistory function with this many arguments passed in...' + arguments.length);
    if (!app.methods.onLine()) return app.methods.onOffline();



    if (Object.keys(thisSetting).length == 0) {
      app.dialog.alert(
        'No History is available yet.  After you make your first settings change, you will be able to view all setting changes here.',
        'No History Available.',
        function () {
          null;
        }
      );
      return null;
    }



            var url=app.data.wsURL+'ws_get_settings_history_ret_json.php';
            var returnCode;

          	app.request({url:url,data:{ user_name:this_user_name, token:Interface.getUser().token, ski_id:this_ski_id },type:'POST',dataType: 'json'
    				,success:function(json_Obj) {
    						console.log('ajax success.');
    						if (json_Obj.length>0) { // RETURNED RESULTS
                  //console.log('json length is ' + json_Obj.length);

              		if (json_Obj[0].RETURN_CODE==1) {
                    returnCode=1;
                        var popupHTML = '<div class="popup popup-history">';
                        popupHTML+='<div class="page">';

                        popupHTML+='<div class="navbar">';
                        popupHTML+='<div class="navbar-inner">';
                        popupHTML+='<div class="title">'+popup_title+'</div>';
                        popupHTML+='<div class="right"><a href="#" class="link popup-close">Close</a></div>';
                        popupHTML+='</div>';
                        popupHTML+='</div>';

                        popupHTML+='<div class="page-content">';
                        popupHTML+='<div class="list">';
                        popupHTML+='<ul id="ulHistory">';

                    for (let a=0;a<json_Obj.length;a++) {
                      popupHTML+='<li class="accordion-item item_'+a+'"><a href="#" class="item-content item-link lightOrangeBG">';
                      popupHTML+='<div class="item-inner">';
                      popupHTML+='<div class="item-title">'+json_Obj[a].date_time_created;
                      if (json_Obj[a].current==1) {
                        popupHTML+='<span class="smallText">(current)</span></div>';
                      } else {
                        popupHTML+='</div>';
                      }
                      popupHTML+='</div></a>'; // item inner
                      popupHTML+='<div class="accordion-item-content">';
                      popupHTML+='<div class="block">';

                        popupHTML+='<div class="data-table">';
                        popupHTML+='<table>';
                        popupHTML+='<thead>';
                        popupHTML+='<tr>';
                        popupHTML+='<th class="numeric-cell">Binding</th>';
                        popupHTML+='<th class="numeric-cell">Length</th>';
                        popupHTML+='<th class="numeric-cell">Depth</th>';
                        popupHTML+='<th class="numeric-cell">DFT</th>';
                        popupHTML+='<th class="numeric-cell">Wing</th>';
                        popupHTML+='</tr>';
                        popupHTML+='</thead>';
                        popupHTML+='<tbody>';
                        popupHTML+='<tr>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].front_binding+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].length+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].depth+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].dft+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].wing_angle+ '</td>';
                        popupHTML+='</tr>';
                        popupHTML+='<tr>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].measure_binding+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].measure_length+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].measure_depth+ '</td>';
                        popupHTML+='<td class="numeric-cell">' +json_Obj[a].measure_dft+ '</td>';
                        popupHTML+='<td class="numeric-cell"></td>';
                        popupHTML+='</tr>';
                        popupHTML+='</tbody>';
                        popupHTML+='</table>';
                        popupHTML+='</div>'; // end data-table

                        popupHTML+='<div class="center">';
                        if (json_Obj[a].leading_edge>0) {
                          popupHTML+='<div class="chip">';
                          popupHTML+='<div class="chip-label">LE: '+json_Obj[a].leading_edge+'</div>';
                          popupHTML+='</div> ';
                        }
                        if (json_Obj[a].binding_to_le>0) {
                          popupHTML+=' <div class="chip">';
                          popupHTML+='<div class="chip-label">B-LE: '+json_Obj[a].binding_to_le+'</div>';
                          popupHTML+='</div>';
                        }
                        popupHTML+='</div>'; // end center

                        if (json_Obj[a].notes) {
                          popupHTML+='<div class="metaNotes" id="note_'+json_Obj[a].notes+'"></div>';
                          notesIdArray.push(json_Obj[a].notes);
                          metaNotes=true;
                        }
                      popupHTML+='</div>'; // END block
                      popupHTML+='</div>'; // END accordian item content
                      popupHTML+='</li>';
                    }

              			//});
                    popupHTML+='</ul>'; // end ul

                    popupHTML+='</div>'; // end class accordian list

                    popupHTML+='</div>'; // end class page content
                    popupHTML+='</div>'; // end class page
                    popupHTML+='</div>'; // end class popup

                    let history_popup=app.popup.open(popupHTML);
    							} else {
                    console.log('return code is NOT 1');
                    returnCode=json_Obj[0].RETURN_CODE;
                  }
    						}
          		}, complete: function(xhr, status){
                  console.log('ajax complete for viewHistory.')
                  if (returnCode!=1) {
                    app.methods.genericAlert("settings_history",returnCode);
                  }

                  var jsonObjLength=JSON.parse(xhr.responseText).length;
                  if (jsonObjLength>1) {
                      app.accordion.toggle('.item_1');
                  }
                  app.accordion.toggle('.item_0');

                  if (metaNotes) { // THERE ARE NOTES IN THIS HISTORY
                    MySettings.getHistoryNotes(this_user_name, this_ski_id, notesIdArray);
                  }
          	  }, // end COMPLETE
    					timeout: 5000,
    					error: function(json_Obj, status, err) {
    						if (status == "timeout") {
    								console.log("Timeout Error. " + json_Obj + status + err);
    								//$( "#error_login").html("Timeout error.  Please retry.")
    								//$(popDiv).html('TimeOut Error:   Please retry.');
    						} else {
    								// another error occured
    								//$( "#error_login").html('Error occurred.  Please retry.');
    								console.log("error: " + json_Obj + status + err);
    						}
              }, // end error
                beforeSend: function(){
    							console.log('ajax beforeSend.')
                  console.log('user_name='+Interface.getUser().user_name);
                    //jQuery('.upd').remove();
                    //jQuery('#submit_unassigned_results').html('');
                    //jQuery('#unassigned_list').html('<span class="upd">Retrieving data...</span>');
    							} // END before Send
            });
  }, // END FUNCTION










  getHistoryNotes: function (user_name, ski_id, notesIdArray) {
    console.log("in history notes " + notesIdArray.toString() );
    var setting_id=notesIdArray.toString();
    if (!app.methods.onLine()) return app.methods.onOffline();

    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;

    var this_user_name = user_name ? user_name : Interface.getUser().user_name;
    var this_ski_id = ski_id ? ski_id : thisSetting.ski_id;

    var url=app.data.wsURL+'ws_get_settings_history_notes_ret_json.php';
    var returnCode;

    app.request({url:url,data:{ user_name:this_user_name,token:Interface.getUser().token, ski_id:this_ski_id, setting_id:setting_id },type:'POST',dataType: 'json'
    ,success:function(json_Obj) {
      //  console.log('ajax success.');
        if (json_Obj.length>0) { // RETURNED RESULTS
        //  console.log('json length is ' + json_Obj.length);

          if (json_Obj[0].RETURN_CODE==1) {
            returnCode=1;
            //console.log('json_Obj is ' + json_Obj);

            //$$.each(json_Obj, function( index, value ) {
            for (let a=0;a<json_Obj.length;a++) {
              var noteStr='';
              noteStr+="<div class='historyNotesHdr'>"+Utility.getLocalDateTimeString(json_Obj[a].date_time,'pretty'); // PRINTS TO SCREEN
              if (json_Obj[a].water_temp>0){
                noteStr+="<span class='floatRight'>Water: "+json_Obj[a].water_temp+"&deg; "+json_Obj[a].water_temp_scale+"</span>";
              }
              noteStr+="</div>";
              noteStr+="<div class='historyNotes'>"+json_Obj[a].notes+"</div>";
              //console.log(noteStr);
              $$("#note_"+json_Obj[a].setting_id).append(noteStr);
            }
            //});



          } else {
            console.log('return code is NOT 1');
            returnCode=json_Obj[0].RETURN_CODE;
          }
        }
      }, complete: function(xhr, status){
          console.log('ajax complete for viewNotes.')
          if (returnCode!=1) {
            app.methods.genericAlert("settings_history_notes",returnCode);
          }
          //console.log('status is ' + status + ' xhr is ' + xhr.responseText);
          var jsonObjLength=JSON.parse(xhr.responseText).length;
      }, // end COMPLETE
      timeout: 5000,
      error: function(json_Obj, status, err) {
        if (status == "timeout") {
            console.log("Timeout Error. " + json_Obj + status + err);
        } else {
            console.log("error: " + json_Obj + status + err);
        }
      }, // end error
        beforeSend: function(){
          console.log('ajax beforeSend.')
          } // END before Send
    });
  },










  cancelSave: function () {
    console.log("in cancelSave");
    MySettings.populateCurrentSettings();
    $$(".page .slideAdjust").css('display','none');
    $$(".page #editFinBtn").text("Edit");
    $$("#mySettingsBinding, #mySettingsLength, #mySettingsDepth, #mySettingsDFT, #myNewLE, #myNewBindToLE, #LE_diff, #B_LE_diff").html('');
    $$(".page #summary_POS").hide();
    $$("#settingsAdjuster").find(".item-inner>div").removeClass("settings_edit");
    $$("#settingsAdjuster").find(".item-inner>div").addClass("settings_default");
    //$$(".page #binding_POS").hide();
//    $$(".stockText").css("margin-left","0em");
  },












  toggleEditFin: function () {
    console.log("in toggleEditFin");

    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;
    var thisSki=Skis.thisSki;

    if ( $$(".page .slideAdjust").css('display') == 'none' )  {// Makes the settings editable
      if (app.device.android) {
        //$$(".overFlowHidden").css('width','initial');
      }



      $$(".page .slideAdjust").css('display','inline-block');

      // V2 specific code for range slider move all range sliders to center on init;
      $$(".page .range-knob-wrap").css("left","50%");

      $$(".page #editFinBtn").text("Save");
      $$("#dateCreated").html("<a href='#' id='cancelSaveBtn' class='button button-small button-fill'>Cancel</a>");
      $$(".page #summary_POS").show();

      // $$(".page #myFrontBindingType").html(Interface.getUser().front_binding_type);
      // $$(".page #myRearBindingType").html(Interface.getUser().rear_binding_type);
      //$$(".page #binding_POS").show();

      $$(".page #mySettingsBinding").html(Interface.getUser().measure_binding);
      $$(".page #mySettingsLength").html(Interface.getUser().measure_length);
      $$(".page #mySettingsDepth").html(Interface.getUser().measure_depth);
      $$(".page #mySettingsDFT").html(Interface.getUser().measure_dft);

      // BLOCKS below address cases where there are NO stock settings in the database...set to base defaults
      if (parseFloat($$("#myCurrentBinding").text() ) <= 0 ) {
        $$("#myCurrentBinding").text("29.50"); $$(".page #stockBinding").html("Estimated");
      } else {
        $$(".page #stockBinding").html("&Delta; 0");
      }


      if (parseFloat($$("#myCurrentLength").text() ) <= 0 ) {
        $$("#myCurrentLength").text("6.900"); $$(".page #stockLength").html("Estimated");
      } else {
        $$(".page #stockLength").html("&Delta; 0");
      }

      if (parseFloat($$("#myCurrentDepth").text() ) <= 0 ) {
          $$("#myCurrentDepth").text("2.500"); $$(".page #stockDepth").html("Estimated");
      } else {
        $$(".page #stockDepth").html("&Delta; 0");
      }

      if (parseFloat($$("#myCurrentDFT").text() ) <= 0 ) {
        $$("#myCurrentDFT").text("0.750"); $$(".page #stockDFT").html("Estimated");}
      else {
        $$(".page #stockDFT").html("&Delta; 0");
      }

      if (parseFloat($$("#myCurrentWingAngle").text() ) <= 0 ) {
        $$("#myCurrentWingAngle").text("7.00"); $$(".page #stockWingAngle").html("Estimated");
      } else {
        $$(".page #stockWingAngle").html("&Delta; 0");
      }

      //$$(".stockText").css("margin-left","-1em");
      $$("#settingsAdjuster").find(".item-inner>div").removeClass("settings_default");
      $$("#settingsAdjuster").find(".item-inner>div").addClass("settings_edit");

    } else { // Changes mode back to disabled and saves the settings
      $$(".page .slideAdjust").css('display','none');
      $$(".page #editFinBtn").text("Edit");
      $$(".page #summary_POS").hide();
      $$("#mySettingsBinding, #mySettingsLength, #mySettingsDepth, #mySettingsDFT").html('');

      MySettings.saveSettings();

    }

  },







  saveSettings: function(noChangeRule=true) {
    console.log("from saveSettings, noChangeRule = " + noChangeRule);
    var thisSetting=Settings.thisSetting.getSettings;
    var thisSki=Skis.thisSki;
    var settingSaved=false; // default value

          // GET ALL VALUES FROM INTERFACE
          var front_binding=$$("#myCurrentBinding").text();
          var length=$$("#myCurrentLength").text();
          var depth=$$("#myCurrentDepth").text();
          var dft=$$("#myCurrentDFT").text();
          var wing_angle=$$("#myCurrentWingAngle").text();
          var leading_edge=$$("#myNewLE").text() ? $$("#myNewLE").text() : $$(".page #myCurrentLE").text();
          var binding_to_le=$$("#myNewBindToLE").text() ? $$("#myNewBindToLE").text() : $$(".page #myCurrentBindToLE").text();
          var measure_binding = Interface.getUser().measure_binding;
          var measure_length = Interface.getUser().measure_length;
          var measure_depth = Interface.getUser().measure_depth;
          var measure_dft = Interface.getUser().measure_dft
          var date_time_created=Utility.getLocalDateTimeString(null,"ISO"); //SAVES TO SERVER
          //var date_time_created_pretty=Utility.getLocalDateTimeString(null,"pretty");


          // SEE IF ANY SETTINGS HAVE CHANGED BEFORE saving
          if (noChangeRule) {
            if (
              parseFloat(front_binding).toFixed(4)==parseFloat((typeof thisSetting.front_binding != "undefined" ? thisSetting.front_binding : MySettings.tempStockChosen.stock_binding_location)).toFixed(4) &&
              parseFloat(length).toFixed(3)==parseFloat((typeof thisSetting.length != "undefined" ? thisSetting.length : MySettings.tempStockChosen.stock_fin_length)).toFixed(3) &&
              parseFloat(depth).toFixed(3)==parseFloat((typeof thisSetting.depth != "undefined" ? thisSetting.depth : MySettings.tempStockChosen.stock_fin_depth)).toFixed(3) &&
              parseFloat(dft).toFixed(3)==parseFloat((typeof thisSetting.dft != "undefined" ? thisSetting.dft : MySettings.tempStockChosen.stock_fin_dft)).toFixed(3) &&
              parseFloat(wing_angle).toFixed(2)==parseFloat((typeof thisSetting.wing_angle != "undefined" ? thisSetting.wing_angle : MySettings.tempStockChosen.stock_wing_angle)).toFixed(2) &&
              typeof thisSetting!='undefined' // NO SETTINGS CURRENTLY SAVED
            ) {
              app.dialog.alert(
                'No settings have been changed.  Save has been canceled.',
                'No Setting Changes.',
                function () {
                  MySettings.cancelSave();
                }
              );
              return false;
            }
          }




          if (!app.methods.onLine()) return app.methods.onOffline();
          var url=app.data.wsURL+'ws_add_cur_settings_ret_json.php';
          var returnCode;
          var msg_id;

          var dataObj = {
            user_name:Interface.getUser().user_name,
            token:Interface.getUser().token,
            ski_id:thisSki.id,
            front_binding:front_binding,
            length:length,
            depth:depth,
            dft:dft,
            wing_angle:wing_angle,
            leading_edge:leading_edge,
            binding_to_le:binding_to_le,
            measure_binding:measure_binding,
            measure_length:measure_length,
            measure_depth:measure_depth,
            measure_dft:measure_dft,
            date_time_created:date_time_created,
            assigned:null, // null since this is never set by user
            assigned_by:null // null since this is never set by user
          }

        return new Promise((resolve, reject) => {
          app.request({url:url,data:dataObj,type:'POST',dataType: 'json'
          ,success:function(json_Obj) {
              console.log('ajax success.');
              if (json_Obj.length>0) { // RETURNED RESULTS
                if (json_Obj[0].RETURN_CODE==1) {
                  returnCode=1;
                  //msg_id=json_Obj[0].msg_id;
                  var idObj = {setting_id:json_Obj[0].INSERT_ID};
                  const updatedObj = Object.assign(dataObj, idObj);
                  if (Object.keys(Settings.thisSetting.getSettings).length == 0) { // if the settings object does not exist...
                    Settings.initSettings(updatedObj);
                  } else {
                    console.log('updating settings object with ...');
                    console.log(updatedObj);
                    Settings.thisSetting.setSettings=updatedObj;
                  }
                  Interface.sendNotification(json_Obj[0].msg_id);
                } else {
                  returnCode = json_Obj[0].RETURN_CODE ? json_Obj[0].RETURN_CODE : 0;
                  console.log('NOT SAVED: return code is ' + json_Obj[0].RETURN_CODE);
                }
              }
            }, complete: function(){
                if (returnCode==1) {
                  settingSaved=true;
                  console.log('ajax complete for save Settings with settingSaved=' + settingSaved);

                  MySettings.populateCurrentSettings();
                  MySettings.displaySetupNotes();

                  $$("#mySettingsBinding, #mySettingsLength, #mySettingsDepth, #mySettingsDFT, #myNewLE, #myNewBindToLE").html('');

                  $$("#settingsAdjuster").find(".item-inner>div").removeClass("settings_edit");
                  $$("#settingsAdjuster").find(".item-inner>div").addClass("settings_default");



                  // // send out notifications
                  // console.log('msg_id=',msg_id);
                  // if (msg_id) {
                  //   let notifyUrl=app.data.wsURL+'notifications/sendNotifications.php';
                  //   var dataObj = {
                  //     user_name:Interface.getUser().user_name,
                  //     token:Interface.getUser().token,
                  //     msg_id:msg_id,
                  //   }
                  //   app.request.post(
                  //     notifyUrl,
                  //     dataObj,
                  //     function (data) {
                  //       console.log("returned data..." ,data);
                  //     },
                  //     function (data) {
                  //       console.log("ERRROR..." ,data);
                  //     },
                  //     'json'
                  //   );
                  // }


                  resolve(settingSaved);
                } else {
                  app.methods.genericAlert("add_setting",returnCode);
                }
            }, // end COMPLETE
            timeout: 5000,
            error: function(json_Obj, status, err) {
              if (status == "timeout") {
                  console.log("Timeout Error. " + json_Obj + status + err);
              } else {
                  console.log("error: " + json_Obj + status + err);
              }
              reject(err);
            }, // end error
              beforeSend: function(){
                console.log('ajax beforeSend.')
                console.log('user_name='+Interface.getUser().user_name);
                } // END before Send
          }); // END SAVE via AJAX
        });
  },







  init_ranges: function () {
    console.log("in MySettings.init_ranges");

    //var thisSetting=Settings.thisSetting.getSettings;
    //var thisSetting=Settings.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;
    console.log(thisSetting);

    var thisBND, thisLEN, thisDEP, thisDFT, thisWIN, b_range, l_range, d_range, dft_range, w_range, bnd_diff,len_diff, dpt_diff, dft_diff, wa_diff;
    if (Object.keys(thisSetting).length>0  && thisSetting.hasOwnProperty("front_binding") ) {
      thisBND=thisSetting.front_binding;
    } else {
      thisBND=MySettings.tempStockChosen.stock_binding_location;
    }
    if (typeof b_range !== 'undefined') { app.range.destroy(b_range); }



    if (Object.keys(thisSetting).length>0 && thisSetting.hasOwnProperty("length") ) {
      thisLEN=thisSetting.length;
    } else {
      thisLEN=MySettings.tempStockChosen.stock_fin_length;
    }
    if (typeof l_range !== 'undefined') { app.range.destroy(l_range); }



    if (Object.keys(thisSetting).length>0 && thisSetting.hasOwnProperty("depth") ) {
      thisDEP=thisSetting.depth;
    } else {
      thisDEP=MySettings.tempStockChosen.stock_fin_depth;
    }
    if (typeof d_range !== 'undefined') { app.range.destroy(d_range); }



    if (Object.keys(thisSetting).length>0 && thisSetting.hasOwnProperty("dft") ) {
      thisDFT=thisSetting.dft;
    } else {
      thisDFT=MySettings.tempStockChosen.stock_fin_dft;
    }
    if (typeof dft_range !== 'undefined') { app.range.destroy(dft_range); }



    if (Object.keys(thisSetting).length>0 && thisSetting.hasOwnProperty("wing_angle") ) {
      thisWIN=thisSetting.wing_angle;
    } else {
      thisWIN=MySettings.tempStockChosen.stock_wing_angle;
    }
    if (typeof w_range !== 'undefined') { app.range.destroy(w_range); }



    b_range = app.range.create({
      el: '#bindingRange',
      dual:false,
      value:Number(thisBND),
      min: Number((Number(thisBND) - Number(.75)).toFixed(4)),
      max: Number((Number(thisBND) + Number(.75)).toFixed(4)),
      step:.0625,
      on: {
        change: function () {
          console.log('Range Slider value changed for binding ' +this.value );
          $$("#myCurrentBinding").text(this.value.toFixed(4));
          bnd_diff=(Object.keys(thisSetting).length !== 0) ? (this.value - Number(thisSetting.front_binding)).toFixed(4) : (this.value - Number(MySettings.tempStockChosen.stock_binding_location)).toFixed(4);
          bnd_diff = (bnd_diff>0) ? "+"+bnd_diff : bnd_diff;
          $$("#stockBinding").html('&Delta; ' + bnd_diff);
          MySettings.calculateBindingToLE();
        }
      }
    });



    l_range = app.range.create({
      el: '#lengthRange',
      dual:false,
      value:Number(thisLEN),
      min: Number((Number(thisLEN) - Number(.012)).toFixed(3)),
      max: Number((Number(thisLEN) + Number(.012)).toFixed(3)),
      step:.001,
      on: {
        change: function () {
          console.log('Range Slider value changed for length');
          $$("#myCurrentLength").text(this.value.toFixed(3));
          len_diff=(Object.keys(thisSetting).length !== 0) ? (this.value - Number(thisSetting.length)).toFixed(3) : (this.value - Number(MySettings.tempStockChosen.stock_fin_length)).toFixed(3);
          len_diff = (len_diff>0) ? "+"+len_diff : len_diff;
          $$("#stockLength").html('&Delta; ' + len_diff );
          MySettings.calculateLE();
          MySettings.calculateBindingToLE();
        }
      }
    });



    d_range = app.range.create({
      el: '#depthRange',
      dual:false,
      value:Number(thisDEP),
      min: Number((Number(thisDEP) - Number(.012)).toFixed(3)),
      max: Number((Number(thisDEP) + Number(.012)).toFixed(3)),
      step:.001,
      on: {
        change: function () {
          console.log('Range Slider value changed for depth');
          $$("#myCurrentDepth").text(this.value.toFixed(3));
          dpt_diff=(Object.keys(thisSetting).length !== 0) ? (this.value - Number(thisSetting.depth)).toFixed(3) : (this.value - Number(MySettings.tempStockChosen.stock_fin_depth)).toFixed(3);
          dpt_diff = (dpt_diff>0) ? "+"+dpt_diff : dpt_diff;
          $$("#stockDepth").html('&Delta; ' + dpt_diff );
        }
      }
    });



    dft_range = app.range.create({
      el: '#dftRange',
      dual:false,
      value:Number(thisDFT),
      min: Number((Number(thisDFT) - Number(.012)).toFixed(3)),
      max: Number((Number(thisDFT) + Number(.012)).toFixed(3)),
      step:.001,
      on: {
        change: function () {
          console.log('Range Slider value changed for dft');
          $$("#myCurrentDFT").text(this.value.toFixed(3));
          dft_diff=(Object.keys(thisSetting).length !== 0) ? (this.value - Number(thisSetting.dft)).toFixed(3) : (this.value - Number(MySettings.tempStockChosen.stock_fin_dft)).toFixed(3);
          dft_diff = (dft_diff>0) ? "+"+dft_diff : dft_diff;
          $$("#stockDFT").html('&Delta; ' + dft_diff );
          MySettings.calculateLE();
          MySettings.calculateBindingToLE();
        }
      }
    });



    w_range = app.range.create({
      el: '#wingRange',
      dual:false,
      value:Number(thisWIN),
      min: Number((Number(thisWIN) - Number(2)).toFixed(2)),
      max: Number((Number(thisWIN) + Number(2)).toFixed(2)),
      step:.25,
      on: {
        change: function () {
          console.log('Range Slider value changed for wing angle');
          $$("#myCurrentWingAngle").text(this.value.toFixed(2));
          wa_diff=(Object.keys(thisSetting).length !== 0) ? (this.value - Number(thisSetting.wing_angle)).toFixed(2) : (this.value - Number(MySettings.tempStockChosen.stock_wing_angle)).toFixed(2);
          wa_diff = (wa_diff>0) ? "+"+wa_diff : wa_diff;
          $$("#stockWingAngle").html('&Delta; ' + wa_diff );
        }
      }
    });



    // THIS IS A FIX/OVERRIDE FOR SMALL RANGE KNOB IN ANDROID
    if (app.device.android) {
      $$(".page .range-knob-wrap > .range-knob").addClass('androidSliderRangeKnob');
    }

  },










  // INITIALIZE slider
  init_slider: function () {
    console.log('in init_slider');
    MySettings.toggleEditFin();
    MySettings.init_ranges();
  },











  // UPDATES RANGE SLIDER AFTER touchend event, so that it can continually be dragged to adjust settings in small/configured increments
  updateSlider: function (obj,currentValue,plusMinusRange,increment) {
    console.log("updateSlider currentValue is "+currentValue);

    //console.log("obj="+obj);
    let minVal=Number(currentValue) - Number(plusMinusRange);
    let maxVal=Number(currentValue) + Number(plusMinusRange);
    obj.value=Number(Number(currentValue).toFixed(4));
    obj.min=Number(minVal.toFixed(4));
    obj.max=Number(maxVal.toFixed(4));
    obj.step=increment;
    console.log("obj.currentValue value = "+obj.value+ " with typeof " + typeof obj.value);
    console.log('obj.min value = ' + obj.min+ " with typeof " + typeof obj.value);
    console.log("obj.max value = "+obj.max+ " with typeof " + typeof obj.value);
    console.log("obj.step value = "+obj.step+ " with typeof " + typeof obj.step);
  },









  calculateLE: function (change) {
    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;

    var length =parseFloat($$(".page #myCurrentLength").text());
    var dft =parseFloat($$(".page #myCurrentDFT").text());
    var LE = (length+dft).toFixed(3);
    console.log("LE = " + LE);
    // get leading edge if in settings or from stock ski settings
    if (Object.keys(thisSetting).length>0) {
      var thisLE=Number(thisSetting.leading_edge).toFixed(3);
    } else {
      var thisLE=(Number(MySettings.tempStockChosen.stock_fin_dft) + Number(MySettings.tempStockChosen.stock_fin_length)).toFixed(3);
    }

    var LE_diff=(LE-thisLE).toFixed(3);
    console.log("LE_diff = " + LE_diff);

    if (LE_diff==0) {
      LE_diff=" Neutral";
    } else {
      if (LE_diff>0) LE_diff=LE_diff.replace( /^0+/,"") + " Forward";
      else LE_diff=LE_diff.replace( /^-0+/,"") + " Backward";
    }

    $$(".page #myNewLE").text(LE);
    $$(".page #LE_diff").text(LE_diff);
  },









  calculateBindingToLE: function (change) {
    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;

    var binding = parseFloat($$(".page #myCurrentBinding").text());
    console.log('typeof binding=' + typeof binding);
    var length = parseFloat($$(".page #myCurrentLength").text());
    var dft = parseFloat($$(".page #myCurrentDFT").text());
    var BindingToLE = Number((binding-(length+dft)).toFixed(4));

    console.log('typeof BindingToLE=' + typeof BindingToLE);
    console.log("BindingToLE = " + BindingToLE);

    // get binding to leading edge if in settings or from stock ski settings
    if (Object.keys(thisSetting).length>0) {
      var this_B_LE=Number(thisSetting.binding_to_le).toFixed(4);
    } else {
      var this_B_LE=(Number(MySettings.tempStockChosen.stock_binding_location) - (Number(MySettings.tempStockChosen.stock_fin_dft) + Number(MySettings.tempStockChosen.stock_fin_length))).toFixed(4);
    }

    var B_LE_diff=(BindingToLE-this_B_LE).toFixed(4);
    if (B_LE_diff==0) {
      B_LE_diff=" Neutral";
    } else {
      if (B_LE_diff>0) B_LE_diff = B_LE_diff.replace( /^0+/,"") + " Longer";
      else B_LE_diff = B_LE_diff.replace( /^-0+/,"") +  " Shorter";
    }

    $$(".page #myNewBindToLE").text(BindingToLE);
    $$(".page #B_LE_diff").text(B_LE_diff);
  },






  // resolveAddNoteSettings: function () {
  //   MySettings.saveSettings(false)
  //     .then((message) => {
  //       if (message) {
  //         console.log("save successful, now triggering add a note...message = " + message);
  //         Interface.interface_addNotePopup();
  //       } else {
  //         console.log("save failed...can not edit notes...message = " + message);
  //       }
  //     }).catch((err) => {
  //       console.log("in catch with message = " + err);
  //     });
  // },





  // popup screen for user to add notes on existing settings
  AddNotePopUp: function () {
  	console.log('AddNotePopUp function!!!');
    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;

    var thisSki=Skis.thisSki;

            if (Object.keys(thisSetting).length == 0) { // IF user has no settings saved for the current ski

              app.dialog.create({
                title: "No Saved Settings",
                text: "You don't have any settings saved to attach a note to.  Would you like to save these settings?",
                buttons: [
                  {
                    text: 'Cancel',
                    onClick: function() {
                      console.log("cancel clicked");
                      return null;
                    }
                  },
                  {
                    text: 'Save',
                    onClick: function() {
                      console.log("save clicked");
                      MySettings.saveSettings(false)
                        .then((message) => {
                          if (message) {
                            console.log("save successful, now triggering add a note...message = " + message);
                            Interface.interface_addNotePopup();// for some reason we can't call AddNotePopup directly from here, so calling it from the outside
                          } else {
                            console.log("save failed...can not edit notes...message = " + message);
                          }
                        }).catch((err) => {
                          console.log("in catch with message = " + err);
                        });
                      //MySettings.resolveAddNoteSettings();
                    }
                  },
                ],
                verticalButtons: false,
              }).open();
          //  });
              // app.dialog.alert(
              //   'After you edit and save your first settings, you will be able to add notes here.',
              //   'No Saved Settings.',
              //   function () {
              //     null;
              //   }
              // );
              return null;
            }

            // noChangeRule



        var popupNote = '<div class="popup popup-notes" id="notesPopup">';
            popupNote +='<div class="view view-init">';
            popupNote +='<div class="page" data-page="menu-add-note">';

            popupNote+='<div class="navbar">';
            popupNote+='<div class="navbar-inner">';
            popupNote+='<div class="title">Setting Notes</div>';
            popupNote+='<div class="right"><a href="#" class="link popup-close">Close</a></div>';
            popupNote+='</div>';
            popupNote+='</div>';

            popupNote+='<div class="page-content">';
            popupNote+='<div class="block">';

            if (thisSki.my_ski_name) {
              popupNote+='<div class="content-block bold">'+thisSki.my_ski_name+'</div>';
            } else {
              popupNote+='<div class="content-block bold">'+thisSki.length + " " + thisSki.brand + " " + thisSki.model + " " + thisSki.year+'</div>';
            }

            popupNote+='<div class="list" id="addNoteSettingsTable">';
            popupNote+='<div class="data-table">';
            popupNote+='<table>';
            popupNote+='<thead>';
            popupNote+='<tr>';
            popupNote+='<th class="numeric-cell">Binding</th>';
            popupNote+='<th class="numeric-cell">Length</th>';
            popupNote+='<th class="numeric-cell">Depth</th>';
            popupNote+='<th class="numeric-cell">DFT</th>';
            popupNote+='<th class="numeric-cell">Wing</th>';
            popupNote+='</tr>';
            popupNote+='</thead>';
            popupNote+='<tbody>';
            popupNote+='<tr>';
            popupNote+='<td class="numeric-cell">' +thisSetting.front_binding+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.length+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.depth+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.dft+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.wing_angle+ '</td>';
            popupNote+='</tr>';
            popupNote+='<tr>';
            popupNote+='<td class="numeric-cell">' +thisSetting.measure_binding+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.measure_length+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.measure_depth+ '</td>';
            popupNote+='<td class="numeric-cell">' +thisSetting.measure_dft+ '</td>';
            popupNote+='<td class="numeric-cell"></td>';
            popupNote+='</tr>';
            popupNote+='</tbody>';
            popupNote+='</table>';
            popupNote+='</div>'; // end data-table
            popupNote+='</div>'; // end list

            popupNote+='<div class="list simple-list">';
            popupNote+='<ul>';
            popupNote+='<li class="txtAreaHeight">';
            popupNote+='<div class="item-cell width-auto flex-shrink-0">';
            popupNote+='<i class="icon f7-icons">compose</i>';
            popupNote+='</div>';
            //popupNote+='<div class="item-title label">Notes</div>';
            popupNote+='<div class="item-cell flex-shrink-3">';
            popupNote+='<textarea name="notes" id="notes" placeholder="Add notes..."></textarea>';
            popupNote+='</div>';

            popupNote+='</li>';

            popupNote+='<li class="item-row">';
            popupNote+='<div class="item-cell width-auto flex-shrink-0">';
            popupNote+='<span id="note_water_temp"><img src="./static/img/water_temp3.png" width="24px" heigh="24px" /></span><span id="note_water_deg"></span><span id="notes_scale"></span>';
            popupNote+='</div>';
            popupNote+='<div class="item-cell flex-shrink-3">';
            //popupNote+='<div class="item-title label">Notes</div>';
            //popupNote+='<div class="item-input">';
            if (Interface.getUser().measure_water_temp=="C") {
              popupNote+='<div id="note_temp_slider" class="range-slider" data-label="true"><input type="range" min="15" max="37" value="28" step="1"></div>';
            } else {
              popupNote+='<div id="note_temp_slider" class="range-slider" data-label="true"><input type="range" min="60" max="99" value="75" step="1"></div>';
            }
            popupNote+='</div>';

            popupNote+='</li>';
            popupNote+='</ul>';
            popupNote+='</div>'; // END LIST

            popupNote+='<div class="content-block"><a href="#" class="inlineBlock whiteTransBG button margin-right_1em" id="addNoteBtn">Add Note</a></div>';

            popupNote+='</div>'; // END  block
            popupNote+='</div>'; // END  page content

            popupNote+='</div>'; // end class page
            popupNote+='</div>'; // end class view
            popupNote+='</div>'; // end class popup

        app.popup.open(popupNote,true);

        var rangeWaterTemp = app.range.create({
          el: '#note_temp_slider',
          on: {
            change: function (e, range) {
              $$('#note_water_temp').html(this.getValue() + '&deg;' +Interface.getUser().measure_water_temp);
            }
          }
        });

  }, // END AddNote FUNCTION









  addNote: function () {
    console.log('AddNote function');
    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;
    var thisSki=Skis.thisSki;

    if (MySettings.newAnim.length>0) MySettings.newAnim.stop();// THIS seems to be a fix for when the animation does not go to completion...happened several times.

    $$('#thisSettingNotes').removeAttr("style");
    if (!app.methods.onLine()) return app.methods.onOffline();

    var notes=$$(".popup #notes").val();
    notes = notes.replace(/(\r\n|\n|\r)/g,'<br>'); // replace line returns with br tags...make sure br tags are allowed in php when stripping
    var date_time=Utility.getLocalDateTimeString(null,"ISO"); //SAVES TO SERVER
    var water_temp=$$(".popup #note_water_temp").text();
    var passed=false;

    var url=app.data.wsURL+'ws_set_settings_note_ret_json.php';

    app.request({url:url,data:{user_name:Interface.getUser().user_name,token:(Interface.getUser().token),ski_id:thisSki.id,setting_id:thisSetting.setting_id,notes:notes,water_temp:water_temp,water_temp_scale:Interface.getUser().measure_water_temp,date_time:date_time },type:'POST',dataType: 'json'
    ,success:function(json_Obj) {
        console.log('ajax success.');
        if (json_Obj.length>0) { // RETURNED RESULTS
        //  console.log('json length is ' + json_Obj.length);
          if (json_Obj[0].RETURN_CODE==1) {
            //console.log('json_Obj is ' + json_Obj);
            passed=true;
          } else {
            console.log('return code is NOT 1');
          }
        }
      }, complete: function(xhr, status){
          console.log('ajax complete for addNote.')
          console.log('status is ' + status);
          if (passed) {
            //app.closeModal();
            let thisPop=app.popup.close("div.popup.popup-notes");
            MySettings.displaySetupNotes();
            //console.log("thisPop is " + thisPop);
          } else { // no settings saved
            app.dialog.alert(
              'Please add notes and/or select a water temperature to save a note.  Otherwise, please select the Close button.',
              'Notes not Saved',
              function () {
                return null;
              }
            );
          }
      }, // end COMPLETE
      timeout: 5000,
      error: function(json_Obj, status, err) {
        if (status == "timeout") {
            console.log("Timeout Error. " + json_Obj + status + err);
        } else {
            console.log("error: " + json_Obj + status + err);
        }
      }, // end error
        beforeSend: function(){
          console.log('ajax beforeSend.')
        } // END before Send
    });
  },











  versionDetails: function (upd_vers_Obj) {
    console.log("in versionDetails function");
    var popupNote = '<div class="popup popup-notes">';
        popupNote +='<div class="view view-init">';
        popupNote +='<div class="page">';

        popupNote+='<div class="navbar">';
        popupNote+='<div class="navbar-inner">';
        popupNote+='<div class="title">Version Update</div>';
        popupNote+='<div class="right"><a href="#" class="link popup-close">Close</a></div>';
        popupNote+='</div>';
        popupNote+='</div>';

        popupNote+='<div class="page-content">';
        popupNote+='<div class="block">';

        if (upd_vers_Obj.user_vers) {
          popupNote+='<div class="content-block bold">Your Version: '+upd_vers_Obj.user_vers+'</div>';
        }
        if (upd_vers_Obj.latest_vers) {
          popupNote+='<div class="content-block bold">Latest Version: '+upd_vers_Obj.latest_vers+'</div>';
        }

        popupNote+='<div>New or upgraded features:</div>';
        if (upd_vers_Obj.vers_features) {
          popupNote+='<div>'+upd_vers_Obj.vers_features+'</div>';
        }

        if (app.device.ios) {
          var update_link="<a class='external' href='"+app.data.appLinkiOS+"' target='_system'>Learn More</a>";
        }
        if (app.device.android) {
          var update_link="<a class='external' href='"+app.data.appLinkAndroid+"' target='_system'>Learn More</a>";
        }
        popupNote+='<div>'+update_link+'</div>';





        popupNote+='</div>'; // END  block
        popupNote+='</div>'; // END  page content

        popupNote+='</div>'; // end class page
        popupNote+='</div>'; // end class view
        popupNote+='</div>'; // end class popup

    app.popup.open(popupNote,true);
  },







  displaySetupNotes: function () {
    console.log("in displaySetupNotes ");

    if (
        (typeof Settings.thisSetting == 'undefined' || Object.keys(Settings.thisSetting).length == 0)
        ||
        (typeof Skis.thisSki == 'undefined' || Object.keys(Skis.thisSki).length == 0)
      ) {
      return false;
    }

    //var thisSetting=app.data.thisSetting;
    var thisSetting=Settings.thisSetting.getSettings;
    var thisSki=Skis.thisSki;

    $$('#thisSettingNotes').css('height','0px');
    $$('#thisSettingNotes').css('opacity','0');
    $$('#thisSettingNotes').removeAttr("style");
    $$('#settingNotesDiv').html("");
    //$$('#settingNotesDiv').remove();

    if (!app.methods.onLine()) return app.methods.onOffline();

    if (Object.keys(thisSetting).length == 0 && Object.keys(thisSki).length == 0) {
      return false;
    } else if (!thisSki.id || !thisSetting.setting_id) { // if the ski and setting are not set, do not execute
      return false;
    }

    var noteStr='';
    var returnCode=-1;
    var url=app.data.wsURL+'ws_get_settings_history_notes_ret_json.php';

    app.request({url:url,data:{ user_name:Interface.getUser().user_name, token:Interface.getUser().token, ski_id:thisSki.id, setting_id:thisSetting.setting_id },type:'POST',dataType: 'json'
    ,success:function(json_Obj) {
        console.log('ajax success.');
        if (json_Obj.length>0) { // RETURNED RESULTS
          //console.log('json length is ' + json_Obj.length);

          if (json_Obj[0].RETURN_CODE==1) {
            returnCode=1;
            //console.log('json_Obj is ' + json_Obj);

            //noteStr+="<div id='settingNotesDiv'>";
            for (let a=0;a<json_Obj.length;a++) {
              //var noteStr='';
              noteStr+="<div class='historyNotesHdr'>"+Utility.getLocalDateTimeString(json_Obj[a].date_time,'pretty');// PRINTS TO SCREEN
              if (json_Obj[a].water_temp>0){
                noteStr+="<span class='floatRight'>Water: "+json_Obj[a].water_temp+"&deg; "+json_Obj[a].water_temp_scale+"</span>";
              }
              noteStr+="</div>";
              noteStr+="<div class='historyNotes'>"+json_Obj[a].notes+"</div>";
              //console.log(noteStr);
              //$$("#thisSettingNotes").append(noteStr);
            }
            //noteStr+="</div>";
            //console.log(noteStr);

          } else {
            console.log('return code is NOT 1 so hide the div for notes.');
            returnCode=json_Obj[0].RETURN_CODE;
            $$('#thisSettingNotes').css('display','none');
          }
        }
      }, complete: function(xhr, status){
        console.log('in complete ...');
        if (returnCode==1) {

          $$('#thisSettingNotes').css('height','0px');

          $$('#settingNotesDiv').html(noteStr);
          var notesHeight=$$("#settingNotesDiv").height();

          var winHeight=$$("#mySettings_div").height();
          var skiCardTop=document.getElementById("ski_card").offsetTop;
          var skiCardHeight=$$("#ski_card").height();
          var spaceLeft=winHeight-skiCardTop-skiCardHeight-34;

          console.log('spaceLeft=' +spaceLeft);



          if (spaceLeft>60) { // IF THERE IS ENOUGH SPACE TO DISPLAY NOTES AND KEEP SKI CARD DIAGRAM, THEN SHOW NOTES ON THIS PAGE
            console.log('in spaceleft>60 and = ' + spaceLeft);

            $$('#thisSettingNotes').addClass('notesGradientBG');
            $$('#thisSettingNotes').css('display','inline-block');
            $$('#thisSettingNotes').css('border-top','1px solid #444');
            $$('#thisSettingNotes').css('border-bottom','2px solid #444');

            var thisAnim = $$('#thisSettingNotes').animate(
              {
                  'height': spaceLeft,
                  'opacity': 1
              },
              {
                  duration: 700,
                  easing: 'swing',
                  begin: function () {
                      console.log('********** animation began');
                      //$$('#settingNotesDiv').html(noteStr);
                      //$$('#thisSettingNotes').html(noteStr);
                  },
                  // Animation completed, optional
                  complete: function () {
                      console.log('********** animation completed');
                      //$$('#thisSettingNotes').removeAttr("style");
                      //$$('#thisSettingNotes').css('height','auto');
                      thisAnim.stop();
                      if (notesHeight<spaceLeft) {
                        console.log('calling bounceBack with value='+notesHeight);
                        MySettings.bounceBack(notesHeight);
                      }
                      //notesHeight=$$("#settingNotesDiv").height();
                  }
              }
            ) ; // END ANIMATION 1

          }
        }

        if (returnCode != 1 && returnCode != -1) { // has notes or no notes...IE no errors in DB call
          app.methods.genericAlert("settings_history_notes",returnCode);
        }

      }, // end COMPLETE
      timeout: 5000,
      error: function(json_Obj, status, err) {
        if (status == "timeout") {
            console.log("Timeout Error. " + json_Obj + status + err);
        } else {
            console.log("error: " + json_Obj + status + err);
        }
      }, // end error
        beforeSend: function(){
          console.log('ajax beforeSend.')
          } // END before Send
    });
  },














  bounceBack: function (newHeight) {
    MySettings.newAnim=$$('#thisSettingNotes').animate(

      {
          'height': newHeight,
          'opacity': 1
      },
      {
          duration: 350,
          easing: 'swing',
          begin: function () {
              console.log('animation2 began');
              console.log(MySettings.newAnim);
          },
          // Animation completed, optional
          complete: function () {
              console.log('animation2 completed');
              MySettings.newAnim.stop();
              console.log(MySettings.newAnim);
          },
          // Animation in progress, optional
          progress: function (elements, complete, remaining, start) {

          }
      }
    ); // END ANIMATION
  },







  finshare_prompt: function() {
    if (!Interface.getUser().fin_share) {
      $$("#stockSettingsDiv").html(Skis.thisSki.ski_count + " other members riding this ski. <a href='/finShare/'>LEARN MORE.</a>");
      $$("#stockSettingsDiv").css({'opacity':1, 'color':'#555', 'font-size':'.8em', 'text-align':'center', 'padding-top':'.3em'});

    } else {
      return false;
    }
  },




}
export default MySettings;
