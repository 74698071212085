import $$ from 'dom7';


import Users from '../js_my/user_class.js';
import Skis from '../js_my/ski_class.js';
import Settings from '../js_my/settings_class.js';
import MySkis from '../js_my/my_skis.js';
import MySettings from '../js_my/my_settings.js';
import SkiLookup from '../js_my/ski_settings_smart_select.js';
import Utility from '../js_my/utility.js';
import FirstEntry from '../js_my/first_entry.js';
import pushNotify from '../js_my/pushNotify.js';
//import Settings from '../js_my/settings_class.js';


var Interface = {

  //thisUser:Users.getProfile(),

  interface_mySettings_updateSlider: function (e,id) {
    //console.log("interface_updateSlider with this.id=" + id);
    //console.log(e);
    var rangeObj = app.range.get('#' + id);
    switch (id) {
      case 'bindingRange':
        MySettings.updateSlider(rangeObj,rangeObj.value.toFixed(4),.750,.0625);
        //console.log("------------> touch ended with b_range current value = " +Number(b_range.value.toFixed(4))+ " typeof = " + typeof Number(b_range.value.toFixed(4)) );
        break;

      case 'lengthRange':
        MySettings.updateSlider(rangeObj,rangeObj.value.toFixed(3),.012,.001);
        break;

      case 'depthRange':
        MySettings.updateSlider(rangeObj,rangeObj.value.toFixed(3),.012,.001);
        break;

      case 'dftRange':
        MySettings.updateSlider(rangeObj,rangeObj.value.toFixed(3),.012,.001);
        break;

      case 'wingRange':
        MySettings.updateSlider(rangeObj,rangeObj.value.toFixed(2),2,.25);
        break;
      }
      // re-center the slider knob
      $$("#" +id+ " .range-knob-wrap").css("left","50%");
  },



  interface_promptSkiName: function (year) {
    var skiName=app.data.lookup.skiYear + ' ' +app.data.lookup.skiBrand+ ' ' +app.data.lookup.skiModel+ ' ' +parseFloat(app.data.lookup.skiLength) + '"';
    app.dialog.create({
      title: skiName,
      text: 'will be the name of your ski.  You can change it by entering a new name below.<div class="input-field"><input type="text" name="newSkiName" id="newSkiName" placeholder="Change Ski Name" value="" /></div>',
      buttons: [
        {
          text: 'Save',
          onClick: function() {
            var newSkiName=$$("#newSkiName").val();
            if (newSkiName.length>0) {
              Interface.interface_defaultToCurrentSki(year,newSkiName);
            } else {
              Interface.interface_defaultToCurrentSki(year,skiName);
            }
          }
        }
      ]
    }).open();
  },



  interface_defaultToCurrentSki: function (year,skiName) {

    //if (typeof app.data.thisSki !== "undefined") { // IF THIS IS THE USERS FIRST SKI ENTRY
    if (typeof Skis.thisSki !== "undefined") { // IF THIS IS THE USERS FIRST SKI ENTRY
      console.log('in defaultToCurrentSki and thisSki IS defined');
      app.dialog.create({
        title:  'Current Ski?',
        text: 'Do you want this to be your current ski?',
        buttons: [
          {
            text: 'Yes',
            onClick: function() {
              Interface.interface_insertSki(year,skiName,1);
            }
          },
          {
            text: 'No',
            onClick: function() {
              Interface.interface_insertSki(year,skiName,0);
            }
          }
        ]
      }).open();
    } else {
      Interface.interface_insertSki(year,skiName,1); // insert the ski as the default ski since it's the first one
    }
  },






  interface_addNotePopup : function() {
    MySettings.AddNotePopUp();
  },






  interface_insertSki: function (nullYear,my_ski_name,current) {
    console.log('running insertSki function');
    if (!app.methods.onLine()) return app.methods.onOffline();

    // NOTE ...THIS SHOULD BE CLEANED UP>... app.data.lookup.skiYear should be set to a default of null and only changed if ski has a year.
    var theYear;
    if (nullYear == null) {
      theYear=null;
    } else {
      theYear=app.data.lookup.skiYear
    }
    var date_time_created=Utility.getLocalDateTimeString(null,"ISO"); //SAVES TO SERVER

    var skiObj = {
      user_name:Interface.getUser().user_name,
      token:Interface.getUser().token,
      my_ski_name:my_ski_name,
      current:current,
      brand:app.data.lookup.skiBrand,
      model:app.data.lookup.skiModel,
      length:parseFloat(app.data.lookup.skiLength).toString(),
      year:theYear,
      date_time_created:date_time_created
    }


    //console.log(savedSki);
    MySkis.saveSki(skiObj)
      .then((savedSki) => {
        if (savedSki==1) {
          if (current==1) {
            console.log('complete and current is 1');
            Skis.getCurrentSki({user_name:Interface.getUser().user_name, token:Interface.getUser().token, ski_id:null});

          } else {
            console.log('complete and current is NOT 1');
            $$(".page #saved_ski_list").remove();
            Interface.interface_closeAddSkiSelect();
            MySkis.getMySkis(Interface.getUser().user_name);
          }
        } else {
          app.methods.genericAlert("interface_insertSki",savedSki);
        }
      }).catch((error) => {
        console.log(error);
      });



    // PS TODO need to reset the app.data.lookup fields after saved.

    //
    // var url=app.data.wsURL+'ws_add_ski_ret_json.php';
    //
    // app.request({url:url,data:{ user_name:Interface.getUser().user_name,my_ski_name:my_ski_name,current:current,brand:app.data.lookup.skiBrand,model:app.data.lookup.skiModel,length:app.data.lookup.skiLength,year:theYear},type:'POST',dataType: 'json',success:function(jsonObj) {
    //   if (jsonObj[0].RETURN_CODE==1) {
    //     console.log('return code 1...success')
    //   } else {
    //     console.log('return code NOT 1...no luck')
    //   }
    // }, timeout: 5000
    //   , beforeSend: function() {
    //     console.log('beforeSend insertSki');
    //
    //     app.preloader.show();
    //
    //   }, complete: function() {
    //
    //       console.log('in complete insertSki');
    //       app.preloader.hide();
    //       // NOW REFRESH INTERFACE, but mySki list and mySettings page.
    //       if (current==1) {
    //         console.log('complete and current is 1');
    //         //Skis.getCurrentSki(Interface.getUser().user_name);
    //         Skis.getCurrentSki({user_name:Interface.getUser().user_name, ski_id:null});
    //
    //       } else {
    //         console.log('complete and current is NOT 1');
    //         $$(".page #saved_ski_list").remove();
    //         MySkis.getMySkis(Interface.getUser().user_name);
    //         Interface.interface_closeAddSkiSelect();
    //       }
    //   }, error: function(jsonObj, status, err) {
    //       if (status == "timeout") {
    //         console.log("Timeout Error. " + jsonObj + status + err);
    //       } else {
    //         console.log("error: "  + status + err);
    //       }
    //   }
    // }) // END ajax function for models


 /* ABOVE CODE SHOULD BE IN SK CLASS */

  },




  interface_closeAddSkiSelect: function () {
    console.log('closeAddSkiSelect triggered');
    document.getElementById("add_ski_div").setAttribute("style", "height:220px")
    console.log('add_ski_div height is ' + document.getElementById('add_ski_div').style.height );
    $$('.page #add_ski_div').attr('style','opacity:0;height:0px;margin-top:0px').transition(750);

    $$('#add_ski_div').transitionEnd(function(){
      console.log('add_ski_div TRANSITION ENDED.......');
      $$('.page #ul_stock_list_add').remove();
    });
  },




  getUser: function () {
    //return Users.getProfile();
    return Users.thisUser.getUser;
  },




  getSki: function () {
    //return Users.getProfile();
    return Skis.thisSki.getSki;
  },



  getSettings: function () {
    //return Users.getProfile();
    return Settings.thisSetting.getSettings;
  },


  updateProfile: function (obj) {
    console.log('In interface.setUser with obj=');
    console.log(obj);
    return Users.setProfile(obj);
    //return Users.thisUser.setUser=obj;
  },



  setSki: function (obj) {
    return Skis.setCurrentSki(obj);
  },



  setSettings: function (obj) {
    //return Settings.setCurrentSettings(obj);
    return Settings.getCurrentSettings(obj);
  },




  sendNotification: function(msg_id) {
    if (msg_id) {
      let notifyUrl=app.data.wsURL+'notifications/sendNotifications.php';
      var dataObj = {
        user_name:Interface.getUser().user_name,
        token:Interface.getUser().token,
        msg_id:msg_id
      }
      app.request.post(
        notifyUrl,
        dataObj,
        function (data) {
          console.log("returned data..." ,data);
        },
        function (data) {
          console.log("ERRROR..." ,data);
        },
        'json'
      );
    }
  },





/* Routes user after running the user_class.js retrieveProfile function */
  route_retrieveProfile(returnCode) {
    if (returnCode==1) { // LOGIN SUCCESSFUL
      if (Users.thisUser.validProfile(Users.thisUser)) { // ALL REQUIRED PROFILE DATA IS PRESENT TO ALLOW USER TO SET UP SKIS
        app.emit('finShareReqCountChange', null);
        Skis.getCurrentSki({user_name:Interface.getUser().user_name, token:Interface.getUser().token, ski_id:null});

        if (app.data.isCordova) {
          if (app.device.ios) {
            if (Interface.getUser().fcm_reg_id) {
              console.log("has fcm_reg_id");
              pushNotify.init();
              // ********************                                                     ******************** //
              // ******************** check for Push Notification registration id updates ******************** //
              // ********************                                                     ******************** //
              // this returns true on condition that user has BOTH previously accepted notifications at the OS LEVEL, AND notifications are currently enabled.  If not previously accepted, will return false.
              // This should fire once on every log-in, which is recommended by the plugin.
              // FOR THE CASE OF A 'first entry' for a user, they will get prompted at the end of the Q/A on setup....not here.  We will also prompt with an in-app message for notifications before we fire the OS level notification acceptance prompt.
              // The main purpose of this is to verify the FCM registration ID has not changed, and if it has, it will be updated in the system.
              pushNotify.isPushEnabled()
                  .then(data => { // returns true
                      console.log("in then...fin_share.js push is enabled " + data);
                      //pushNotify.init();
                  })
                  .catch(error => { // returns false ... DO NOT INIT BECAUSE IT WILL PROMPT USER AT OS LEVEL WHICH I DON'T WANT TO DO WITHOUT MY OWN MODAL IN FRONT OF IT ... SINCE YOU ONLY GET ONE CHANCE WITH IOS.
                    console.log ("in catch...fin_share.js push is NOT enabled " + error);
                    // ideally we should prompt user to turn on notifications, then track their response in DB so we don't keep prompting them if they decline.
                  });
              }
            } else {
              pushNotify.init();// init for ANDROID
            }
          }


      } else { // ALERT USER PROFILE IS NOT VALID AND SEND TO PROFILE PAGE
        app.dialog.alert(
          'You must set your profile for how you intend to measure your set-up.  Click OK to choose your set-up measurement properties.',
          'Profile Not Complete.',
          function () {
            app.views.main.router.navigate('/profile/');
        });
      }
    } else if (returnCode == -1) { // FIRST TIME USER FORCE partial profile completion
      FirstEntry.firstTimeEntry(); // FOR FIRST TIME ENTRY AND WHEN PROFILE IS NOT COMPLETE/REQURIED ITEMS ADDED
    } else { // GENERIC ERROR PAGE/ALERT
      let caller='route_retrieveProfile';
      app.methods.genericAlert(caller,returnCode)
    }
  },



  //
  // isPushEnabled: function() {
  //   pushNotify.isPushEnabled()
  //       .then(data => {
  //           console.log ("in then ");
  //           console.log (data);
  //           return data;
  //       }) // fat arrow
  //       .catch(error => {
  //         console.log ("in catch ");
  //         console.log (error)
  //         return error;
  //       });
  // },



  viewTerms: function () {
            console.log('viewTerms function');

            var url=app.data.wsURL+'ws_get_terms_conditions_ret_text.php';

            app.request({url:url,data:{  },type:'GET',dataType: 'text'
            ,success:function(htmlString) {
                console.log('ajax success.');
                if (htmlString.length>0) { // RETURNED RESULTS

                    console.log('json_Obj is ' + htmlString);

                    var popupHTML = '<div class="popup popup-terms">';
                        popupHTML+='<div class="page">';

                        popupHTML+='<div class="navbar">';
                        popupHTML+='<div class="navbar-bg"></div>';
                        popupHTML+='<div class="navbar-inner">';
                        popupHTML+='<div class="title">Terms and Conditions</div>';
                        popupHTML+='<div class="right"><a href="#" class="link popup-close">Close</a></div>';
                        popupHTML+='</div>';
                        popupHTML+='</div>';

                        popupHTML+='<div class="page-content">';
                        popupHTML+='<div class="block">';
                        popupHTML+= htmlString;
                        popupHTML+='</div>'; // END  block
                        popupHTML+='</div>'; // END  page content

                        popupHTML+='</div>'; // end class page
                        popupHTML+='</div>'; // end class popup
                        app.popup.open(popupHTML);

                  } else {
                    console.log('no text string returned');
                  }

              }, complete: function(){
                  console.log('ajax complete for viewTerms.')

              }, // end COMPLETE
              timeout: 5000,
              error: function(htmlString, status, err) {
                if (status == "timeout") {
                    console.log("Timeout Error. " + htmlString + status + err);
                    //$( "#error_login").html("Timeout error.  Please retry.")
                    //$(popDiv).html('TimeOut Error:   Please retry.');
                } else {
                    // another error occured
                    //$( "#error_login").html('Error occurred.  Please retry.');
                    console.log("error: " + htmlString + status + err);
                }
              }, // end error
                beforeSend: function(){
                  console.log('ajax beforeSend.')
                  } // END before Send
            });
  },





  viewDataPrivacy: function () {
            console.log('viewDataPrivacy function');

            var url=app.data.wsURL+'ws_get_data_privacy_policy_ret_text.php';

            app.request({url:url,data:{  },type:'GET',dataType: 'text'
            ,success:function(htmlString) {
                console.log('ajax success.');
                if (htmlString.length>0) { // RETURNED RESULTS

                    console.log('json_Obj is ' + htmlString);

                    var popupHTML = '<div class="popup popup-terms">';
                        popupHTML+='<div class="page">';

                        popupHTML+='<div class="navbar">';
                        popupHTML+='<div class="navbar-bg"></div>';
                        popupHTML+='<div class="navbar-inner">';
                        popupHTML+='<div class="title">Data Privacy Policy</div>';
                        popupHTML+='<div class="right"><a href="#" class="link popup-close">Close</a></div>';
                        popupHTML+='</div>';
                        popupHTML+='</div>';

                        popupHTML+='<div class="page-content">';
                        popupHTML+='<div class="block">';

                        //popupHTML+='<div class="closePopup"><a href="#" class="link popup-close"><i class="icon f7-icons">close_round</i></a></div>';
                        //popupHTML+='<div class="content-block">';
                        //popupHTML+='<div class="content-block-title">Terms and Conditions</div>';
                        popupHTML+= htmlString;
                        //popupHTML+='<div><a href="#" class="link popup-close">Close</a></div>';
                        popupHTML+='</div>'; // END  block
                        popupHTML+='</div>'; // END  page content

                        popupHTML+='</div>'; // end class page
                        popupHTML+='</div>'; // end class popup
                        app.popup.open(popupHTML);

                  } else {
                    console.log('no text string returned');
                  }

              }, complete: function(){
                  console.log('ajax complete for viewTerms.')

              }, // end COMPLETE
              timeout: 5000,
              error: function(htmlString, status, err) {
                if (status == "timeout") {
                    console.log("Timeout Error. " + htmlString + status + err);
                    //$( "#error_login").html("Timeout error.  Please retry.")
                    //$(popDiv).html('TimeOut Error:   Please retry.');
                } else {
                    // another error occured
                    //$( "#error_login").html('Error occurred.  Please retry.');
                    console.log("error: " + htmlString + status + err);
                }
              }, // end error
                beforeSend: function(){
                  console.log('ajax beforeSend.')
                  } // END before Send
            });
  },

}
export default Interface;
