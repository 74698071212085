class SkiSettings {

  constructor(setting_id, user_name, ski_id, front_binding, length, depth, dft, wing_angle, leading_edge, binding_to_le, measure_binding, measure_length, measure_depth, measure_dft, assigned, assigned_by, date_time_created) {
    this.setting_id = setting_id;
    this.user_name = user_name;
    this.ski_id = ski_id;
    this.front_binding = front_binding;
    this.length = length;
    this.depth = depth;
    this.dft = dft;
    this.wing_angle = wing_angle;
    this.leading_edge = leading_edge;
    this.binding_to_le = binding_to_le;
    this.measure_binding = measure_binding;
    this.measure_length = measure_length;
    this.measure_depth = measure_depth;
    this.measure_dft = measure_dft;
    this.assigned = assigned;
    this.assigned_by = assigned_by;
    this.date_time_created = date_time_created;
  }


  get getSettings() {
    return this;
  }


  set setSettings(obj) {
    if(obj.setting_id) this.setting_id = obj.setting_id;
    if(obj.user_name) this.user_name = obj.user_name;
    if(obj.ski_id) this.ski_id = obj.ski_id;
    if(obj.front_binding) this.front_binding = obj.front_binding;
    if(obj.length) this.length = obj.length;
    if(obj.depth) this.depth = obj.depth;
    if(obj.dft) this.dft = obj.dft;
    if(obj.wing_angle) this.wing_angle = obj.wing_angle;
    if(obj.leading_edge) this.leading_edge = obj.leading_edge;
    if(obj.binding_to_le) this.binding_to_le = obj.binding_to_le;
    if(obj.measure_binding) this.measure_binding = obj.measure_binding;
    if(obj.measure_length) this.measure_length = obj.measure_length;
    if(obj.measure_depth) this.measure_depth = obj.measure_depth;
    if(obj.measure_dft) this.measure_dft = obj.measure_dft;
    this.assigned = obj.assigned; // this can be null as only set by dealer
    this.assigned_by = obj.assigned_by; // this can be null as only set by dealer
    if(obj.date_time_created) this.date_time_created = obj.date_time_created
  }



  clearSettings(obj) {
    for (let prop in obj) {
      delete obj[prop];
    }
  }

} // END class Settings






var Settings  = {

  thisSetting:{},


  /*
    ******** THIS IS THE CONSTRUCTOR FOR THE thisSetting Object
    ******** the thisSetting object will be available to all modules that import the Settings export
    ******** All methods to CRUD the thisSettings object should be done in the SkiSettings class above.
  */
  initSettings: function(obj) {
    console.log("in Settings.initSettings...thisSetting object is established.");
    if (Object.keys(obj).length>0) { // create populated object
      Settings.thisSetting = new SkiSettings(obj.setting_id, obj.user_name, obj.ski_id, obj.front_binding, obj.length, obj.depth, obj.dft, obj.wing_angle, obj.leading_edge, obj.binding_to_le, obj.measure_binding, obj.measure_length, obj.measure_depth, obj.measure_dft, obj.assigned, obj.assigned_by, obj.date_time_created);
    } else {
      Settings.thisSetting = new SkiSettings({}); // create empty object
      Settings.thisSetting.clearSettings(Settings.thisSetting);// then remove all the properties...this is a workaround for other code in the project
      //Settings.thisSetting={};
    }

  },



};

export default Settings;
