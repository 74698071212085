import $$ from 'dom7';
//import Users from '../js_my/user_class.js';
import Interface from '../js_my/interface.js';
import Utility from '../js_my/utility.js';
//import pushNotify from '../js_my/pushNotify.js';


var Notifications = {
  notify:true,



  getNotifications: function() {
    console.log('in getNotificaitons ');

    var dataObj = { user_name:Interface.getUser().user_name, token:Interface.getUser().token }
    var url=app.data.wsURL+'ws_get_notifications_ret_json.php';
    var returnCode;

    app.request({url:url,data:dataObj,type:'POST',dataType: 'json',success:function(jsonObj) {
      if (jsonObj.RETURN_CODE==1) {
        console.log('return code 1...success');
        console.log(jsonObj);
        Notifications.renderNotifications(jsonObj);
        returnCode=1;
      } else {
        returnCode = jsonObj.RETURN_CODE ? jsonObj.RETURN_CODE : 0;
      }
    }, timeout: 5000
      , beforeSend: function() {
        //console.log('beforeSend toggleFinShare');
        app.preloader.show();
      }, complete: function() {
        //console.log('in complete toggleFinShare');

        app.preloader.hide();
      }, error: function(jsonObj, status, err) {
          if (status == "timeout") {
            console.log("Timeout Error. " + jsonObj + status + err);
          } else {
            console.log("error: "  + status + err);
            console.log(jsonObj);
          }
      }
    }) // END ajax function for models
  },





  renderNotifications: function(jsonObj) {
    let data=jsonObj.data;
    console.log('in renderNotificaitons with notifications='+data.length);
    let str='';

    if (data.length>0) {
      str+=`<div class="list media-list"><ul>`;
      data.forEach(function(item){
        let thisURL="#;"
        if (item.data_attr) {
          let dArray=Notifications.PipeDelimitedNameValuesToArray(item.data_attr,"|");
          if (dArray.hasOwnProperty("pageID")) {
            //thisURL="/"+dArray["pageID"]+"/";
            thisURL=dArray["pageID"];
            console.log("url is"+thisURL+"now ending");
            thisURL=thisURL.replace(/ /g,'');
            console.log("and now"+thisURL+"now ending");
            var rxStart = new RegExp(/^\//);
            var rxEnd = new RegExp(/\/$/);
            if (!rxStart.test(thisURL)) thisURL="/"+thisURL;
            if (!rxEnd.test(thisURL)) thisURL=thisURL+"/";
          }
        }
        str+=`<li data-page-id='finShare'>
              <a href="${thisURL}" class="item-link item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title">${item.title}</div>
                    <div class="item-after">${item.date_time}</div>
                  </div>
                  <div class="item-text">${item.message}</div>
                </div>
              </a>
            </li>`;
        });
        str+=`</ul></div`;
    } else {
      str+=`<div class="list simple-list"><ul><li>No notifications available.</li></ul></div>`;
    }

      $$("#notifications").html(str);
  },



  // takes a pipe delimites tring with name value pairs and returns associative array
  PipeDelimitedNameValuesToArray: function(str,delimiter) {
    let arr = str.split(delimiter);
    let dataArray=[];
    arr.forEach(function(item) {
      let tmp=item.split("=");
      dataArray[tmp[0]]=tmp[1];
    });
    return dataArray;
  },



}

export default Notifications;


//
// // Called from deviceReady event
// function initNotifications() {
//   window.FirebasePlugin.hasPermission(function(data){
//     if (data.isEnabled) {
//
//       window.FirebasePlugin.getToken(function(token) {
//           // save this server-side and use it to push notifications to this device
//           console.log("getToken is " + token);
//           saveFCMToken(token);
//       }, function(error) {
//           console.error(error);
//       });
//
//       // Get notified when a token is refreshed
//       window.FirebasePlugin.onTokenRefresh(function(token) {
//           // save this server-side and use it to push notifications to this device
//           console.log("Refresh to get new token: " + token);
//           saveFCMToken(token);
//       }, function(error) {
//           alert(error);
//       });
//
//     } else if (isIos){ // IOS only
//       window.FirebasePlugin.grantPermission();
//       console.log("Requesting IOS permission to receive notifications.");
//     }
//
//   });
// }
//
// function showNotifications() {
//   // Get notified when the user opens a notification
//   window.FirebasePlugin.onNotificationOpen(function(notification) {
//       let title=notification.aps.alert.title;
//       let msg=notification.aps.alert.body;
//       console.log("Notification payload from cordova.plugin.firebase: " + JSON.stringify(notification));
//       if (notification.score==850) {
//         var fcmNotification = myApp.notification.create({
//           icon: '<img src="../res/icon/ios/icon-40.png" width="24" height="24" />',
//           title: title,
//           titleRightText: 'now',
//           subtitle: 'Notification',
//           text: msg,
//           closeButton: true,
//           closeOnClick: true,
//         });
//         fcmNotification.open();
//
//       } else {
//         alert("The notification is open!");
//       }
//       window.FirebasePlugin.setBadgeNumber(0); // clear the badge after showing notifications
//   }, function(error) {
//       console.error(error);
//   });
// }
//
// function saveFCMToken(token) {
//   var url=wsURL+'ws_set_fcm_token_ret_json.php';
//   var user_name = localStorage.user_name;
//   if (typeof user_name != 'undefined') {
//     var data={'user_name':user_name,'fcm_token':token}
//     ajaxPostRequest (url, data, showNotifications);
//   }
// }
